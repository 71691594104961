import React from "react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle, faTimes, faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import "react-toastify/dist/ReactToastify.min.css";

const Toaster = ({ message, type = "success" }) =>
  toast(
    ({ closeToast }) => (
      <div className="toast show mb-1" role="alert" aria-live="assertive" aria-atomic="true" data-delay="5000">
        <div className="toast-header text-dark pr-1">
          <img src="/images/dragonflyai-ident.svg" className="rounded me-2" alt="..." width="16" height="16" />
          <strong className="me-auto">Dragonfly AI</strong>
          <small className="text-muted">just now</small>
          <button type="button" className="btn btn-sm btn-link ms-2 text-muted" data-dismiss="toast" aria-label="Close" onClick={closeToast}>
            <span aria-hidden="true">
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </button>
        </div>
        <div className="toast-body text-break">
          {Boolean(type === "success") && <FontAwesomeIcon icon={faCheckCircle} size="lg" className="me-1 text-success" />}
          {Boolean(type === "error") && <FontAwesomeIcon icon={faExclamationCircle} size="lg" className="me-1 text-danger" />}
          {Boolean(type === "warn") && <FontAwesomeIcon icon={faTriangleExclamation} size="lg" className="me-1 text-warning" />}
           {message}
        </div>
      </div>
    ),
    {
      autoClose: 2000,
      hideProgressBar: true,
      closeButton: false,
      position: "bottom-left",
      toastId: message
    }
  );

export { Toaster };
