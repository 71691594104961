import axios from "axios";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    "DF-Version": process.env.REACT_APP_DF_VERSIONS,
    Accept: "application/json",
    "Content-Type": "application/json",
  },

  withCredentials: true,
  timeout: 1000 * 60 * 10,
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    return {
      ...config,
    };
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
