import { useMemo, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { Button as FormikButton } from "../FormFields";
import { useSelector } from "react-redux";
import { TagsDropdown } from "./TagsDropdown";
import { Form, Formik } from "formik";
import { useUpdateStudyMutation } from "@/features/studies";
import { Toaster } from "../Toast";
import { OtherTag } from "./OtherTag";
import { segmentAnalytics } from "@/utilities/genericFunctions";
import { useNavigate } from "react-router-dom";
import { useGetUseCasesQuery } from "@/redux/toolkit/accountSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";

export const UpdateUseCaseModal = ({ hide, study, projectId }) => {
  const [updateStudyUseCase, { isLoading, isError }] = useUpdateStudyMutation();

  const accountId = useSelector((state) => state.session?.account?.id);

  const {
    data: { filteredUseCases: useCases } = {},
    isSuccess: useCaseIsSuccess,
    isLoading: useCaseIsLoading,
    isError: useCaseIsError,
  } = useGetUseCasesQuery({ accountId }, { refetchOnMountOrArgChange: true });

  const session = useSelector((state) => state.session);
  const [useCase, setUseCase] = useState("");

  const navigate = useNavigate();

  useMemo(() => {
    // match the use case id to the use case name
    if (useCases && study.useCase?.id) setUseCase(useCases?.find(({ id }) => id === study?.useCase.id)?.name);
  }, [study.useCase]);

  const handleSubmit = async (tag) => {
    if (tag.otherTag) {
      segmentAnalytics({
        page: false,
        name: "Use Case Suggestion",
        session,
        segmentData: {
          otherUseCase: tag.otherTag,
          projectId: projectId,
          studyName: study.name,
        },
      });

      Toaster({ message: "Use case suggestion added." });
    }
    // update the study use case, use the study use case id if the use case is not changed
    try {
      await updateStudyUseCase({
        data: { useCaseID: tag.useCaseId || study.useCaseId },
        projectId,
        studyId: study.id,
      }).unwrap();
      if (!isError) {
        hide(true);
        navigate(`/projects/${projectId}/studies/${study.id}`);
      }
      Toaster({ message: "Study use case updated" });
    } catch (error) {
      Toaster({ message: "Failed to update study use case", type: "error" });
    }
  };

  return (
    <Formik
      initialValues={{
        useCaseId: "",
        otherTag: "",
      }}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <Modal.Header closeButton onHide={hide}>
            <Modal.Title as="h5" className="fw-light">
              Set use case
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-white">
            <div className="form-label-group mb-2 mt-2 text-area-label-fix">
              <TagsDropdown
                tag={useCases}
                setTag={setUseCase}
                displayValue={useCase}
                isError={useCaseIsError}
                isSuccess={useCaseIsSuccess}
                isLoading={useCaseIsLoading}
                defaultDisplayValue="Select use case"
                fieldName="useCaseId"
                noTagsMessage="No use cases found"
              />
              {useCase === "Other" && (
                <OtherTag
                  label="Feedback"
                  supportingMessage="Share your feedback here to let us know what use case options you'd like us to support."
                />
              )}
            </div>
            <Alert variant="info" className="fs-sm p-3 ps-5 position-relative mt-0">
              <FontAwesomeIcon icon={faInfoCircle} size="xl" className="position-absolute top-0 start-0 m-3" />
              <p className="mb-0 readable-md ms-2">
                Adding a use case helps us categorize your data and improve the product experience over time.{" "}
                <a
                  href="https://academy.dragonflyai.co/en/articles/8278201-study-use-case"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more.
                </a>
              </p>
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              className="text-muted"
              data-dismiss="modal"
              onMouseDown={(e) => e.preventDefault()}
              onClick={hide}
            >
              Discard
            </Button>
            <FormikButton label="Update" className="btn btn-primary" disabled={isLoading} />
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};
