import { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router";
import {
  useDeleteBenchmarkAssetMutation,
  useGetAllBenchmarksQuery,
  useUpdateBenchmarkMutation,
} from "../api/librarySlice";
import { Loader } from "@/components";
import { NotFound } from "./NotFound";
import { LibraryCard } from "./LibraryCard";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCircleInfo, faLock } from "@fortawesome/pro-regular-svg-icons";
import { Toaster } from "@/components/Toast";
import { AddBenchmarkMediaModal, ToggleModal } from "@/components/Modals";
import { segmentAnalytics } from "@/utilities/genericFunctions";
import { faGauge } from "@fortawesome/pro-light-svg-icons";

export const Benchmark = () => {
  const { benchmark_id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { benchmark } = state || {};

  const [contextSelection, setContextSelection] = useState({});
  const [showAssetPanel, setShowAssetPanel] = useState(false);

  const session = useSelector((state) => state.session);
  const benchmarkFilters = useSelector((state) => state.libraryFilters.benchmarkFilters);
  const { useCase, status, name } = benchmark;

  const {
    data: { benchmarks } = {},
    isSuccess,
    isLoading,
  } = useGetAllBenchmarksQuery({ ...benchmarkFilters, useCaseIds: [useCase.id], benchmarkId: benchmark_id });

  const [updateBenchmark] = useUpdateBenchmarkMutation();
  const [deleteBenchmarkAsset] = useDeleteBenchmarkAssetMutation();

  const updateStatus = async (status) => {
    try {
      await updateBenchmark({ data: { status: status }, benchmarkId: benchmark_id }).unwrap();

      /** Segment track event for benchmark publish */
      segmentAnalytics({
        page: false,
        session,
        name: "Library Benchmark Publish",
        segmentData: {
          benchmarkName: name,
        },
      });
      navigate("/library/benchmarks");
      Toaster({ message: "Benchmark successfully updated." });
    } catch (e) {
      Toaster({ message: "Updating benchmark failed.", type: "error" });
    }
  };

  const deleteAsset = async (assetId) => {
    try {
      await deleteBenchmarkAsset({ benchmarkId: benchmark_id, assetId: assetId }).unwrap();
      Toaster({ message: "Asset successfully deleted." });
    } catch (e) {
      Toaster({ message: "Deleting asset failed.", type: "error" });
    }
  };

  return (
    <div className="h-100 flex-grow-1 p-4 overflow-auto">
      <div className="d-flex mb-3 mt-1">
        <div className="d-flex align-items-center">
          <a
            className="d-inline-block float-left btn btn-outline-dark me-3"
            onClick={() => navigate("/library/benchmarks")}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </a>
          <h1 className="d-inline-block h4 fw-700 mt-1 mb-0">
            {name} <span className="badge border border-dark bg-light text-dark fs-xxs ms-2">{status}</span>
          </h1>
        </div>
        <nav className="navbar navbar-expand-lg align-items-middle justify-content-between p-0 ms-auto">
          <div className="btn-toolbar d-none d-md-flex ms-auto" role="toolbar" aria-label="Toolbar with button groups">
            <span
              className="btn btn-transparent bg-info-10 text-info me-2 pe-none rounded-pill"
              style={{ backgroundColor: "rgba(6,78,142,.1)" }}
            >
              <FontAwesomeIcon icon={faLock} className="me-1" /> {useCase?.name}
            </span>
            <div className="dropdown mx-2">
              <button
                className="btn btn-outline-dark"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                disabled={status === "published"}
              >
                Publish
              </button>
              <div className="dropdown-menu dropdown-menu-end p-3" id="menu-end-fix" style={{ width: "12rem" }}>
                <p className="fs-sm mb-2">
                  <FontAwesomeIcon icon={faCircleInfo} className="me-1" /> Hint
                </p>
                <p className="fs-xs text-muted">
                  Once published, your pack can't be edited. If you want to make a change, you'll need to create a new
                  pack.
                </p>
                <p className="mb-0">
                  <button className="btn btn-sm btn-outline-primary" onClick={() => updateStatus("published")}>
                    Confirm
                  </button>
                  <a href="" className="float-end fs-xs text-muted mt-2">
                    Learn more
                  </a>
                </p>
              </div>
            </div>
            <ToggleModal
              toggle={(show) => (
                <div className="btn-group" role="group" aria-label="Third group">
                  <button className="btn btn-primary" onClick={show} disabled={status === "published"}>
                    Upload
                  </button>
                </div>
              )}
              content={(hide) => (
                <AddBenchmarkMediaModal
                  hide={() => {
                    hide();
                  }}
                />
              )}
              contentClassName="bg-light"
              dialogClassName="modal-fullscreen"
            />
          </div>
        </nav>
      </div>
      {isLoading && <Loader show={true} />}
      {isSuccess && benchmarks[0].assets.length === 0 && (
        <NotFound
          componentText="You now need to select and upload your benchmark assets. Don't forget to publish when ready."
          icon={faGauge}
        />
      )}
      {isSuccess && benchmarks[0].assets.length > 0 && (
        <>
          <div className="d-flex flex-row flex-wrap">
            {benchmarks[0].assets.map((benchmarkMedia, i) => (
              <LibraryCard
                key={`col-${i}`}
                cardMeta={benchmarkMedia}
                setContextSelection={setContextSelection}
                contextSelection={contextSelection}
                setShowAssetPanel={setShowAssetPanel}
                showAssetPanel={showAssetPanel}
                updateFunction={deleteAsset}
                updateHook={updateBenchmark}
                actions={["preview", "delete"]}
                title={benchmarkMedia.filename}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
