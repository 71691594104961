import { useState } from "react";
import { LibraryNavbar } from "./LibraryNavbar";
import { useGetAllBenchmarksQuery, useUpdateBenchmarkMutation } from "../api/librarySlice";
import { Loader, Pagination } from "@/components";
import { NotFound } from "./NotFound";
import { LibraryCard } from "./LibraryCard";
import { useDispatch, useSelector } from "react-redux";
import { NewBenchmarkModal } from "@/components/Modals/NewBenchmarkModal";
import { updateBenchmarkFilter } from "../stores/filtersSlice";
import { useNavigate } from "react-router-dom";
import { Toaster } from "@/components/Toast";
import { faGauge } from "@fortawesome/pro-regular-svg-icons";

export const Benchmarks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [contextSelection, setContextSelection] = useState({});
  const [showAssetPanel, setShowAssetPanel] = useState(false);

  const benchmarkFilters = useSelector((state) => state.libraryFilters.benchmarkFilters);
  const [updateBenchmark, mutationStatus] = useUpdateBenchmarkMutation();

  const {
    data: { benchmarks, maxPageIndex, maxRecordCount, pageIndex } = {},
    isSuccess,
    isLoading,
  } = useGetAllBenchmarksQuery(benchmarkFilters);

  const onSubmit = async (values, cardId) => {
    try {
      await updateBenchmark({ data: values, benchmarkId: cardId }).unwrap();
      Toaster({ message: "Benchmark successfully updated." });
    } catch (error) {
      Toaster({ message: "Benchmark update failed.", type: "error" });
    }
  };

  return (
    <div className="h-100 flex-grow-1 p-4 overflow-auto">
      <LibraryNavbar
        filterDisplay={{ name: "Name", createdat: "Created", owner: "Owner" }}
        filterState={benchmarkFilters}
        filterStore={updateBenchmarkFilter}
        uploadContentText="New benchmark"
        uploadComponent={NewBenchmarkModal}
        modalClassNames=""
      />
      {isLoading && <Loader show={true} />}
      {isSuccess && benchmarks?.length <= 0 && <NotFound icon={faGauge} />}
      {isSuccess && (
        <div className="d-flex flex-row flex-wrap">
          {benchmarks.map((benchmark, i) => (
            <LibraryCard
              key={`col-${i}`}
              cardMeta={benchmark}
              cardCoverImage={benchmark.assets[0]?.thumbUrl}
              cardIcon={faGauge}
              setContextSelection={setContextSelection}
              contextSelection={contextSelection}
              setShowAssetPanel={setShowAssetPanel}
              showAssetPanel={showAssetPanel}
              stackNumber={benchmark.assets.length}
              onClick={() => {
                navigate(`${benchmark.id}`, { state: { benchmark: benchmark } });
              }}
              updateFunction={onSubmit}
              updateHook={updateBenchmark}
              mutationStatus={mutationStatus}
              status={benchmark.status}
              actions={["preview", "rename", "archive"]}
            />
          ))}
        </div>
      )}
      <nav className="navbar navbar-expand-lg navbar-light mt-5 mb-0">
        {isSuccess && benchmarks.length > 0 && (
          <Pagination
            key={`${benchmarkFilters.itemsPerPage}`}
            maxPageIndex={maxPageIndex}
            pageIndex={pageIndex}
            totalRecords={maxRecordCount}
            pageLimit={benchmarkFilters.itemsPerPage}
            pageNeighbours={2}
            onPageChanged={({ currentPage }) => dispatch(updateBenchmarkFilter({ pageIndex: currentPage }))}
            onLimitChange={(itemsPerPage) => {
              dispatch(updateBenchmarkFilter({ itemsPerPage, pageIndex: 1 }));
            }}
          />
        )}
      </nav>
    </div>
  );
};
