import { createAction } from "redux-actions";

// CalculateRegion
export const CALCULATE_RECT_REGION = "CALCULATE_RECT_REGION";
export const CalculateRectRegion = createAction(CALCULATE_RECT_REGION);
export const CALCULATE_RECT_REGION_REQUESTED = "CALCULATE_RECT_REGION_REQUESTED";
export const CalculateRectRegionRequested = createAction(CALCULATE_RECT_REGION_REQUESTED);
export const CALCULATE_RECT_REGION_FAILURE = "CALCULATE_RECT_REGION_FAILURE";
export const CalculateRectRegionFailure = createAction(CALCULATE_RECT_REGION_FAILURE);
export const CALCULATE_RECT_REGION_SUCCESS = "CALCULATE_RECT_REGION_SUCCESS";
export const CalculateRectRegionSuccess = createAction(CALCULATE_RECT_REGION_SUCCESS);

export const CALCULATE_POLYGON_REGION = "CALCULATE_POLYGON_REGION";
export const CalculatePolygonRegion = createAction(CALCULATE_POLYGON_REGION);
export const CALCULATE_POLYGON_REGION_REQUESTED = "CALCULATE_POLYGON_REGION_REQUESTED";
export const CalculatePolygonRegionRequested = createAction(CALCULATE_POLYGON_REGION_REQUESTED);
export const CALCULATE_POLYGON_REGION_FAILURE = "CALCULATE_POLYGON_REGION_FAILURE";
export const CalculatePolygonRegionFailure = createAction(CALCULATE_POLYGON_REGION_FAILURE);
export const CALCULATE_POLYGON_REGION_SUCCESS = "CALCULATE_POLYGON_REGION_SUCCESS";
export const CalculatePolygonRegionSuccess = createAction(CALCULATE_POLYGON_REGION_SUCCESS);

export const CALCULATE_VISUAL_HIERARCHY = "CALCULATE_VISUAL_HIERARCHY";
export const CalculateVisualHierarchy = createAction(CALCULATE_VISUAL_HIERARCHY);
export const CALCULATE_VISUAL_HIERARCHY_REQUESTED = "CALCULATE_VISUAL_HIERARCHY_REQUESTED";
export const CalculateVisualHierarchyRequested = createAction(CALCULATE_VISUAL_HIERARCHY_REQUESTED);
export const CALCULATE_VISUAL_HIERARCHY_FAILURE = "CALCULATE_VISUAL_HIERARCHY_FAILURE";
export const CalculateVisualHierarchyFailure = createAction(CALCULATE_VISUAL_HIERARCHY_FAILURE);
export const CALCULATE_VISUAL_HIERARCHY_SUCCESS = "CALCULATE_VISUAL_HIERARCHY_SUCCESS";
export const CalculateVisualHierarchySuccess = createAction(CALCULATE_VISUAL_HIERARCHY_SUCCESS);
