import { push } from "@lagunovsky/redux-react-router";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { Toaster } from "../../components/Toast";
import httpClient from "../../config/http-client";

import {
  SIGNOUT,
  signoutSuccess,
  UpdateUserFailure,
  UpdateUserPasswordFailure,
  UpdateUserPasswordRequested,
  UpdateUserPasswordSuccess,
  UpdateUserRequested,
  UpdateUserSuccess,
  UPDATE_USER,
  UPDATE_USER_PASSWORD,
} from "../actions/session-action-types";

export function* signout() {
  yield put(signoutSuccess());
  yield put(push("/signout"));
}

export function* UpdateUser({ payload: data }) {
  yield put(UpdateUserRequested());

  const appUserId = yield select(
    ({
      session: {
        user: { id },
      },
    }) => id
  );

  const { result, error } = yield call(httpClient, {
    data,
    method: "put",
    url: `/manage/users/${appUserId}`,
  });

  if (error) {
    yield put(UpdateUserFailure(error));
    Toaster({ message: "User update failed.", type: "error" });
  } else {
    yield put(UpdateUserSuccess(result.payload.user));
    Toaster({ message: "User successfully updated." });
  }

  return { error, result };
}

export function* UpdateUserPassword({ payload: { data, appUserId, callback } }) {
  yield put(UpdateUserPasswordRequested());

  const { result, error } = yield call(httpClient, {
    data,
    method: "put",
    url: `/manage/users/${appUserId}/password`,
  });

  if (error) {
    yield put(UpdateUserPasswordFailure(error));
    Toaster({ message: "User password update failed.", type: "error" });
  } else {
    yield put(UpdateUserPasswordSuccess(result.payload));
    Toaster({ message: "User password successfully updated." });
  }

  if (callback) callback();

  return { error, result };
}

function* Session() {
  yield all([
    takeLatest(SIGNOUT, signout),
    takeLatest(UPDATE_USER, UpdateUser),
    takeLatest(UPDATE_USER_PASSWORD, UpdateUserPassword),
  ]);
}

export default Session;
