import { useState } from "react";
import { LibraryNavbar } from "./LibraryNavbar";
import { useGetAllContextPacksQuery, useUpdateContextPackMutation } from "../api/librarySlice";
import { Loader, Pagination } from "@/components";
import { NotFound } from "./NotFound";
import { LibraryCard } from "./LibraryCard";
import { useDispatch, useSelector } from "react-redux";
import { updateContextPackFilter } from "../stores/filtersSlice";
import { ContextPackModal } from "@/components/Modals/ContextPackModal";
import { NewContextPackModal } from "@/components/Modals/NewContextPackModal";
import { Toaster } from "@/components/Toast";
import { ImagePreviewModal } from "./ImagePreviewModal";
import { faLayerGroup } from "@fortawesome/pro-regular-svg-icons";

export const ContextPacks = () => {
  const dispatch = useDispatch();
  const contextPackFilters = useSelector((state) => state.libraryFilters.contextPackFilters);
  const [updateContextPack, mutationStatus] = useUpdateContextPackMutation();

  const [contextSelection, setContextSelection] = useState({});
  const [showAssetPanel, setShowAssetPanel] = useState(false);

  const {
    data: { contextPacks, maxPageIndex, maxRecordCount, pageIndex } = {},
    isSuccess: contextPackIsSuccess,
    isLoading: contextPackIsLoading,
  } = useGetAllContextPacksQuery(contextPackFilters);

  const onSubmit = async (values, cardId) => {
    try {
      await updateContextPack({ data: values, contextPackId: cardId }).unwrap();
      Toaster({ message: "Context pack successfully updated." });
    } catch (error) {
      Toaster({ message: "Context pack update failed.", type: "error" });
    }
  };

  return (
    <div className="h-100 flex-grow-1 p-4 overflow-auto">
      <LibraryNavbar
        filterDisplay={{ name: "Name", createdat: "Created", owner: "Owner" }}
        filterState={contextPackFilters}
        filterStore={updateContextPackFilter}
        uploadContentText="New pack"
        uploadComponent={NewContextPackModal}
      />
      {contextPackIsLoading && <Loader show={true} />}
      {contextPackIsSuccess && contextPacks?.length <= 0 && <NotFound icon={faLayerGroup} />}
      {contextPackIsSuccess && contextPacks?.length > 0 && (
        <div className="d-flex flex-row flex-wrap">
          {contextPacks.map((packs, i) => (
            <LibraryCard
              key={`col-${i}`}
              cardMeta={packs}
              cardCoverImage={packs.contexts[0]?.thumbUrl}
              cardIcon={faLayerGroup}
              setContextSelection={setContextSelection}
              contextSelection={contextSelection}
              setShowAssetPanel={setShowAssetPanel}
              showAssetPanel={showAssetPanel}
              navigateModal={packs.status === "draft" ? ContextPackModal : ImagePreviewModal}
              stackNumber={packs.contexts.length}
              updateFunction={onSubmit}
              mutationStatus={mutationStatus}
              title="Context Pack"
              updateHook={updateContextPack}
              status={packs.status}
              actions={["preview", "rename", "archive"]}
            />
          ))}
        </div>
      )}
      <nav className="navbar navbar-expand-lg navbar-light mt-5 mb-0">
        {contextPackIsSuccess && (
          <Pagination
            key={`${contextPackFilters.itemsPerPage}`}
            maxPageIndex={maxPageIndex}
            pageIndex={pageIndex}
            totalRecords={maxRecordCount}
            pageLimit={contextPackFilters.itemsPerPage}
            pageNeighbours={2}
            onPageChanged={({ currentPage }) => dispatch(updateContextPackFilter({ pageIndex: currentPage }))}
            onLimitChange={(itemsPerPage) => {
              dispatch(updateContextPackFilter({ itemsPerPage, pageIndex: 1 }));
            }}
          />
        )}
      </nav>
    </div>
  );
};
