import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faInfoCircle, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faArrowToBottom as falArrowToBotton, faTrashAlt as falTrashAlt, faSpinner as falSpinner, faCheck as falCheck } from "@fortawesome/pro-light-svg-icons";
import { faGripVertical as fasGripVertical } from "@fortawesome/pro-solid-svg-icons";
import { Toaster } from "../Toast";
import { UploadFile } from "../../redux/actions/file-action-types";
import { UpdateMediaStudy } from "../../redux/actions/project-action-types";
import { ReactSortable } from "react-sortablejs";
import { segmentAnalytics, deepCopy } from "../../utilities/genericFunctions";

function AddMediaModal({ hide, study, projectId , uploadSpinner, assignToStage, uploadFlag, assignMedia, assignToStageProgress, setUploadFlag, activeReport }) {
  const dispatch = useDispatch();
  const [ images, setImages ] = useState([]);
  const [ uploadProgress, setUploadProgress ] = useState(0);
  const [ uploadedCount, setUploadedCount ] = useState(0);
  const [ uploadInProcess, setUploadInProcess ] = useState(false);
  const [ assignStage, setAssignStage ] = useState(false);
  const [ showMsg, setShowMsg ] = useState(false);
  const maxNumber = 100;
  const session = useSelector((state) => state.session);
  const Media = useSelector((state) => state.project.getStudy?.data?.media);
  const studyCopy = deepCopy(study);
  const mediaAssigned = studyCopy.media && studyCopy.media.length ? studyCopy.media.filter(media=> (media.stageIndex !== null && media.stageIndex >= 0)).length : 0;

  useEffect(() => {
    if (images.length && uploadProgress === images.length) {
      if(!assignStage){
        hide();
      }
    }
  }, [ uploadProgress, hide, images.length, assignStage ]);

  useEffect(() => {
    if (images.length && uploadProgress === images.length) {
      if(assignStage && assignMedia){
        assignToStage(uploadedCount);
      }
    }
  }, [ Media ]);

  const uploadMedia = async (fileDetail) => {
    return new Promise((resolve, reject) => {
      const callback = (err, response) =>{
        if(!err) {
          response.originalUrl = response.imageUrl;
          response.imageUrl = response.scaledUrl;

          // We save the original filename to the response object due to the backend not returning it
          response.filename = fileDetail.file.name;
          delete response.scaledUrl;
          const media = studyCopy.media;
          dispatch(UpdateMediaStudy({ data: { media: media, name: studyCopy.name }, projectId, studyId: studyCopy.id }));
          setUploadedCount(uploadedCount => (uploadedCount + 1));
          media.push(response);
          resolve(true);
        } else {
          setUploadFlag(false);
          resolve(false);
        }
        setUploadProgress(uploadProgress=> (uploadProgress+1));
      };
      dispatch(UploadFile({ file: fileDetail.file, filename: fileDetail.file.name, callback }));
      /** Segment track event for file upload */
      segmentAnalytics({ page: false, session, name: "Media uploaded", segmentData:{
        projectId: projectId,
        studyId: studyCopy.id,
        reportId: activeReport.id } });
    });
  }

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ 
    accept: {
      "image/jpg": [ ".jpg", ".jpeg", ".png" ],
    }, 
    multiple: true, 
    maxSize:10000000, 
    noClick: true, 
    maxFiles:maxNumber, 
    onDrop: async(acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          Toaster({ message:"Selected file size exceed 10MB", type:"error" });
        }

        if (err.code === "file-invalid-type") {
          Toaster({ message:"Only JPG, JPEG and PNG file supported.", type:"error" });
        }
      });
    });
    const fileList = await getListFiles(acceptedFiles);
    setImages([ ...images, ...fileList ]);
  }
  });

  const getBase64 = (file) => {
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.addEventListener("load", () => resolve(String(reader.result)));
      reader.readAsDataURL(file);
    });
  };

  const getListFiles = async (files) => {
    const promiseFiles = [];
    for (let i = 0; i < files.length; i += 1) {
      promiseFiles.push(getBase64(files[i]));
    }

    return Promise.all(promiseFiles).then((fileListBase64) => {
      const fileList = fileListBase64.map((base64, index) => ({
        data_url: base64, file: files[index]
      }));
      return fileList;
    });
  };

  const onUpload = async(e, assign= false) => {
    e.preventDefault();
    // hide();
   if(images.length>0){
     if(assign){
       setAssignStage(true);
     }
     setUploadInProcess(true);
    uploadSpinner();
   }
    for (const x of images) {
      await uploadMedia(x);
    }
  };
  return (
    <>
      <div className="modal-header border-darken-15 bg-white">
        <h5 className="modal-title fw-light" id="exampleModalLabel">
          Upload media
        </h5>
        {!uploadInProcess &&
          <>
            <button type="button" className="btn btn-link text-muted ms-auto" onClick={(e) => {
              e.stopPropagation();
              hide()
            }}>
              Cancel
            </button>
            <button type="button" className="btn btn-primary me-2" disabled={!images.length} onClick={(e)=>onUpload(e, true)}>
              Upload & Assign
            </button>
            <button type="button" className="btn btn-secondary" disabled={!images.length} onClick={(e)=>onUpload(e)}>
              Upload
            </button>
            <button type="button" className="btn btn-link text-primary" onClick={(e)=> { e.preventDefault(); setShowMsg(true) }}>
              <FontAwesomeIcon icon={faInfoCircle} size="xl"/>
            </button>
          {showMsg &&
          <div className="position-absolute top-0 end-0 bg-white rounded-3 upload-assign-info">
            <div className="d-flex align-items-end justify-content-end">
              <button type="button" className="btn btn-link text-black-50 close" onClick={(e) => { e.preventDefault(); setShowMsg(false)}}>
                <FontAwesomeIcon icon={faTimes} className="mt-1" size="lg"/>
              </button>
            </div>
            <div className="p-4 pt-0">
              <p><span className="text-primary">Upload</span> places media in the tray but does not assign that media to
                a version.</p>
              <p><span className="text-primary">Upload & Assign</span> automatically assigns your media to the next
                available version, in the order you have specified.</p>
              <p>If you have 26 versions live, all additional media will remain unassigned.</p>
              <div className="d-flex align-items-end justify-content-end">
                <button type="button" className="btn btn-link text-primary text-decoration-none close" onClick={(e) => { e.preventDefault(); setShowMsg(false)}}>
                  OK
                </button>
              </div>
            </div>
          </div>
          }
          </>
        }
      </div>
      <div className="modal-body p-0 position-relative media-modal">
        <div className="tab-content d-flex h-100 w-100" id="mediaModalStates">
          <div className="tab-pane h-100 w-100 fade show active" id="initial" role="tabpanel" aria-labelledby="initial-tab">
              <div className="upload__image-wrapper" onClick={()=>{}} {...getRootProps({ className: "dropzone full-width" })}>
                  <div
                    className={
                      isDragActive ? "dropzone-area dropzone-overlay position-absolute top-0 start-0 w-100 h-100 p-3 ui-droppable-active ui-droppable-hover"
                        : images?.length? "" :  "dropzone-area dropzone-overlay position-absolute top-0 start-0 w-100 h-100 p-3 ui-droppable-active"
                    }
                  >
                    <input {...getInputProps()} onClick={e => e.stopPropagation()}/>
                    <div
                      className="dropzone-inner d-flex align-items-center justify-content-center w-100 h-100 rounded border-2"
                    >
                      <div
                        className="dropzone-content text-center dpzone-content"
                      >
                        <div
                          className={`"zindex1" ${images?.length ? "h-100 w-100" : ""} ${ images?.length && isDragActive ? "dp-inner-content" : ""}`}
                        >
                          {images?.length > 0 && !uploadInProcess && (
                            isDragActive ? (
                              <div>
                                <p>
                                  <FontAwesomeIcon icon={falArrowToBotton} size="sx" />
                                </p>
                                <p className="fw-500 mb-2">Drag files here</p>
                                <p className="text-black-50 small">
                            Max file size 10MB. Supported file types JPG, JPEG and PNG. High resolution uploads are scaled to 1024px (w/h) for performance.
                                </p>
                              </div>
                            ) : (
                              <>
                                <div className="tab-pane h-100 w-100 fade show active bg-color" >
                                  <div className="h-100 w-100 text-center overflow-auto">
                                    <div className="readable-md mx-auto text-start my-5">
                                      <div className="d-flex alignment-content-center px-2"><h5> Media</h5></div>
                                      <div className="alignment-content-center px-2">
                                        <p>You currently have { (studyCopy.media && studyCopy.media.length) ? studyCopy.media.length : "no" } media loaded into your study.<br />
                                        <span className="text-primary"> { mediaAssigned ? mediaAssigned : "None" } of those media are assigned to a version. You can test up to 26 versions at the same time.</span></p>
                                      </div>
                                      <ReactSortable
                                      list={images}
                                      setList={(newState) => setImages(newState)}
                                      animation={150}
                                      onChange={(order, sortable, evt) => {
                                        console.log("on change")
                                      }}
                                      className="list-group list-group-flush"
                                      >
                                        {images.length > 0 && images.map((image, index) => {
                                          return (
                                            <li className="list-group-item d-flex justify-content-between align-items-center px-2 bg-transparent border-0" key={index}>

                                              <div className="d-flex flex-row bg-white flex-grow-1 cus-list px-2">
                                              <div className="summary w-100 d-flex justify-content-between">
                                                <p className="fw-500 mb-0 overflow-hidden text-truncate mx-2 max-width375">{image.file.name}</p>
                                                <div
                                                  className="btn btn-link text-muted"
                                                >
                                                  <FontAwesomeIcon icon={fasGripVertical} fixedWidth />
                                                </div>
                                                </div>
                                            </div>
                                              <button
                                                className="btn btn-link text-muted"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  e.stopPropagation();
                                                  let imageList = [ ...images ];
                                                  imageList.splice(index, 1);
                                                  setImages(imageList);
                                                }}
                                              >
                                                <FontAwesomeIcon icon={falTrashAlt} fixedWidth />
                                              </button>
                                            </li>
                                          );
                                        })}
                                      </ReactSortable>
                            <div className="d-flex alignment-content-center px-2"><p className="my-2 small">
                            <a href="#" onClick={(e)=>{e.preventDefault(); open();}} className="text-decoration-none">
                            <FontAwesomeIcon icon={faPlus} /> Add more files
                            </a>
                            </p></div>

                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          )}
                          { images?.length === 0 &&(
                            <div
                              className="dropzone-content text-center dpzone-content">
                              <div className="zindex1">
                                <p>
                                  <FontAwesomeIcon icon={falArrowToBotton} size="5x" />
                                </p>
                                <p className="fw-500 mb-2">
                                  <a href="#" onClick={(e)=>{e.preventDefault(); open();}}>
                            Choose files
                                  </a> or drag them here
                                </p>
                                <p className="text-black-50 small">
                            Max file size 10MB. Supported file types JPG, JPEG and PNG. High resolution uploads are scaled to 1024px (w/h) for performance.
                                </p>
                              </div>
                            </div>
                          )}
                          {uploadInProcess && <div className="modal-body bg-light progress-body">
                            <form className="form needs-validation mx-auto progress-width" noValidate>
                              {uploadProgress >= 0 && uploadProgress < images.length && <div className="text-center">
                                <div className="py-4">
                                  <span className="d-inline-block m-auto mb-3 border rounded-circle text-center d-flex align-items-center justify-content-center text-dark border-dark border-2 progress-circle">
                                    <FontAwesomeIcon icon={falSpinner} spin size="2x" />
                                  </span>
                                  <p className="lead fw-400">{uploadProgress}/{images.length} uploaded</p>

                                  <p className="text-muted fs-sm">Please do not close the window.</p>
                                  <div className="progress mb-5 progress-height">
                                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${(uploadProgress*100)/images.length}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" />
                                  </div>
                                </div>
                              </div>}
                              {uploadProgress == images.length && <div className="text-center">
                                <div className="py-4">
                                  {assignToStageProgress >= 0 && assignToStageProgress < uploadedCount && <div className="text-center">
                                    <span className="d-inline-block m-auto mb-3 border rounded-circle text-center d-flex align-items-center justify-content-center text-dark border-dark border-2 progress-circle">
                                      <FontAwesomeIcon icon={falSpinner} spin size="2x" />
                                    </span>
                                    <p className="lead fw-400">{assignToStageProgress}/{uploadedCount} assigned</p>

                                    <p className="text-muted fs-sm">Please do not close the window.</p>
                                    <div className="progress mb-5 progress-height">
                                      <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${(assignToStageProgress*100)/uploadedCount}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" />
                                    </div>
                                  </div>}
                                  { assignToStageProgress >= uploadedCount && <>
                                    <span className="d-inline-block m-auto mb-3 border rounded-circle text-center d-flex align-items-center justify-content-center text-success border-success border-2 progress-circle">
                                      <FontAwesomeIcon icon={falCheck} size="2x" className="text-success" />
                                    </span>
                                    <p className="lead fw-400 text-success">Complete</p>
                                    <p className="text-muted fs-sm">Images uploaded & assigned to versions.</p>
                                  <div className="progress mb-5 progress-height" >
                                      <div className="progress-bar" role="progressbar" style={{ width: `${(assignToStageProgress*100)/uploadedCount}%` }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" />
                                    </div>
                                      <button type="buttonuploadInProcess" className="btn btn-primary mx-auto"
                                              data-bs-dismiss="modal" onClick={(e) => {
                                        e.stopPropagation();
                                        hide()
                                      }}>Go to Study</button>
                                  </>
                                  }
                                </div>
                              </div>}
                            </form>
                          </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default AddMediaModal;
