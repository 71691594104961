import { api } from "../../../redux/toolkit/apiSlice";

export const extendedApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    // Project endpoints
    getAllProjects: builder.query({
      query: ({ query, view, pageIndex, itemsPerPage, orderby, sortby }) =>
        `/manage/projects?query=${query}&view=${view}&orderBy=${orderby}&sortBy=${sortby}&pageIndex=${pageIndex}&itemsPerPage=${itemsPerPage}`,
      providesTags: ["Project"],
      transformResponse: (response) => {
        return {
          projects: response.payload.projects,
          maxPageIndex: response.payload.maxPageIndex,
          maxRecordCount: response.payload.maxRecordCount,
          pageIndex: response.payload.pageIndex,
        };
      }
    }),
    getProject: builder.query({
      query: ({ projectId }) => `/manage/projects/${projectId}`,
      transformResponse: (response) => {
        return {
          project: response.payload.project,
        };
      },
    }),
    createProject: builder.mutation({
      query: (data) => ({
        url: "/manage/projects",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Project"],
    }),
    updateProject: builder.mutation({
      query: ({ projectId, data }) => ({
        url: `/manage/projects/${projectId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteProject: builder.mutation({
      query: ({ id }) => ({
        url: `/manage/projects/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
  }),
});

export const {
  useGetAllProjectsQuery,
  useGetProjectQuery,
  useUpdateProjectMutation,
  useCreateProjectMutation,
  useDeleteProjectMutation,
} = extendedApiSlice;
