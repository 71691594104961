import { all, call, put, takeEvery } from "redux-saga/effects";
import { Toaster } from "../../components/Toast";
import HttpClient from "../../config/http-client";
import {
  CalculateRectRegionFailure,
  CalculateRectRegionRequested,
  CalculateRectRegionSuccess,
  CALCULATE_RECT_REGION,
  CalculatePolygonRegionFailure,
  CalculatePolygonRegionRequested,
  CalculatePolygonRegionSuccess,
  CALCULATE_POLYGON_REGION,
  CalculateVisualHierarchyFailure,
  CalculateVisualHierarchyRequested,
  CalculateVisualHierarchySuccess,
  CALCULATE_VISUAL_HIERARCHY,
} from "../actions/scoring-action-types";

export function* CalculateRectRegions({ payload: { key, type, indexId, shapes, bias, callback } }) {
  yield put(CalculateRectRegionRequested());

  const payload = {
    method: "post",
    url: "/web/images/regions",
    data: {
      key: key,
      type,
      bias,
      regions: shapes,
    },
  };

  const { error, result } = yield call(HttpClient, payload, true, true);

  if (error) {
    yield put(CalculateRectRegionFailure(error));
    Toaster({ message: `Failed to calculate ${type} region`, type: "error" });
  } else {
    yield put(CalculateRectRegionSuccess({ result, indexId, type }));
    // Toaster({message:`${type} region calculated successfully.`});
  }
  if (callback) callback(error, result);

  return { error, result };
}

export function* CalculatePolygonRegions({ payload: { key, type, indexId, shapes, bias, callback } }) {
  yield put(CalculatePolygonRegionRequested());

  const payload = {
    method: "post",
    url: "/web/images/polygons",
    data: {
      key: key,
      type,
      bias,
      polygons: shapes,
    },
  };

  const { error, result } = yield call(HttpClient, payload, true, true);

  if (error) {
    yield put(CalculatePolygonRegionFailure(error));
    Toaster({ message: `Failed to calculate ${type} polygon region`, type: "error" });
  } else {
    yield put(CalculatePolygonRegionSuccess({ result, indexId, type }));
    // Toaster({message:`${type} polygon region calculated successfully.`});
  }
  if (callback) callback(error, result);

  return { error, result };
}

export function* CalculateVisualHierarchy({ payload: { key, type, indexId, shapes, bias, callback } }) {
  yield put(CalculateVisualHierarchyRequested());

  const payload = {
    method: "post",
    url: "/web/images/shapes",
    data: {
      key: key,
      type,
      bias,
      shapes: shapes,
    },
  };

  const { error, result } = yield call(HttpClient, payload, true, true);

  if (error) {
    yield put(CalculateVisualHierarchyFailure(error));
    Toaster({ message: "Failed to calculate Visual hierarchy.", type: "error" });
  } else {
    yield put(CalculateVisualHierarchySuccess({ result, indexId, type }));
  }

  if (callback) callback(error, result);

  return { error, result };
}

function* Scoring() {
  yield all([
    takeEvery(CALCULATE_RECT_REGION, CalculateRectRegions),
    takeEvery(CALCULATE_POLYGON_REGION, CalculatePolygonRegions),
    takeEvery(CALCULATE_VISUAL_HIERARCHY, CalculateVisualHierarchy),
  ]);
}

export default Scoring;
