import React from "react";
import { Image } from "react-konva";
import useImage from "../../../../hooks/useImage";

export default function CreateImage(props) {

  const { forwardRef, imageHeight, imageWidth, xPos, yPos } = props;
  const [ image ] = useImage(props.url);


  return (
    <Image image={image} ref={forwardRef} height={imageHeight} width={imageWidth} x={xPos} y={yPos} />
  );
}