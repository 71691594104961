import { all, call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { Toaster } from "../../components/Toast";
import HttpClient from "../../config/http-client";
import {
  DeleteFileFailure,
  DeleteFileRequested,
  DeleteFileSuccess,
  DELETE_FILE,
  UploadFileFailure,
  UploadFileRequested,
  UploadFileSuccess,
  UPLOAD_FILE,
} from "../actions/file-action-types";
import { deepCopy } from "../../utilities/genericFunctions";
// import axios from "axios";
import { preSignedAPIFlow } from "@/utilities/preSignedAPIFlow";

export function* UploadFile({ payload: { file, callback } }) {
  const tempId = file.name + Date.now();
  yield put(UploadFileRequested({ tempId }));

  try {
    const { result } = yield preSignedAPIFlow(file, "/web/images/upload/url", "/web/images/upload/process");
    yield put(UploadFileSuccess({ api: result, tempId }));
    Toaster({ message: "File uploaded successfully" });
    if (callback) {
      let response = deepCopy(result);
      callback(null, response);
    }
    return { error: null, result: result };
  } catch (error) {
    yield put(UploadFileFailure({ error, tempId }));
    Toaster({ message: "Failed to upload file", type: "error" });
    if (callback) {
      callback(error, null);
    }
    return { error, result: null };
  }
}

export function* DeleteFile({ payload: { key, callback } }) {
  yield put(DeleteFileRequested());

  const payload = {
    method: "post",
    url: "/web/images/delete",
    data: { key: key },
  };

  const { error, result } = yield call(HttpClient, payload);

  if (error) {
    yield put(DeleteFileFailure(error));
    Toaster({ message: "Failed to delete file", type: "error" });
  } else {
    yield put(DeleteFileSuccess(result));
    Toaster({ message: "File deleted successfully" });
  }
  if (callback) callback(error, result);
  return { error, result };
}

function* File() {
  yield all([takeEvery(UPLOAD_FILE, UploadFile), takeLatest(DELETE_FILE, DeleteFile)]);
}

export default File;
