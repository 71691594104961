import httpClient from "@/config/http-client";
import axios from "axios";
import { call } from "redux-saga/effects";

export function* preSignedAPIFlow(file, uploadURL, processURL, processPostBody) {
  /*
   * Each image upload uses 3 API calls - this is so we can upload images that are larger than 5MB
   * 1. Get pre-signed url from server
   * 2. Upload file to s3 using pre-signed url
   * 3. Send the key to server for so it can process the image
   */

  /*
   * API call one - this returns a pre-signed url from the server with the key
   * e.g { url: ""https://df-dev-web-media.s3.eu-west-1.amazonaws.com/...", "key": "31c39048-f360-48e4-a9a8-dc6b115ad3b6" }
   */
  const preSignedPayload = {
    method: "get",
    url: uploadURL,
  };
  const { error: preSignedError, result: preSignedRes } = yield call(httpClient, preSignedPayload);
  if (preSignedError) throw preSignedError;

  /*
   * API call two - this uploads the file to s3 using the pre-signed url
   * the API expects the file to be sent as binary data
   * we don't receive a response from this API call other than a status code
   */
  const { error: preSignedUploadError } = yield call(axios.put, preSignedRes.url, file, {
    headers: {
      "Content-Type": file.type,
    },
  });
  if (preSignedUploadError) throw preSignedUploadError;

  /*
   * API call three - this sends the key from API call one to the server so it can process the image that was uploaded in API call two
   * e.g { "key": "31c39048-f360-48e4-a9a8-dc6b115ad3b6" }
   * the server will process the image and return the data of the processed image
   * this also allows us to generate a clarity score for the image before the user has added it to their study
   */
  if(processPostBody){
    processPostBody.key = preSignedRes.key
    // return processPostBody;
  }
  const processPayload = {
    method: "POST",
    url: processURL,
    data: processPostBody || { key: preSignedRes.key },
  };
  const { error: processError, result: processRes } = yield call(httpClient, processPayload);
  if (processError) throw processError;
  return { error: null, result: processRes };
}
