import { faFrame, faLayerGroup, faUserCircle } from "@fortawesome/pro-regular-svg-icons";
import { faLayerGroup as fasLayerGroup } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "react-bootstrap";
import { Tooltip } from "react-tooltip";

export const ContextCard = ({
  cardMeta,
  cardCoverImage = cardMeta?.status ? cardMeta.contexts[0]?.thumbUrl : cardMeta?.thumbUrl,
  contextSelection,
  setContextSelection,
  select = false,
}) => {
  const cardTitle = cardMeta?.filename ? cardMeta.filename : cardMeta.name;
  const useCaseDisplay = cardMeta.useCase?.name;
  const isCloned = cardMeta?.isClone;
  const status = cardMeta?.status;

  const cardSelected =
    select && contextSelection?.length > 0 ? contextSelection?.find((item) => item.id === cardMeta.id) : false;

  const handleContextSelection = (e) => {
    setContextSelection((prev) => [cardMeta]);
  };

  return (
    <Card
      className={`shadow-sm reveal-overlay-hover me-3 mb-3 border ${
        cardSelected ? "border-primary shadow-primary" : ""
      } ${cardMeta?.disabled ? "disabled" : ""}`}
      style={{ width: "16rem" }}
    >
      <Card.Img
        as="div"
        variant="top"
        className={`${cardMeta?.disabled ? "disabled" : "cursor-pointer"} position-relative img-box`}
        style={{
          backgroundImage: `url(${cardCoverImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        onClick={() => (select ? handleContextSelection() : null)}
      >
        {cardMeta?.status && (
          <span className="position-absolute d-inline-block p-2 bg-secondary text-white fs-xs bottom-0 start-0">
            <FontAwesomeIcon icon={fasLayerGroup} /> {cardMeta?.contexts?.length}
          </span>
        )}
      </Card.Img>

      <Card.Body style={{ padding: "0rem" }}>
        <div className="position-relative" style={{ padding: "1rem", paddingLeft: "4.25rem" }}>
          <span
            className="icon border rounded-circle position-absolute top-0 start-0 text-center d-flex align-items-center justify-content-center text-info border-info"
            style={{ width: "2.75rem", height: "2.75rem", margin: "0.75rem 0 0 0.75rem" }}
          >
            <FontAwesomeIcon icon={cardMeta?.status ? faLayerGroup : faFrame} size="lg" />
          </span>
          <Card.Title
            as="h6"
            className="fw-700 mb-1 text-truncate text-nowrap pe-5"
            data-tooltip-id="context-card-title"
            data-tooltip-content={cardTitle}
          >
            {cardTitle}
          </Card.Title>
          <Tooltip
            place="bottom"
            effect="solid"
            variant="light"
            id="context-card-title"
            style={{ boxShadow: "1px 1px 6px rgba(0, 0, 0, 0.05)" }}
          />
          <Card.Text
            as="p"
            className="badge text-info fw-500 text-uppercase fs-xxs p-1 m-0"
            style={{ backgroundColor: "rgba(6,78,142,.1)" }}
          >
            {useCaseDisplay}
          </Card.Text>
        </div>

        <div className="card-footer d-flex text-muted fs-xs">
          {isCloned && (
            <span className="d-inline-block text-nowrap text-truncate w-50 text-decoration-none text-secondary">
              <img src="/img/dragonfly-min-icon.svg" height={12} style={{ marginTop: "-0.1rem" }} alt="" />
              &nbsp;Dragonfly
            </span>
          )}
          {!isCloned && (
            <span className="d-inline-block text-nowrap text-truncate w-50 text-decoration-none text-secondary">
              <FontAwesomeIcon icon={faUserCircle} fixedWidth />
              {` ${cardMeta?.user.firstName} ${cardMeta?.user.lastName}`}
            </span>
          )}
          {status && (
            <span className="text-decoration-none text-secondary ms-auto">
              {status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
            </span>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};
