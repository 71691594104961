import { createAction } from "redux-actions";

export const GENERATE_HOTSPOTS = "GENERATE_HOTSPOTS";
export const GenerateHotspots = createAction(GENERATE_HOTSPOTS);

export const GENERATE_HOTSPOTS_REQUESTED = "GENERATE_HOTSPOTS_REQUESTED";
export const GenerateHotspotsRequested = createAction(GENERATE_HOTSPOTS_REQUESTED);

export const GENERATE_HOTSPOTS_FAILURE = "GENERATE_HOTSPOTS_FAILURE";
export const GenerateHotspotsFailure = createAction(GENERATE_HOTSPOTS_FAILURE);

export const GENERATE_HOTSPOTS_SUCCESS = "GENERATE_HOTSPOTS_SUCCESS";
export const GenerateHotspotsSuccess = createAction(GENERATE_HOTSPOTS_SUCCESS);