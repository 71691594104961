import { createAction } from "redux-actions";

// GenerateMap
export const GENERATE_MAP = "GENERATE_MAP";
export const GenerateMap = createAction(GENERATE_MAP);
export const GENERATE_MAP_REQUESTED = "GENERATE_MAP_REQUESTED";
export const GenerateMapRequested = createAction(GENERATE_MAP_REQUESTED);
export const GENERATE_MAP_FAILURE = "GENERATE_MAP_FAILURE";
export const GenerateMapFailure = createAction(GENERATE_MAP_FAILURE);
export const GENERATE_MAP_SUCCESS = "GENERATE_MAP_SUCCESS";
export const GenerateMapSuccess = createAction(GENERATE_MAP_SUCCESS);

// GenerateContextMap
export const GENERATE_CONTEXT_MAP = "GENERATE_CONTEXT_MAP";
export const GenerateContextMap = createAction(GENERATE_CONTEXT_MAP);
export const GENERATE_CONTEXT_MAP_REQUESTED = "GENERATE_CONTEXT_MAP_REQUESTED";
export const GenerateContextMapRequested = createAction(GENERATE_CONTEXT_MAP_REQUESTED);
export const GENERATE_CONTEXT_MAP_FAILURE = "GENERATE_CONTEXT_MAP_FAILURE";
export const GenerateContextMapFailure = createAction(GENERATE_CONTEXT_MAP_FAILURE);
export const GENERATE_CONTEXT_MAP_SUCCESS = "GENERATE_CONTEXT_MAP_SUCCESS";
export const GenerateContextMapSuccess = createAction(GENERATE_CONTEXT_MAP_SUCCESS);