import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faSearch, faTag, faCalendarAlt, faLongArrowDown, faLongArrowUp, faPencil, faUserFriends, faArchive, faTrashAlt, faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { faUserFriends as falUserFriends,faLock as falLock, faUserCircle as falUserCircle, faEllipsisV as falEllipsisV, faArchive as falArchive } from "@fortawesome/pro-light-svg-icons";

import { ToggleModal, DeleteProjectModal, ManageAccessProjectModal, RenameProjectModal, NewStudyModal } from "../../../components/Modals";

import { GetProject, StudyFiltersSuccess } from "../../../redux/actions/project-action-types";
import { useUpdateProjectMutation } from "../../projects/api/projectsSlice";
import { Toaster } from "../../../components/Toast";

export const Navbar = ({ setIsProjectArchived }) => {
  const { project_id } = useParams();

  const dispatch = useDispatch();
  const SORT_ARRAY = { "name": "Name", "createdat": "Created", "updatedat": "Modified" };

  const StudyFilters = useSelector((state) => state.project.studyFilters);

  const Project = useSelector((state) => state.project.getProject?.data);

  let navigate = useNavigate();
  /*Persisting the list filter*/
  const tabChange = (value) => {
    dispatch(StudyFiltersSuccess({ view: value, pageIndex: 1 }));
  };
  /*Persisting the order filter*/
  const orderChange = (value) => {
    dispatch(StudyFiltersSuccess({ orderby: value }));
  };
  /*Persisting the sort filter*/
  const sortChange = (value) => {
    dispatch(StudyFiltersSuccess({ sortby: value }));
  };

  const onSearch = (e) => {
    dispatch(StudyFiltersSuccess({ query: e.target.value.trimStart(), pageIndex: 1 }));
  };

  const [ archiveProject ] = useUpdateProjectMutation();

  const archivedProject = async (project) => {
    try {
      await archiveProject({ data: { name: project.name, visibility: project.visibility, isArchived: !project.isArchived }, projectId: project.id }).unwrap();
      setIsProjectArchived(!project.isArchived);
      Toaster({ message: "Project successfully archived." });
    } catch (error) {
      Toaster({ message: "Project archive failed.", type: "error" });
    }
  };

  return (
    <>
      <div className="d-flex mb-3 mt-1">
        <div className="d-flex align-items-center">
          <button className="d-inline-block float-left btn btn-outline-dark me-3" onClick={() => navigate("/projects")}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <h1 className="d-inline-block h4 fw-700 mt-1 mb-0 text-nowrap text-truncate overflow-hidden mw-20" title={Project?.name}>{Project?.name}</h1>
        </div>
        <nav className="navbar navbar-expand-lg align-items-middle justify-content-between p-0 ms-auto">
            <div className="btn-toolbar d-none d-md-flex ms-auto align-items-center" role="toolbar" aria-label="Toolbar with button groups">
              {Project?.isArchived ?
                <span className="btn text-decoration-none text-dark">
                  <FontAwesomeIcon icon={falArchive} size="lg" className="me-1" /> Archived
                </span> : null}

              <span className="text-decoration-none text-dark">
                <FontAwesomeIcon icon={falUserCircle} size="lg" /> {`${Project?.user?.firstName} ${Project?.user?.lastName}`}
              </span>


              <ToggleModal
                toggle={(show) => (
                  <button className="btn btn-link text-decoration-none text-dark" onClick={show}>
                    <FontAwesomeIcon icon={Project?.visibility === "public" ? falUserFriends : falLock} size="lg" /> {Project?.visibility === "public" ? "Shared" : "Private"}
                  </button>
                )}
                content={(hide) => <ManageAccessProjectModal hide={(updateSuccess = false) => {
                  hide();
                  if (updateSuccess) {
                    dispatch(
                      GetProject({ projectId: project_id })
                    );
                  }
                }} project={Project} />}
              />

              <div className="dropdown ms-2">
                <button className="btn btn-outline-dark" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <FontAwesomeIcon icon={falEllipsisV} size="lg" className="mx-1" />
                </button>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <h6 className="dropdown-header">Actions</h6>
                  </li>
                  <li>
                    <ToggleModal
                      toggle={(show) => (
                        <span className="dropdown-item cursor-pointer" onClick={show}>
                          <FontAwesomeIcon icon={faPencil} fixedWidth className="me-1" /> Rename
                        </span>
                      )}
                      content={(hide) => <RenameProjectModal hide={(updateSuccess = false) => {
                        hide();
                        if (updateSuccess) {
                          dispatch(
                            GetProject({ projectId: project_id })
                          );
                        }
                      }} project={Project} />}
                    />
                  </li>
                  <li>
                    <ToggleModal
                      toggle={(show) => (
                        <span className="dropdown-item cursor-pointer" onClick={show}>
                          <FontAwesomeIcon icon={faUserFriends} fixedWidth className="me-1" /> Manage access
                        </span>
                      )}
                      content={(hide) => <ManageAccessProjectModal hide={(updateSuccess = false) => {
                        hide();
                        if (updateSuccess) {
                          dispatch(
                            GetProject({ projectId: project_id })
                          );
                        }
                      }} project={Project} />}
                    />
                  </li>
                  <li>
                    <span className="dropdown-item cursor-pointer" onClick={() => archivedProject(Project)}>
                      <FontAwesomeIcon icon={faArchive} className="me-1" fixedWidth /> {Project?.isArchived ? "Unarchive" : "Archive"}
                    </span>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <ToggleModal
                      toggle={(show) => (
                        <span className="dropdown-item text-danger cursor-pointer" onClick={show}>
                          <FontAwesomeIcon icon={faTrashAlt} fixedWidth className="me-1" /> Delete
                        </span>
                      )}
                      content={(hide) => <DeleteProjectModal hide={(deleteSuccess = false) => {
                        hide();
                        if (deleteSuccess) {
                          navigate("/projects");
                        }
                      }} project={Project} />}
                      dialogClassName={"modal-md"}
                      contentClassName={"bg-light"}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </nav>
      </div>
      <nav className="navbar mb-3">
        <div className="container-fluid d-flex flex-row p-0 ">
          <h1 className="navbar-brand mb-0 fw-700">Studies</h1>
          <ul className="nav">
            <li className="nav-item">
              <span className={`${StudyFilters.view === "all" ? "active text-primary" : "text-muted"} nav-link fw-500 cursor-pointer`} onClick={() => tabChange("all")}>
                All
              </span>
            </li>
            <li className="nav-item">
              <span className={`${StudyFilters.view === "archived" ? "active text-primary" : "text-muted"} nav-link fw-500 cursor-pointer`} onClick={() => tabChange("archived")}>
                Archived
              </span>
            </li>
          </ul>
          <form className="d-flex ms-auto me-2">
            <div className="input-group">
              <span className="input-group-text bg-white" id="basic-addon1">
                <FontAwesomeIcon icon={faSearch} fixedWidth />
              </span>
              <input type="search" className="form-control" placeholder="Search..." value={StudyFilters.query}
                onChange={(e) => onSearch(e)}
                onKeyPress={e => {
                  const keyCode = e.keyCode || e.which;
                  if (13 === keyCode) {
                    e.preventDefault();
                    return false
                  }
                }} />
            </div>
          </form>
          <form className="d-flex me-2">
            <div className="btn-group">
              <button type="button" className="btn btn-light bg-white border border-form-control" data-bs-toggle="dropdown" aria-expanded="false">
                {SORT_ARRAY[StudyFilters.orderby]} <FontAwesomeIcon icon={faAngleDown} fixedWidth />
              </button>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <h6 className="dropdown-header">Sort by</h6>
                </li>
                <li>
                  <span className="dropdown-item cursor-pointer" onClick={() => orderChange("name")}>
                    <FontAwesomeIcon icon={faTag} fixedWidth className="me-1" /> Name
                  </span>
                </li>
                <li>
                  <span className="dropdown-item cursor-pointer" onClick={() => orderChange("createdat")}>
                    <FontAwesomeIcon icon={faCalendarAlt} fixedWidth className="me-1" /> Created
                  </span>
                </li>
                <li>
                  <span className="dropdown-item cursor-pointer" onClick={() => orderChange("updatedat")}>
                    <FontAwesomeIcon icon={faCalendarAlt} fixedWidth className="me-1" /> Modified
                  </span>
                </li>
              </ul>
              <button type="button" className="btn btn-light bg-white border border-form-control" onClick={() => sortChange(StudyFilters.sortby === "asc" ? "desc" : "asc")}>
                <FontAwesomeIcon icon={StudyFilters.sortby === "asc" ? faLongArrowDown : faLongArrowUp} fixedWidth />
              </button>
            </div>
          </form>
          <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
            <div className="btn-group" role="group" aria-label="Third group">
              <ToggleModal
                toggle={(show) => (
                  <button className="btn btn-primary" onClick={show}>
                    New study
                  </button>
                )}
                content={(hide) => <NewStudyModal hide={(createSuccess = false) => {
                  hide();
                  if (createSuccess) {
                    navigate(`${createSuccess.payload.study.id}`);
                  }
                }} project={Project} />}
              />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
