import { REHYDRATE } from "redux-persist";
import {
  GENERATE_MAP_FAILURE,
  GENERATE_MAP_REQUESTED,
  GENERATE_MAP_SUCCESS,
  GENERATE_CONTEXT_MAP_FAILURE,
  GENERATE_CONTEXT_MAP_REQUESTED,
  GENERATE_CONTEXT_MAP_SUCCESS,
} from "../actions/processing-action-types";

const initialState = {
  mapImage: {},
  contextMapImage: {},
};

export default function processing(state = initialState, { payload, type }) {
  switch (type) {
    case GENERATE_MAP_REQUESTED: {
      return { ...state, mapImage: { loading: true, error: false, data: null } };
    }
    case GENERATE_MAP_FAILURE: {
      return { ...state, mapImage: { loading: false, error: true, data: null } };
    }
    case GENERATE_MAP_SUCCESS: {
      return { ...state, mapImage: { loading: false, error: false, data: payload } };
    }
    case GENERATE_CONTEXT_MAP_REQUESTED: {
      return { ...state, contextMapImage: { loading: true, error: false, data: null } };
    }
    case GENERATE_CONTEXT_MAP_FAILURE: {
      return { ...state, contextMapImage: { loading: false, error: true, data: null } };
    }
    case GENERATE_CONTEXT_MAP_SUCCESS: {
      return { ...state, contextMapImage: { loading: false, error: false, data: payload } };
    }

    case REHYDRATE: {
      let data = { ...initialState };
      if (payload?.processing) {
        data = payload.processing;
      }
      return data;
    }

    default:
      return state;
  }
}
