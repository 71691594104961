import { all, call, debounce, put, takeLatest, takeEvery } from "redux-saga/effects";
import { Toaster } from "../../components/Toast";
import httpClient from "../../config/http-client";
import {
  CreateProjectFailure,
  CreateProjectRequested,
  CreateProjectSuccess,
  CREATE_PROJECT,
  GetProjectFailure,
  GetProjectRequested,
  GetProjectSuccess,
  GET_PROJECT,
  GetAllProjectsFailure,
  GetAllProjectsRequested,
  GetAllProjectsSuccess,
  GET_ALL_PROJECTS,
  GetSearchProjectsFailure,
  GetSearchProjectsRequested,
  GetSearchProjectsSuccess,
  GET_SEARCH_PROJECTS,
  DeleteProjectFailure,
  DeleteProjectRequested,
  DeleteProjectSuccess,
  DELETE_PROJECT,
  UpdateProjectFailure,
  UpdateProjectRequested,
  UpdateProjectSuccess,
  UPDATE_PROJECT,
  CreateStudyFailure,
  CreateStudyRequested,
  CreateStudySuccess,
  CREATE_STUDY,
  DeleteStudyFailure,
  DeleteStudyRequested,
  DeleteStudySuccess,
  DELETE_STUDY,
  GetStudyFailure,
  GetStudyRequested,
  GetStudySuccess,
  GET_STUDY,
  GetAllStudiesFailure,
  GetAllStudiesRequested,
  GetAllStudiesSuccess,
  GET_ALL_STUDIES,
  UpdateStudyFailure,
  UpdateStudySuccess,
  UPDATE_STUDY,
  MoveStudyFailure,
  MoveStudyRequested,
  MoveStudySuccess,
  MOVE_STUDY,
  UpdateMediaStudyFailure,
  UpdateMediaStudyRequested,
  UpdateMediaStudySuccess,
  UPDATE_MEDIA_STUDY,
} from "../actions/project-action-types";

export function* GetAllProjects({ payload: { query, view, pageIndex, itemsPerPage, sortby, orderby } }) {
  yield put(GetAllProjectsRequested());
  const payload = {
    method: "get",
    url: "/manage/projects",
    params: { query, view, pageIndex, itemsPerPage, orderby, sortby },
  };
  const { result, error } = yield call(httpClient, payload);
  if (error) {
    yield put(GetAllProjectsFailure());
    Toaster({ message: "Failed to fetch all projects", type: "error" });
  } else {
    yield put(GetAllProjectsSuccess({ itemsPerPage, ...result.payload }));
  }
}
export function* GetSearchProjects({ payload: { query, view, pageIndex, itemsPerPage, sortby, orderby } }) {
  yield put(GetSearchProjectsRequested());
  const payload = {
    method: "get",
    url: "/manage/projects",
    params: { query, view, pageIndex, itemsPerPage, orderby, sortby },
  };
  const { result, error } = yield call(httpClient, payload, false);
  if (error) {
    yield put(GetSearchProjectsFailure());
    Toaster({ message: "Failed to fetch all projects", type: "error" });
  } else {
    yield put(GetSearchProjectsSuccess({ itemsPerPage, ...result.payload }));
  }
}

export function* DeleteProject({ payload: { id, callback } }) {
  yield put(DeleteProjectRequested());
  const { result, error } = yield call(
    httpClient,
    {
      method: "delete",
      url: `/manage/projects/${id}`,
    },
    false
  );

  if (error) {
    yield put(DeleteProjectFailure(error));
    Toaster({ message: "Removing project failed.", type: "error" });
  } else {
    yield put(DeleteProjectSuccess());
    Toaster({ message: "Project removed successfully." });
  }
  if (callback) callback(error, result);
  return { error, result };
}

export function* GetProject({ payload: { projectId, callback } }) {
  yield put(GetProjectRequested());

  const { result, error } = yield call(
    httpClient,
    {
      method: "get",
      url: `/manage/projects/${projectId}`,
    },
    false
  );


  if (error) {
    yield put(GetProjectFailure(error));
  } else {
    yield put(GetProjectSuccess(result.payload.project));
  }

  if (callback) callback(error, result);
  return { error, result };
}

export function* UpdateProject({ payload: { data, projectId, callback } }) {
  yield put(UpdateProjectRequested());
  const { result, error } = yield call(
    httpClient,
    {
      data,
      method: "put",
      url: `/manage/projects/${projectId}`,
    },
    false
  );

  if (error) {
    yield put(UpdateProjectFailure(error));
    Toaster({ message: "Project update failed.", type: "error" });
  } else {
    yield put(UpdateProjectSuccess(result.payload.project));
    Toaster({ message: "Project successfully updated." });
  }
  if (callback) callback(error, result);
  return { error, result };
}

export function* CreateProject({ payload: { data, callback } }) {
  yield put(CreateProjectRequested());
  const { result, error } = yield call(
    httpClient,
    {
      data,
      method: "post",
      url: "/manage/projects",
    },
    false
  );

  if (error) {
    yield put(CreateProjectFailure(error));
    Toaster({ message: "Adding new project failed.", type: "error" });
  } else {
    yield put(CreateProjectSuccess(result.payload.project));
    Toaster({ message: "Project added successfully." });
  }

  if (callback) callback(error, result);
  return { error, result };
}

export function* GetStudy({ payload: { projectId, studyId, callback } }) {
  yield put(GetStudyRequested());

  const { result, error } = yield call(
    httpClient,
    {
      method: "get",
      url: `/manage/projects/${projectId}/studies/${studyId}`,
    },
    false
  );

  if (error) {
    yield put(GetStudyFailure(error));
    Toaster({ message: "Failed to fetch Study", type: "error" });
  } else {
    yield put(GetStudySuccess(result.payload.study));
  }

  if (callback) callback(error, result);
  return { error, result };
}

export function* GetAllStudies({ payload: { query, view, pageIndex, itemsPerPage, sortby, orderby, projectId } }) {
  yield put(GetAllStudiesRequested());
  const payload = {
    method: "get",
    url: `/manage/projects/${projectId}/studies`,
    params: { query, view, pageIndex, itemsPerPage, sortby, orderby },
  };
  const { result, error } = yield call(httpClient, payload);
  if (error) {
    yield put(GetAllStudiesFailure());
    Toaster({ message: "Failed to fetch all Studies", type: "error" });
  } else {
    yield put(GetAllStudiesSuccess({ itemsPerPage, ...result.payload }));
  }
}

export function* DeleteStudy({ payload: { projectId, studyId, callback } }) {
  yield put(DeleteStudyRequested());
  const { result, error } = yield call(
    httpClient,
    {
      method: "delete",
      url: `/manage/projects/${projectId}/studies/${studyId}`,
    },
    false
  );

  if (error) {
    yield put(DeleteStudyFailure(error));
    Toaster({ message: "Removing study from project failed.", type: "error" });
  } else {
    yield put(DeleteStudySuccess());
    Toaster({ message: "Study removed from project successfully." });
  }

  if (callback) callback(error, result);

  return { error, result };
}

export function* CreateStudy({ payload: { data, projectId, callback } }) {
  yield put(CreateStudyRequested());
  const { result, error } = yield call(
    httpClient,
    {
      data: data,
      method: "post",
      url: `/manage/projects/${projectId}/studies`,
    },
    false
  );

  if (error) {
    yield put(CreateStudyFailure(error));
    Toaster({ message: "Adding study in project failed.", type: "error" });
  } else {
    yield put(CreateStudySuccess({ result }));
    Toaster({ message: "Study added in project successfully." });
  }

  if (callback) callback(error, result);

  return { error, result };
}

export function* UpdateStudy({ payload: { data, projectId, studyId, callback } }) {
  // yield put(UpdateStudyRequested());
  const { result, error } = yield call(
    httpClient,
    {
      data,
      method: "PATCH",
      url: `/manage/projects/${projectId}/studies/${studyId}`,
    },
    false
  );

  if (error) {
    yield put(UpdateStudyFailure(error));
    Toaster({ message: "Study update failed.", type: "error" });
  } else {
    yield put(UpdateStudySuccess(result.payload.study));
    // Toaster({message: "Study successfully updated."});
  }
  if (callback) callback(error, result);
  return { error, result };
}

export function* MoveStudy({ payload: { data, projectId, studyId, callback } }) {
  yield put(MoveStudyRequested());
  const { result, error } = yield call(
    httpClient,
    {
      data,
      method: "post",
      url: `/manage/projects/${projectId}/studies/${studyId}/actions/move`,
    },
    false
  );

  if (error) {
    yield put(MoveStudyFailure(error));
    Toaster({ message: "Study moved failed.", type: "error" });
  } else {
    yield put(MoveStudySuccess(result.payload));
    Toaster({ message: "Study moved successfully to another project." });
  }
  if (callback) callback(error, result);
  return { error, result };
}

export function* UpdateMediaStudy({ payload: { data, projectId, studyId, callback } }) {
  yield put(UpdateMediaStudyRequested());
  const { result, error } = yield call(
    httpClient,
    {
      data,
      method: "patch",
      url: `/manage/projects/${projectId}/studies/${studyId}`,
    },
    false
  );

  if (error) {
    yield put(UpdateMediaStudyFailure(error));
    Toaster({ message: "Study media update failed.", type: "error" });
  } else {
    yield put(UpdateMediaStudySuccess(result.payload.study));
    // Toaster({message: "Study moved successfully to another project."});
  }
  if (callback) callback(error, result);
  return { error, result };
}

function* Projects() {
  yield all([
    debounce(400, GET_ALL_PROJECTS, GetAllProjects),
    debounce(400, GET_SEARCH_PROJECTS, GetSearchProjects),
    takeLatest(DELETE_PROJECT, DeleteProject),
    takeLatest(UPDATE_PROJECT, UpdateProject),
    takeEvery(GET_PROJECT, GetProject),
    takeLatest(CREATE_PROJECT, CreateProject),
    debounce(400, GET_ALL_STUDIES, GetAllStudies),
    takeEvery(GET_STUDY, GetStudy),
    takeLatest(DELETE_STUDY, DeleteStudy),
    takeLatest(CREATE_STUDY, CreateStudy),
    takeLatest(UPDATE_STUDY, UpdateStudy),
    takeLatest(MOVE_STUDY, MoveStudy),
    takeLatest(UPDATE_MEDIA_STUDY, UpdateMediaStudy),
  ]);
}

export default Projects;
