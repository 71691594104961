import { all, call, put, takeEvery } from "redux-saga/effects";
import { Toaster } from "../../components/Toast";
import HttpClient from "../../config/http-client";
import {
  GenerateHotspotsFailure,
  GenerateHotspotsRequested,
  GenerateHotspotsSuccess,
  GENERATE_HOTSPOTS
} from "../actions/hotspots-action-types";

export function* GenerateHotspots({ payload: { key, indexId, bias, thresholdValues, callback } }) {
  yield put(GenerateHotspotsRequested());

  let data = {
    key,
    bias,
    "type": "convex",
  }

  if (thresholdValues) {
    if (thresholdValues.stdThreshold && thresholdValues.stdThreshold !== "") {
      data.stdThreshold = thresholdValues.stdThreshold
    }
    if (thresholdValues.areaThreshold && thresholdValues.areaThreshold !== "") {
      data.areaThreshold = thresholdValues.areaThreshold
    }
    if (thresholdValues.probThreshold && thresholdValues.probThreshold !== "") {
      data.probThreshold = thresholdValues.probThreshold
    }
  }

  const payload = {
    method: "post",
    url: "/web/images/hotspots",
    data
  };

  const { error, result } = yield call(HttpClient, payload, true, true);

  if (error) {
    yield put(GenerateHotspotsFailure(error));
    Toaster({ message: "Failed to generate hotspots", type: "error" });
  } else {
    yield put(GenerateHotspotsSuccess({ result, indexId }));
  }
  if (callback) callback(error, result);

  return { error, result };
}

function* Hotspot() {
  yield all([
    takeEvery(GENERATE_HOTSPOTS, GenerateHotspots),
  ]);
}

export default Hotspot;
