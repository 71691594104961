import { REHYDRATE } from "redux-persist";
import {
  DELETE_FILE_FAILURE,
  DELETE_FILE_REQUESTED,
  DELETE_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_REQUESTED,
  UPLOAD_FILE_SUCCESS,
  HIDE_LOADER,
  SHOW_LOADER
} from "../actions/file-action-types";

const initialState = {
  uploadFile: {},
  deleteFile: {},
  visible:false,
  loading: true,
  error: false,
};

export default function file(state = initialState, { payload, type }) {
  switch (type) {
    case UPLOAD_FILE_REQUESTED: {
      const tempId = payload.tempId;

      return {
        ...state,
        uploadFile: {
          loading: true,
          error: false,
          data: state.uploadFile.data?.length
            ? [ ...state.uploadFile.data, { tempId, loading: true, error: false } ]
            : [ { tempId, error: false, loading: true } ],
        },
      };
    }
    case UPLOAD_FILE_FAILURE: {
      const tempId = payload.tempId;

      return {
        ...state,
        uploadFile: {
          loading: false,
          error: true,
          data: state.uploadFile.data?.map((_) => (_.tempId === tempId ? { ..._, error: true, loading: false } : _)),
        },
      };
    }
    case UPLOAD_FILE_SUCCESS: {
      const tempId = payload.tempId;
      delete payload.tempId;
      return {
        ...state,
        uploadFile: {
          loading: false,
          error: false,
          data: state.uploadFile.data?.map((_) => (_.tempId === tempId ? { ...payload, error: false, loading: false } : _)),
        },
      };
    }
    case DELETE_FILE_REQUESTED: {
      return {
        ...state,
        deleteFile: { loading: true, error: false, data: null },
      };
    }
    case DELETE_FILE_FAILURE: {
      return {
        ...state,
        deleteFile: { loading: false, error: true, data: null },
      };
    }
    case DELETE_FILE_SUCCESS: {
      return {
        ...state,
        deleteFile: { loading: false, error: false, data: payload },
      };
    }
    case HIDE_LOADER:
      return {
        ...state,
        type: payload || "loader",
        visible: false,
      };

    case SHOW_LOADER:
      return {
        ...state,
        type: payload || "loader",
        visible: true,
      };

    case REHYDRATE: {
      let data = { ...initialState };
      if (payload?.file) {
        data = payload.file;
      }
      return data;
    }

    default:
      return state;
  }
}
