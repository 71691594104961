import React from "react";

import { useSelector } from "react-redux";
import { Outlet } from "react-router";
import { Navigate } from "react-router-dom";
import DashboardNav from "@/components/DashboardNav";

function NavLayout() {
  const role = useSelector((state) => state.session.role);
  const accountId = useSelector((state) => state.session?.account?.id);

  document.body.className = "bg-light h-100";

  if (role === null && accountId === undefined) {
    return <Navigate to="/signout" />;
  }

  return (
    <>
    <DashboardNav/>
    <Outlet />
    </>
  );
}

export default NavLayout;
