import { createAction } from "redux-actions";

export const LOGIN = "LOGIN";
export const login = createAction(LOGIN);

export const UPDATE_SESSION = "UPDATE_SESSION";
export const updateSession = createAction(UPDATE_SESSION);

export const SIGNOUT = "SIGNOUT";
export const signout = createAction(SIGNOUT);

export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const signoutSuccess = createAction(SIGNOUT_SUCCESS);

// UpdateUser
export const UPDATE_USER = "UPDATE_USER";
export const UpdateUser = createAction(UPDATE_USER);
export const UPDATE_USER_REQUESTED = "UPDATE_USER_REQUESTED";
export const UpdateUserRequested = createAction(UPDATE_USER_REQUESTED);
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const UpdateUserFailure = createAction(UPDATE_USER_FAILURE);
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UpdateUserSuccess = createAction(UPDATE_USER_SUCCESS);

// UpdateUserPassword
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const UpdateUserPassword = createAction(UPDATE_USER_PASSWORD);
export const UPDATE_USER_PASSWORD_REQUESTED = "UPDATE_USER_PASSWORD_REQUESTED";
export const UpdateUserPasswordRequested = createAction(UPDATE_USER_PASSWORD_REQUESTED);
export const UPDATE_USER_PASSWORD_FAILURE = "UPDATE_USER_PASSWORD_FAILURE";
export const UpdateUserPasswordFailure = createAction(UPDATE_USER_PASSWORD_FAILURE);
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const UpdateUserPasswordSuccess = createAction(UPDATE_USER_PASSWORD_SUCCESS);