import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck as falCheck, faExternalLinkAlt as falExternalLinkAlt, faSpinner as falSpinner, faCircleCheck } from "@fortawesome/pro-light-svg-icons";
import { faBars as fasBars, faFolderTree as fasFolderTree } from "@fortawesome/pro-solid-svg-icons";
import { iconList } from "../../constants/reportSettings";
import { useProductAccess } from "../../hooks/useProductAccess";

function ExportModal({ hide, exportViews, handlechange, selectedReport, reports, exportProgress, exportInProcess, stopExport, exportExcel, defaultReport, includeAnalysis, handleIncludeAnalysisChange, folderFormat }) {

  const handleCloseClick = () => {
    if (exportInProcess) {
      stopExport()
    } else {
      hide()
    }
  }

  const hasContextAccess = useProductAccess("contexts");

  let exportVisualsArr = [];
  if (reports && reports.length) {
    reports.forEach(report => {
      /* Only add context report if they have access */
      if (report.type === "context" && !hasContextAccess) return;
      exportVisualsArr.push(report)
    });
  }

  return (
    <>
      {/*----------------------------Export modal start------------------*/}

      <div className="modal-header border-darken-15 bg-white">
        <h5 className="modal-title fw-light" id="exampleModalLabel">Export</h5>
        <button type="button" className="btn btn-link text-muted ms-auto" data-bs-dismiss="modal" onClick={() => handleCloseClick()}>Cancel</button>
        <button type="button" className="btn btn-primary" disabled={exportInProcess || !Object.values(selectedReport).includes(true)} onClick={() => {
          exportViews()
        }}>Export</button>
      </div>
      {!exportInProcess &&
        <div className="export-modal modal-body bg-light emd-body">
          <form className="form needs-validation mx-auto progress-width"
            noValidate>
            <div className="text-center">
              <div className="py-4">
                <span className="d-inline-block m-auto mb-3 border rounded-circle text-center d-flex align-items-center justify-content-center text-dark border-dark border-2 progress-circle">
                  <FontAwesomeIcon icon={falExternalLinkAlt} size="2x" />
                </span>
                <p className="lead fw-400">Export to PNG</p>
                <p className="fs-sm text-muted">Select the report you would like to export. You can select more than one report.</p>
              </div>
            </div>
            <div className="p-5">
              <div className="form-group mb-5">
                <div className="row">
                  <div className="col-6"><p className="fw-500">Select reports</p></div>
                  {/*<div className="col-6 text-center px-0"><p className="fw-500">Include Analysis</p></div>*/}
                </div>

                {exportVisualsArr && exportVisualsArr.length && exportVisualsArr.map((visual, index) => {
                  return (<div className="row mb-3" key={index}>
                    <div className="col-6">
                      <div className={ `d-flex py-1 px-3 rounded-1 align-items-baseline flex-grow-1 border ${defaultReport[`${visual.name}`] == false ? "disabled" :  "cursor-pointer"} ${selectedReport[`${visual.name}`] == true ? "border-primary text-primary" : "border-secondary text-secondary"}`} onClick={() => defaultReport[`${visual.name}`] == false ? null :  handlechange({ target:{ name: visual.name, type: "checkbox", checked: !selectedReport[`${visual.name}`] } })}>
                        <span className="flex-shrink-0 rounded text-center lh-sm me-2">
                          <FontAwesomeIcon icon={iconList[visual.type]}/>
                        </span>
                        <span className="flex-grow-1 text-nowrap text-truncate d-flex">
                          <span className="text-truncate cursor-pointer" data-bs-toggle="tooltip" data-bs-placement="top" title={visual.name}>{visual.name}</span>
                        </span>
                        <span className="flex-shrink-0 text-end"><FontAwesomeIcon icon={faCircleCheck} className={`align-items-end ${selectedReport[`${visual.name}`] == true ? "": "text-gray" } `}/></span>
                      </div>
                      {defaultReport[`${visual.name}`] == false && <p className="fs-xs mt-2 text-danger mb-0">There are no context within this report.</p>}
                    </div>
                    {/*<div className="col-6 align-top text-center">*/}
                      {/*{includeAnalysis[`${visual.name}`] === undefined ? <p className="text-secondary op-50 mb-0">Not available</p> : <input className={`form-check-input fs-xl ${includeAnalysis[`${visual.name}`] === undefined ? "disabled disabled-checkbox" : ""}`} type="checkbox" name={visual.name} defaultValue id={`flexCheckDefault1${index}`} onChange={handleIncludeAnalysisChange} disabled={(defaultReport[`${visual.name}`] == false || includeAnalysis[`${visual.name}`] === undefined)  ? true : false} checked={includeAnalysis[`${visual.name}`] == true ? true : false} /> }*/}
                    {/*</div>*/}
                  </div>)
                }
                )}
              </div>
              <div className="form-group mb-5">
                <p className="fw-500">Select format</p>



                <div className="row mb-3">
                  <div className="col-6">
                    <div className={ `d-flex py-1 px-3 rounded-1 align-items-baseline flex-grow-1 border ${folderFormat == "flat" ? "border-primary text-primary" : "border-secondary text-secondary"}`} onClick={() => handlechange({ target:{ name: "flat", type: "radio", value: "flat" } })}>
                        <span className="flex-shrink-0 rounded text-center lh-sm me-2">
                          <FontAwesomeIcon icon={fasBars}/>
                        </span>
                      <span className="flex-grow-1 text-nowrap text-truncate d-flex">
                          <span className="fw-500">Flat</span>
                        </span>
                      <span className="flex-shrink-0 text-end"><FontAwesomeIcon icon={faCircleCheck} className={ `align-items-end ${folderFormat == "flat" ? "" : "text-gray" }`}/></span>
                    </div>
                    <p className="fs-xs text-muted mt-2 mb-0">Export all images to a single folder</p>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-6">
                    <div className={ `d-flex py-1 px-3 rounded-1 align-items-baseline flex-grow-1 border ${folderFormat == "folders" ? "border-primary text-primary" : "border-secondary text-secondary"}`} onClick={() => handlechange({ target:{ name: "folders", type: "radio", value: "folders" } })}>
                        <span className="flex-shrink-0 rounded text-center lh-sm me-2">
                          <FontAwesomeIcon icon={fasFolderTree}/>
                        </span>
                      <span className="flex-grow-1 text-nowrap text-truncate d-flex">
                          <span className="fw-500">Folders</span>
                        </span>
                      <span className="flex-shrink-0 text-end"><FontAwesomeIcon icon={faCircleCheck} className={ `align-items-end ${folderFormat == "folders" ? "" : "text-gray" }`}/></span>
                    </div>
                    <p className="fs-xs text-muted mt-2 mb-0">Create a folder for each report</p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>}
      {/*----------------------------Export progess download modal start------------------*/}
      {exportInProcess && <div className="export-modal modal-body bg-light progress-body">
        <form className="form needs-validation mx-auto progress-width" noValidate>
          {exportProgress > 0 && exportProgress < 100 && <div className="text-center">
            <div className="py-4">
              <span className="d-inline-block m-auto mb-3 border rounded-circle text-center d-flex align-items-center justify-content-center text-dark border-dark border-2 progress-circle">
                <FontAwesomeIcon icon={falSpinner} spin size="2x" />
              </span>
              <p className="lead fw-400">{Math.round(exportProgress)}% complete</p>

              <p className="text-muted fs-sm">Please do not close the window.</p>
              <div className="progress mb-5 progress-height">
                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${Math.round(exportProgress)}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" />
              </div>
              <button type="button" className="btn btn-link text-muted mx-auto" data-bs-dismiss="modal" onClick={() => handleCloseClick()}>Cancel</button>
            </div>
          </div>}
          {exportProgress == 100 && <div className="text-center">
            <div className="py-4">
              <span className="d-inline-block m-auto mb-3 border rounded-circle text-center d-flex align-items-center justify-content-center text-dark border-dark border-2 progress-circle">
                <FontAwesomeIcon icon={falCheck} size="2x" />
              </span>
              <p className="lead fw-400">{exportProgress}% complete</p>
              <p className="text-muted fs-sm">Your download is ready.</p>
              <div className="progress mb-5 progress-height">
                <div className="progress-bar" role="progressbar" style={{ width: `${exportProgress}%` }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" />
              </div>
              <button type="button" className="btn btn-link text-muted mx-auto" data-bs-dismiss="modal" onClick={() => { stopExport(); hide(); }}>Close</button>
            </div>
          </div>}
        </form>
      </div>}
      {/*----------------------------Export progess download modal end------------------*/}

    </>
  );
}

export default ExportModal;
