import { useState } from "react";
import { Modal } from "react-bootstrap";

export const ImagePreviewModal = ({ imageUrl, title, modalMeta, hide }) => {
  const [imageSelection, setImageSelection] = useState(
    () => imageUrl || modalMeta.imageUrl || modalMeta[modalMeta.contexts ? "contexts" : "assets"][0].imageUrl
  );
  return (
    <>
      <Modal.Header closeButton onHide={hide}>
        <Modal.Title as="h5" className="fw-light">
          {title || modalMeta?.name || modalMeta?.filename}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <div className="h-100 w-100 d-flex flex-row">
          {modalMeta?.contexts?.length > 1 ? (
            <>
              <div className="h-100 p-3 flex-shrink-0 overflow-auto" style={{ width: "16rem" }}>
                <h1 className="h5 fw-700 mb-3">Contexts</h1>
                <div className="media-items">
                  {modalMeta?.contexts?.map((item) => (
                    <div
                      className="media-item mb-3 position-relative cursor-pointer"
                      key={item.id}
                      onClick={() => setImageSelection(item.imageUrl)}
                    >
                      <div
                        className={`card shadow-sm reveal-overlay-hover border ${
                          item.imageUrl === imageSelection ? "border-primary shadow-primary" : ""
                        }`}
                      >
                        <div
                          className="position-relative card-img-top"
                          style={{
                            backgroundImage: `url(${item.imageUrl})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            height: "150px",
                          }}
                        >
                          {/* <img src={item.imageUrl} className="card-img-top" alt="..." /> */}
                        </div>
                        <div className="card-body position-relative">
                          <h5 className="card-title h6 fw-700 mb-1 text-dark text-nowrap text-truncate fs-sm">
                            {item.name}
                          </h5>
                          <p className="badge bg-info-10 text-info fw-500 text-uppercase fs-xxs p-1 m-0">
                            {item.useCase?.name}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="h-100 flex-grow-1 border-end overflow-auto mw-100 mh-100"
                style={{
                  backgroundImage: `url(${imageSelection})`,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </>
          ) : modalMeta?.assets?.length > 1 ? (
            <>
              <div className="h-100 p-3 flex-shrink-0 overflow-auto" style={{ width: "16rem" }}>
                <h1 className="h5 fw-700 mb-3">Benchmarks</h1>
                <div className="media-items">
                  {modalMeta?.assets?.map((item) => (
                    <div
                      className="media-item mb-3 position-relative cursor-pointer"
                      key={item.id}
                      onClick={() => setImageSelection(item.imageUrl)}
                    >
                      <div
                        className={`card shadow-sm reveal-overlay-hover border ${
                          item.imageUrl === imageSelection ? "border-primary shadow-primary" : ""
                        }`}
                      >
                        <div
                          className="position-relative card-img-top"
                          style={{
                            backgroundImage: `url(${item.imageUrl})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            height: "150px",
                          }}
                        ></div>
                        <div className="card-body position-relative">
                          <h5 className="card-title h6 fw-700 mb-1 text-dark text-nowrap text-truncate fs-sm">
                            {item.filename}
                          </h5>
                          <p className="badge bg-info-10 text-info fw-500 text-uppercase fs-xxs p-1 m-0">
                            {item?.useCase?.name}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="h-100 flex-grow-1 border-end overflow-auto mw-100 mh-100"
                style={{
                  backgroundImage: `url(${imageSelection})`,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </>
          ) : (
            <div className="h-100 flex-grow-1 overflow-auto align-items-center justify-content-center d-flex">
              <img src={imageUrl || modalMeta?.imageUrl} className="h-100 w-auto" alt="" />
            </div>
          )}
        </div>
      </Modal.Body>
    </>
  );
};
