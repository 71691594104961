import { REHYDRATE } from "redux-persist";
import {
  UPLOAD_CONTEXT_FAILURE,
  UPLOAD_CONTEXT_REQUESTED,
  UPLOAD_CONTEXT_SUCCESS,
  CLEAR_CONTEXT,
  GET_CONTEXT_MEDIA_FAILURE,
  GET_CONTEXT_MEDIA_REQUESTED,
  GET_CONTEXT_MEDIA_SUCCESS,
  DELETE_CONTEXT_FAILURE,
  DELETE_CONTEXT_REQUESTED,
  DELETE_CONTEXT_SUCCESS,
} from "../actions/context-action-types";

const initialState = {
  uploadContext: {},
  contextMedia: {},
  deleteContext: {},
};

export default function context(state = initialState, { payload, type }) {
  switch (type) {
    case UPLOAD_CONTEXT_REQUESTED: {
      const tempId = payload.tempId;

      return {
        ...state,
        uploadContext: {
          loading: true,
          error: false,
          data: state.uploadContext.data?.length
            ? [ ...state.uploadContext.data, { tempId, loading: true, error: false } ]
            : [ { tempId, error: false, loading: true } ],
        },
      };
    }
    case UPLOAD_CONTEXT_FAILURE: {
      const tempId = payload.tempId;

      return {
        ...state,
        uploadContext: {
          loading: false,
          error: true,
          data: state.uploadContext.data?.map((_) => (_.tempId === tempId ? { ..._, error: true, loading: false } : _)),
        }
      };
    }
    case UPLOAD_CONTEXT_SUCCESS: {
      //const tempId = payload.tempId;
      delete payload.tempId;

      return {
        ...state,
        contextMedia: { 
          loading: false, 
          error: false, 
          data: state.contextMedia.data?.length ? [ ...state.contextMedia.data, payload.api.context ] : [ payload.api.context ],
        }
      };
    }

    case CLEAR_CONTEXT: {
      return {
        ...initialState
      };
    }

    case GET_CONTEXT_MEDIA_REQUESTED: {
      return {
        ...state,
        contextMedia: { loading: true, error: false, data: null },
      };
    }
    case GET_CONTEXT_MEDIA_FAILURE: {
      return {
        ...state,
        contextMedia: { loading: false, error: true, data: null },
      };
    }
    case GET_CONTEXT_MEDIA_SUCCESS: {
      return {
        ...state,
        contextMedia: { loading: false, error: false, data: payload.payload.contexts },
      };
    }
    case DELETE_CONTEXT_REQUESTED: {
      return {
        ...state,
        deleteContext: { loading: true, error: false, data: null },
      };
    }
    case DELETE_CONTEXT_FAILURE: {
      return {
        ...state,
        deleteContext: { loading: false, error: true, data: null },
      };
    }
    case DELETE_CONTEXT_SUCCESS: {
      return {
        ...state,
        contextMedia: {
          loading: false,
          error: false,
          data: state.contextMedia.data?.length ? state.contextMedia.data.filter((context)=> context.id !== payload.key) : [ ...state.contextMedia.data ],
      }
      };
    }
    case REHYDRATE: {
      let data = {
        ...initialState
      };
      if (payload?.context) {
        data = payload.context;
      }
      return data;
    }

    default:
      return state;
  }
}
