import { useField } from "formik";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner as falSpinner } from "@fortawesome/pro-light-svg-icons";
const modifyClasses = (className, meta) => {
  if (meta.touched) {
    let isInValid = meta.error;
    if (isInValid && !className.includes("is-invalid")) className = className + " is-invalid";
    if (!isInValid && !className.includes("is-valid")) className = className + " is-valid";
  }
  return className;
};

export const Checkbox = ({ children, ...props }) => {
  const [ field, meta ] = useField({ ...props, type: "checkbox" });
  return (
    <>
      <label>
        <input type="checkbox" {...field} {...props} /> {children}
      </label>
      {meta.touched && meta.error ? <div className="invalid-feedback">{meta.error}</div> : null}
    </>
  );
};

export const TextInput = React.forwardRef(({ label, ...props }, ref) => {
  const [ field, meta ] = useField(props);

  props.className = modifyClasses(props.className, meta);

  return (
    <>
      <input {...field} {...props} ref={ref}/>

      {label && <label htmlFor={props.id || props.name}>{label}</label>}
      {meta.touched && meta.error ? <div className="invalid-feedback">{meta.error}</div> : null}
    </>
  );
});

export const Button = ({
  label,
  dirty,
  errors,
  isValidating,
  isSubmitting,
  className = "btn btn-outline-primary",
  processingMsg = "Saving",
  ...props
}) => {
  return (
    <button type="submit" className={className} disabled={!dirty || Object.keys(errors).length || isValidating || isSubmitting} {...props}>
      {isValidating || isSubmitting ? (
        <>
          <FontAwesomeIcon icon={falSpinner} spin className="me-1" aria-hidden="true"></FontAwesomeIcon>
          {` ${processingMsg}`}
        </>
      ) : (
          label
        )}
    </button>
  );
};
