import { createAction } from "redux-actions";

// UploadContext
export const UPLOAD_CONTEXT = "UPLOAD_CONTEXT";
export const UploadContext = createAction(UPLOAD_CONTEXT);
export const UPLOAD_CONTEXT_REQUESTED = "UPLOAD_CONTEXT_REQUESTED";
export const UploadContextRequested = createAction(UPLOAD_CONTEXT_REQUESTED);
export const UPLOAD_CONTEXT_FAILURE = "UPLOAD_CONTEXT_FAILURE";
export const UploadContextFailure = createAction(UPLOAD_CONTEXT_FAILURE);
export const UPLOAD_CONTEXT_SUCCESS = "UPLOAD_CONTEXT_SUCCESS";
export const UploadContextSuccess = createAction(UPLOAD_CONTEXT_SUCCESS);
export const CLEAR_CONTEXT = "CLEAR_CONTEXT";
export const ClearContext = createAction(CLEAR_CONTEXT);

// GetContextMedia
export const GET_CONTEXT_MEDIA = "GET_CONTEXT_MEDIA";
export const GetContextMedia = createAction(GET_CONTEXT_MEDIA);
export const GET_CONTEXT_MEDIA_REQUESTED = "GET_CONTEXT_MEDIA_REQUESTED";
export const GetContextMediaRequested = createAction(GET_CONTEXT_MEDIA_REQUESTED);
export const GET_CONTEXT_MEDIA_FAILURE = "GET_CONTEXT_MEDIA_FAILURE";
export const GetContextMediaFailure = createAction(GET_CONTEXT_MEDIA_FAILURE);
export const GET_CONTEXT_MEDIA_SUCCESS = "GET_CONTEXT_MEDIA_SUCCESS";
export const GetContextMediaSuccess = createAction(GET_CONTEXT_MEDIA_SUCCESS);

// DeleteContext
export const DELETE_CONTEXT = "DELETE_CONTEXT";
export const DeleteContext = createAction(DELETE_CONTEXT);
export const DELETE_CONTEXT_REQUESTED = "DELETE_CONTEXT_REQUESTED";
export const DeleteContextRequested = createAction(DELETE_CONTEXT_REQUESTED);
export const DELETE_CONTEXT_FAILURE = "DELETE_CONTEXT_FAILURE";
export const DeleteContextFailure = createAction(DELETE_CONTEXT_FAILURE);
export const DELETE_CONTEXT_SUCCESS = "DELETE_CONTEXT_SUCCESS";
export const DeleteContextSuccess = createAction(DELETE_CONTEXT_SUCCESS);

// Calculate In context score
export const CALCULATE_INCONTEXT_SCORE = "CALCULATE_INCONTEXT_SCORE";
export const CalculateInContextScore = createAction(CALCULATE_INCONTEXT_SCORE);
export const CALCULATE_INCONTEXT_SCORE_REQUESTED = "CALCULATE_INCONTEXT_SCORE_REQUESTED";
export const CalculateInContextScoreRequested = createAction(CALCULATE_INCONTEXT_SCORE_REQUESTED);
export const CALCULATE_INCONTEXT_SCORE_FAILURE = "CALCULATE_INCONTEXT_SCORE_FAILURE";
export const CalculateInContextScoreFailure = createAction(CALCULATE_INCONTEXT_SCORE_FAILURE);
export const CALCULATE_INCONTEXT_SCORE_SUCCESS = "CALCULATE_INCONTEXT_SCORE_SUCCESS";
export const CalculateInContextScoreSuccess = createAction(CALCULATE_INCONTEXT_SCORE_SUCCESS);