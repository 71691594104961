import React from "react";
import Modal from "./Modal";

const ToggleModal = ({ toggle, content, className, dialogClassName, contentClassName, onClickBackdrop, wrapperProps, inPortal = true }) => {
  const [ isShown, setIsShown ] = React.useState(false);
  const [ isVisible, setIsVisible ] = React.useState(false);
  const hide = () => {
    setIsVisible(false);
    setIsShown(false)
  };
  const show = () => {
    setIsVisible(true);
    setIsShown(true);
  };

  return (
    <>
      {toggle(show)}
      {isShown && (
        <Modal
          visible={isVisible}
          fade={true}
          className={className}
          dialogClassName={dialogClassName}
          contentClassName={contentClassName}
          onClickBackdrop={onClickBackdrop}
          wrapperProps={wrapperProps}
          inPortal={inPortal}
        >
          {content(hide)}
        </Modal>
      )}
    </>
  );
};

export default ToggleModal;
