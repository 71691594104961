import { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes as falTimes } from "@fortawesome/pro-light-svg-icons";
import { Button, TextInput } from "../FormFields";
import { useCreateStudyMutation } from "@/features/studies/api/studySlice";
import { Toaster } from "../Toast";
import { useSelector } from "react-redux";
import { TagsDropdown } from "../Tags";
import { segmentAnalytics } from "@/utilities/genericFunctions";
import { OtherTag } from "../Tags/OtherTag";
import { useGetUseCasesQuery } from "@/redux/toolkit/accountSlice";
import { Accordion, Card, FormCheck } from "react-bootstrap";
import { AdvancedToggle } from "../Common/AdvancedToggle";

function NewStudyModal({ hide, project }) {
  const [createStudy, { isLoading, isError, data, isSuccess }] = useCreateStudyMutation();

  const accountId = useSelector((state) => state.session?.account?.id);

  const {
    data: { filteredUseCases: useCases } = {},
    isSuccess: useCaseIsSuccess,
    isLoading: useCaseIsLoading,
    isError: useCaseIsError,
  } = useGetUseCasesQuery({ accountId }, { refetchOnMountOrArgChange: true });

  const session = useSelector((state) => state.session);

  // sets the display value in the dropdown to the use case name
  const [useCase, setUseCase] = useState("");
  // By default all studies should have center bias, the user has the option to turn this off when creating a study
  const [hasBias, setHasBias] = useState(true);

  const onSubmit = async (value) => {
    if (value.otherTag) {
      segmentAnalytics({
        page: false,
        session,
        name: "Use Case Suggestion",
        segmentData: {
          otherUseCase: value.otherTag,
          projectId: project.id,
          studyName: value.name,
        },
      });

      Toaster({ message: "Use case suggestion added." });
    }

    try {
      const studyData = await createStudy({
        data: { name: value.name, useCaseID: value.useCaseId, bias: hasBias ? "center" : "none" },
        projectId: project.id,
      }).unwrap();
      segmentAnalytics({
        page: false,
        session,
        name: "Study Created",
        segmentData: {
          projectId: project.id,
          studyId: studyData.payload.study.id,
          studyName: studyData.payload.study.name,
          useCaseId: studyData.payload.study.useCase.id,
          useCaseName: studyData.payload.study.useCase.name,
          useCaseCategory: studyData.payload.study.useCase.category,
          bias: hasBias ? "center" : "none",
        },
      });
      Toaster({ message: "Study added in project successfully." });
    } catch ({
      data: {
        error: { message },
      },
    }) {
      if (message === "The use case does not exist.") {
        Toaster({ message: "This use case no longer exists - please log out and try again", type: "error" });
      } else {
        Toaster({ message: "Adding study in project failed.", type: "error" });
      }
    }
  };

  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 1);
    }
  }, []);

  useEffect(() => {
    // data is the new project created which is used to navigate to the project page after creation
    if (data && !isError) {
      hide(data);
    }
  }, [isSuccess]);

  return (
    <Formik
      initialValues={{
        name: "",
        useCaseId: "",
        otherTag: "",
      }}
      validationSchema={Yup.object({
        name: Yup.string()
          .required("Study name is required.")
          .matches(/^[A-Za-z 0-9]+$/, "Study names can only contain letters, numbers and spaces."),
        useCaseId: Yup.string().required(),
        otherTag: useCase !== "Other" ? Yup.string() : Yup.string().required(),
      })}
      onSubmit={onSubmit}
    >
      {({ dirty, errors, isValidating, handleSubmit }) => (
        <>
          <Form
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit();
              }
            }}
          >
            <div className="modal-header border-darken-15 bg-white">
              <h5 className="modal-title fw-light" id="exampleModalLabel">
                Study name
              </h5>
              <button
                type="button"
                className="btn btn-link text-black-50 close"
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => hide()}
              >
                <FontAwesomeIcon icon={falTimes} size="lg" className="mt-1" />
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group mb-3">
                <div className="form-label-group drop-align">
                  <TextInput
                    id="studyName"
                    label="Name"
                    name="name"
                    type="text"
                    placeholder="Name"
                    className="form-control"
                    required
                    ref={inputRef}
                    autoFocus={true}
                  />
                </div>
                <div className="form-label-group mb-2 mt-0 text-area-label-fix">
                  <TagsDropdown
                    tag={useCases}
                    setTag={setUseCase}
                    displayValue={useCase}
                    isError={useCaseIsError}
                    isSuccess={useCaseIsSuccess}
                    isLoading={useCaseIsLoading}
                    defaultDisplayValue="Select use case"
                    fieldName="useCaseId"
                    noTagsMessage="No use cases found"
                  />
                  {useCase === "Other" && (
                    <OtherTag
                      label="Feedback"
                      supportingMessage="Share your feedback here to let us know what use case options you'd like us to support."
                    />
                  )}
                </div>
              </div>
              <Accordion>
                <div>
                  <AdvancedToggle eventKey="0" />
                  <Accordion.Collapse eventKey="0" style={{ border: "none" }}>
                    <Card.Body className="fs-sm" style={{ paddingLeft: "0" }}>
                      <p>
                        Center bias mimics the brain's inclination to focus on objects in the center of vision. If your
                        test asset aims to evaluate objects' innate ability to capture attention regardless of their
                        position in the field of view, consider disabling center bias.{" "}
                        <a
                          href="https://academy.dragonflyai.co/en/articles/5605656-centre-bias"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Learn more.
                        </a>
                      </p>
                      <FormCheck
                        type="switch"
                        label="Center bias"
                        checked={hasBias}
                        onChange={() => setHasBias(!hasBias)}
                        reverse
                      />
                    </Card.Body>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </div>
            <div className="modal-footer border-darken-15 bg-white">
              <button
                type="button"
                className="btn btn-outline-dark"
                data-dismiss="modal"
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => hide()}
              >
                Discard
              </button>
              <Button
                label={"Create study"}
                className="btn btn-primary"
                dirty={dirty}
                errors={errors}
                isValidating={isValidating}
                isSubmitting={isLoading}
                onClick={handleSubmit}
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}

export default NewStudyModal;
