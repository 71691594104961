import { useState, useRef, useEffect, Fragment, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { Toaster } from "../Toast";
import CreateImage from "../../features/study/components/Editor/CreateImage";
import { Layer, Stage } from "react-konva";
import { UploadContext } from "../../redux/actions/context-action-types";
import TransformerRectangle from "../../features/study/components/Shapes/TransformerRectangle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowToBottom as falArrowToBotton,
  faSpinner as falSpinner,
  faImage,
  faDrawSquare,
  faTag,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { faLock } from "@fortawesome/pro-regular-svg-icons";
import { roundAspectRatio, segmentAnalytics, deepCopy } from "../../utilities/genericFunctions";
import { api } from "@/redux/toolkit/apiSlice";
import { Dropdown } from "react-bootstrap";
import { matchPath, useLocation } from "react-router-dom";
import { useProductAccess } from "@/hooks/useProductAccess";
import { useGetUseCasesQuery } from "@/redux/toolkit/accountSlice";
import { debounce } from "lodash";

function AddContextMediaModal({ hide, study, projectId, activeReport, updateContextSetting, setContextToStage }) {
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);
  const accountId = useSelector((state) => state.session?.account?.id);
  const {
    data: { filteredUseCases } = {},
    isSuccess,
    isLoading,
    isError,
  } = useGetUseCasesQuery({ accountId }, { refetchOnMountOrArgChange: true });

  //Array of images uploaded - currently only one image is supported
  const [images, setImages] = useState([]);
  const [ useCases, setUseCases ] = useState(filteredUseCases);
  const [tag, setTag] = useState("");
  const useCase = study?.useCase;
  const [contextUseCase, setContextUseCase] = useState(useCase || null);

  // Canvas states
  const [contextMediaArea, setContextMediaArea] = useState([]);
  const [newContextMediaArea, setNewContextMediaArea] = useState([]);
  const [contextShapeToDraw, setContextShapeToDraw] = useState([]);
  const [selectedId, selectShape] = useState(null);
  const [squareShape, setSquareShape] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(null);
  const [bgColor, setBgColor] = useState(false);

  // current pointer position
  const [pointerPosition, setPointerPosition] = useState({});

  //Default height - width scales based on view port
  const [canvasDimensions, setCanvasDimensions] = useState({ height: 450, width: 0 });

  //Upload context flags
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadInProcess, setUploadInProcess] = useState(false);

  // Refs
  const imageRef = useRef(null);

  // Dropzone bg color
  const [dropzoneBgColor, setDropzoneBgColor] = useState("#FFFFFF");
  const [dropzoneBgOpacity, setDropzoneBgOpacity] = useState("00");

  // match path to library
  const { pathname } = useLocation();
  const isLibrary = matchPath("/library/*", pathname);

  // flag to add to library or not based on if they have access
  const hasLibraryAccess = useProductAccess("contextsplus");

  useEffect(() => {
    if (images.length && uploadProgress === images.length) {
      const container = document.getElementById("drawing-zone");
      if (container) {
        container.style.cursor = "default";
      }
      hide();
    }
  }, [uploadProgress, hide, images.length]);

  useEffect(() => {
    setContextShapeToDraw([...contextMediaArea, ...newContextMediaArea]);
  }, [newContextMediaArea]);

  /* Dropzone function - acceptance critria */
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: {
      "image/jpg": [".jpg", ".jpeg", ".png"],
    },
    multiple: false,
    maxSize: 10000000,
    noClick: true,
    onDrop: async (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            Toaster({ message: "Selected file size exceed 10MB", type: "error" });
          }

          if (err.code === "file-invalid-type") {
            Toaster({ message: "Only JPG, JPEG and PNG file supported.", type: "error" });
          }
        });
      });
      const fileList = await getListFiles(acceptedFiles);

      /* Create image node to find out width/height of the file */
      const imgNode = new Image();
      imgNode.src = fileList[0].data_url;
      /* Add aspectRatio to the image state for drawing the canvas */
      imgNode.onload = () => {
        const aspectRatio = imgNode.width / imgNode.height;
        const invertRatio = imgNode.height / imgNode.width;
        fileList[0].aspectRatio = aspectRatio;
        fileList[0].invertRatio = invertRatio;
        fileList[0].width = imgNode.width;
        fileList[0].height = imgNode.height;

        /* Default scaled aspect ratio */
        let scaledWidth = 1024;
        let scaledHeight = 820;
        const { width, height } = imgNode;
        width > height
          ? (scaledHeight = (height / width) * scaledWidth)
          : (scaledWidth = (width / height) * scaledHeight);

        fileList[0].scaledWidth = scaledWidth;
        fileList[0].scaledHeight = scaledHeight;

        setImages([...images, ...fileList]);
        setCanvasDimensions((prev) => ({ ...prev, width: scaledWidth, height: scaledHeight }));
        let contextAOI = {
          x: scaledWidth > 200 ? Math.round(scaledWidth / 2 - 100) : 0,
          y: scaledHeight > 200 ? Math.round(scaledHeight / 2 - 100) : 0,
          width: scaledWidth > 200 ? 200 : scaledWidth,
          height: scaledHeight > 200 ? 200 : scaledHeight,
          id: "mediaArea",
        };
        setContextMediaArea([contextAOI]);
        setContextShapeToDraw([contextAOI]);
        const ratio = roundAspectRatio(Math.abs(Math.round(contextAOI.width)), Math.abs(Math.round(contextAOI.height)));
        setAspectRatio(ratio);
        selectShape(contextAOI.id);
      };
    },
  });

  const getBase64 = (file) => {
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.addEventListener("load", () => resolve(String(reader.result)));
      reader.readAsDataURL(file);
    });
  };

  const getListFiles = async (files) => {
    const promiseFiles = [];
    for (let i = 0; i < files.length; i += 1) {
      promiseFiles.push(getBase64(files[i]));
    }

    return Promise.all(promiseFiles).then((fileListBase64) => {
      const fileList = fileListBase64.map((base64, index) => ({
        data_url: base64,
        file: files[index],
      }));
      return fileList;
    });
  };

  const uploadContextMedia = (fileDetail, dropzone) => {
    return new Promise((resolve, reject) => {
      const callback = (err, resp) => {
        let response = deepCopy(resp);
        let activeReportCopy = deepCopy(activeReport);
        if (!err && study) {
          if (!(activeReportCopy.contextIds && activeReportCopy.contextIds.length)) {
            activeReportCopy.contextIds = [];
          }

          activeReportCopy.activeContext = response.context.id;
          activeReportCopy.contextIds.push(response.context.id);
          activeReportCopy.contextIds = activeReportCopy.contextIds.filter((ids) => ids != null);
          updateContextSetting(activeReportCopy);
          setContextToStage(response.context);
          resolve(true);

          /** Segment track event for context upload */
          segmentAnalytics({
            page: false,
            session,
            name: "Context uploaded",
            segmentData: {
              backgroundColour: dropzoneBgColor + dropzoneBgOpacity,
              projectId: projectId,
              studyId: study.id,
              reportId: activeReport.id,
            },
          });
        } else if (!err && !study) {
          // invalidate the contexts cache due to upload context endpoint being a saga
          // can be removed when this is updated to the query
          dispatch(api.util.invalidateTags(["Contexts"]));
          /** Segment track event for context upload from library */
          segmentAnalytics({
            page: false,
            session,
            name: "Library Context Upload",
            segmentData: {
              contextPackName: fileDetail.file.name,
              backgroundColour: dropzoneBgColor + dropzoneBgOpacity,
            },
          });
          resolve(true);
        } else {
          resolve(false);
        }

        // Change this to 0.5 when we can also call the performance API so that it increments on context upload and then finishes on performance upload
        setUploadProgress((uploadProgress) => uploadProgress + 1);
      };
      dispatch(
        UploadContext({
          image: fileDetail.file,
          filename: fileDetail.file.name,
          dropzone: dropzone,
          dropzoneBackground: dropzoneBgColor + dropzoneBgOpacity,
          includeInLibrary: isLibrary && hasLibraryAccess ? true : false,
          useCaseId: contextUseCase.id,
          callback,
        })
      );
    });
  };

  const onUpload = async (e, assign = false) => {
    e.preventDefault();

    const sx = contextShapeToDraw[0].x;
    const sy = contextShapeToDraw[0].y;
    const x = sx + contextShapeToDraw[0].width;
    const y = sy + contextShapeToDraw[0].height;

    // Now create the 4 corner points for the API
    let contextAOIPositions = [];

    // divide the orginal width by the scaled width and multiply by the x/y to get the correct position against the orginal size
    // we do this on upload due to not needing the scaled/ whole values until we send them to the API
    const { width, scaledWidth, height, scaledHeight } = images[0];
    contextAOIPositions[0] = { x: Math.round(sx * (width / scaledWidth)), y: Math.round(sy * (height / scaledHeight)) }; // top left
    contextAOIPositions[1] = { x: Math.round(x * (width / scaledWidth)), y: Math.round(sy * (height / scaledHeight)) }; // top right
    contextAOIPositions[2] = { x: Math.round(x * (width / scaledWidth)), y: Math.round(y * (height / scaledHeight)) }; // bottom right
    contextAOIPositions[3] = { x: Math.round(sx * (width / scaledWidth)), y: Math.round(y * (height / scaledHeight)) }; // bottom left

    if (images.length > 0) {
      setUploadInProcess(true);
    }

    for (const x of images) {
      await uploadContextMedia(x, contextAOIPositions);
    }
  };

  /* Mouse Events on canvas to draw the rect */
  const handleMouseDown = (event) => {
    /* Only one area allowed to be drawn */
    if (contextMediaArea.length === 0) {
      const { x, y } = event.target.getStage().getPointerPosition();
      setNewContextMediaArea([{ x, y, width: 0, height: 0, id: "mediaArea" }]);
    }
  };

  const handleMouseMove = (event) => {
    if (newContextMediaArea.length === 1) {
      const sx = newContextMediaArea[0].x;
      const sy = newContextMediaArea[0].y;
      const { x, y } = event.target.getStage().getPointerPosition();
      setPointerPosition({ x, y });
      let squareWidth = 0;
      if (squareShape) {
        squareWidth = Math.abs(x - sx) > Math.abs(y - sy) ? x - sx : y - sy;
      }

      if (
        !squareShape ||
        (squareShape &&
          sx + Math.sign(pointerPosition.x - sx) * Math.abs(squareWidth) > 0 &&
          sy + Math.sign(pointerPosition.y - sy) * Math.abs(squareWidth) > 0 &&
          sx + Math.sign(pointerPosition.x - sx) * Math.abs(squareWidth) < images[0].scaledWidth &&
          sy + Math.sign(pointerPosition.y - sy) * Math.abs(squareWidth) < images[0].scaledHeight)
      ) {
        let contextAOI = {
          x: sx,
          y: sy,
          width: squareShape && squareWidth ? Math.sign(pointerPosition.x - sx) * Math.abs(squareWidth) : x - sx,
          height: squareShape && squareWidth ? Math.sign(pointerPosition.y - sy) * Math.abs(squareWidth) : y - sy,
          id: "mediaArea",
        };
        setNewContextMediaArea([contextAOI]);

        const ratio = roundAspectRatio(Math.abs(Math.round(contextAOI.width)), Math.abs(Math.round(contextAOI.height)));
        setAspectRatio(ratio);
      }
    }
  };

  const handleMouseUp = (event) => {
    if (newContextMediaArea.length === 1) {
      const sx = newContextMediaArea[0].x;
      const sy = newContextMediaArea[0].y;
      let { x, y } = event.target.getStage().getPointerPosition();
      let squareWidth = 0;
      if (squareShape) {
        squareWidth = Math.abs(x - sx) > Math.abs(y - sy) ? x - sx : y - sy;
      }
      const contextAOIToAdd = {
        x: sx,
        y: sy,
        width: x - sx ? (squareShape && squareWidth ? newContextMediaArea[0].width : x - sx) : 20,
        height: y - sy ? (squareShape && squareWidth ? newContextMediaArea[0].height : y - sy) : 20,
        id: "mediaArea",
      };

      contextMediaArea.push(contextAOIToAdd);
      setNewContextMediaArea([]);
      setContextMediaArea(contextMediaArea);
      const container = document.getElementById("drawing-zone");
      container.style.cursor = "default";
      selectShape(contextAOIToAdd.id);
      const ratio = roundAspectRatio(
        Math.abs(Math.round(contextAOIToAdd.width)),
        Math.abs(Math.round(contextAOIToAdd.height))
      );
      setAspectRatio(ratio);
    }
  };

  const deleteContextShape = () => {
    setContextMediaArea([]);
    setContextShapeToDraw([]);
    selectShape(null);
    const container = document.getElementById("drawing-zone");
    if (container) {
      container.style.cursor = "crosshair";
    }
    setAspectRatio(null);
  };

  const handleKeyDown = (e) => {
    if ((e.keyCode === 46 || e.keyCode === 8) && contextMediaArea.length && contextShapeToDraw.length) {
      deleteContextShape();
    }

    if (e.keyCode === 16) {
      setSquareShape(true);
      if (newContextMediaArea[0]?.x) {
        const sx = newContextMediaArea[0].x;
        const sy = newContextMediaArea[0].y;
        let width =
          Math.abs(pointerPosition.x - sx) > Math.abs(pointerPosition.y - sy)
            ? pointerPosition.x - sx
            : pointerPosition.y - sy;

        let widthX =
          sx + Math.sign(pointerPosition.x - sx) * Math.abs(width) > 0 &&
          sx + Math.sign(pointerPosition.x - sx) * Math.abs(width) < images[0].scaledWidth
            ? width
            : sx + Math.sign(pointerPosition.x - sx) * Math.abs(width) > images[0].scaledWidth
            ? images[0].scaledWidth - sx
            : 0 - sx;
        let widthY =
          sy + Math.sign(pointerPosition.y - sy) * Math.abs(width) > 0 &&
          sy + Math.sign(pointerPosition.y - sy) * Math.abs(width) < images[0].scaledHeight
            ? width
            : sy + Math.sign(pointerPosition.y - sy) * Math.abs(width) > images[0].scaledHeight
            ? images[0].scaledHeight - sy
            : 0 - sy;

        setNewContextMediaArea([
          {
            x: sx,
            y: sy,
            width: Math.sign(pointerPosition.x - sx) * Math.abs(Math.abs(widthX) > Math.abs(widthY) ? widthY : widthX),
            height: Math.sign(pointerPosition.y - sy) * Math.abs(Math.abs(widthX) > Math.abs(widthY) ? widthY : widthX),
            id: "mediaArea",
          },
        ]);
      }
    }
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 16) {
      setSquareShape(false);
      if (newContextMediaArea[0]?.x) {
        const sx = newContextMediaArea[0].x;
        const sy = newContextMediaArea[0].y;
        setNewContextMediaArea([
          {
            x: sx,
            y: sy,
            width: pointerPosition.x - sx,
            height: pointerPosition.y - sy,
            id: "mediaArea",
          },
        ]);
      }
    }
  };

  const changeShape = (shape) => {
    let contextAOI = { ...contextShapeToDraw[0], ...shape };

    setContextMediaArea([contextAOI]);
    setContextShapeToDraw([contextAOI]);
    const ratio = roundAspectRatio(Math.abs(Math.round(contextAOI.width)), Math.abs(Math.round(contextAOI.height)));
    setAspectRatio(ratio);
  };

  const debounceSearch = useCallback(debounce(function (e) {
    const value = e.target.value.trim();
    if(value===""){
      setContextUseCase(null);
    }
    if(value.length) {
      const list = filteredUseCases.filter(tags=> tags.name && tags.name.toLowerCase().includes(value.toLowerCase()));
      setUseCases(list);
    } else {
      setUseCases(filteredUseCases);
    }
  }, 300), [ filteredUseCases ]);

  const onSearch= (e) => {
    setTag(e.target.value);
    debounceSearch(e);
  };

  const onChangeBackground = (value) => {
    setBgColor(value);
    if(value) {
      setDropzoneBgOpacity("ff");
    } else {
      setDropzoneBgOpacity("00");
    }
  };

  return (
    <>
      <div className="modal-header border-darken-15 bg-white">
        <h5 className="modal-title fw-light" id="exampleModalLabel">
          Upload Context
        </h5>

        <button
          type="button"
          className="btn btn-link text-muted ms-auto"
          onClick={(e) => {
            e.stopPropagation();
            hide();
          }}
        >
          Cancel
        </button>

        {/* Show upload button once the dropzone has been drawn onto the context */}
        <button
          type="button"
          className="btn btn-primary me-2"
          disabled={!contextMediaArea?.length || contextUseCase === null}
          onClick={(e) => onUpload(e)}
        >
          Upload
        </button>
      </div>
      <div className="modal-body p-0 position-relative media-modal">
        <div className="tab-content d-flex h-100 w-100" id="mediaModalStates">
          <div
            className="tab-pane h-100 w-100 fade show active"
            id="initial"
            role="tabpanel"
            aria-labelledby="initial-tab"
          >
            <div className="h-100 w-100 d-flex flex-row">
              {!uploadInProcess && (
                <div className="h-100 flex-shrink-0 border-end overflow-auto" style={{ width: "320px" }}>
                  <section className="border-bottom py-3">
                    <header className="position-relative ms-3 ps-5">
                      <span
                        className="d-inline-block position-absolute start-0 top-0 bg-primary-10 rounded-circle text-primary text-center"
                        style={{ width: "2.4em", height: "2.4em", paddingTop: "8px" }}
                      >
                        <FontAwesomeIcon icon={faTag} fixedWidth size="lg" />
                      </span>
                      <h2 className="fs-6 mb-1">1. Assign use case</h2>
                      <p className="text-muted fs-xs">The content type this context supports.</p>
                    </header>
                    <div className="px-3">
                      {study && study.id ? (
                        <span className="btn btn-sm btn-transparent bg-info-10 text-info me-2 pe-none w-100">
                          <FontAwesomeIcon icon={faLock} fixedWidth className="me-1" />
                          {useCase?.name}
                        </span>
                      ) : (
                        <Dropdown className="mt-3">
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            className="w-100 d-flex align-items-center justify-content-between fs-sm"
                            style={{ textAlign: "left", padding: "0.5rem", backgroundColor: "white" }}
                            variant="outline-secondary"
                          >
                            <input className="w-100 border-0 form-range" type="text" onChange={e=>{onSearch(e)}} placeholder="Select Use Case" value={tag} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="w-100 usecase-list">
                            {isLoading && <Dropdown.Item>Loading...</Dropdown.Item>}
                            {isError && <Dropdown.Item>Unable to retrieve use cases</Dropdown.Item>}
                            {isSuccess && useCases && useCases.length === 0 && <Dropdown.Item>No use cases found</Dropdown.Item>}
                            {useCases &&
                              useCases.length > 0 &&
                              useCases.map((tag) => (
                                <Fragment key={tag.id}>
                                {tag.isCategory ? <Dropdown.Header
                                  as="div"
                                  key={tag.id}
                                  className="py-1 fw-600 text-primary"
                                >
                                  {tag.category.toUpperCase()}
                                </Dropdown.Header> :
                                <Dropdown.Item
                                  as="div"
                                  key={tag.id}
                                  onClick={() => {
                                    setContextUseCase(tag);
                                    setTag(tag.name);
                                  }}
                                  className="py-2"
                                >
                                  {tag.name}
                                </Dropdown.Item>}
                                </Fragment>
                              ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                  </section>
                  <section className="border-bottom py-3">
                    <header className="position-relative ms-3 ps-5">
                      <span
                        className="d-inline-block position-absolute start-0 top-0 bg-primary-10 rounded-circle text-primary text-center"
                        style={{ width: "2.4em", height: "2.4em", paddingTop: "8px" }}
                      >
                        <FontAwesomeIcon icon={faImage} fixedWidth size="lg" />
                      </span>
                      <h2 className="fs-6 mb-1">2. Upload image</h2>
                      <p className="text-muted fs-xs">The scene where your content appears.</p>
                    </header>
                    <div className="px-3">
                      {images.length ? (
                        <div className="media-item position-relative d-inline-block">
                          <span
                            onClick={(e) => {
                              deleteContextShape();
                              setImages([]);
                            }}
                            className="position-absolute top-0 start-100 btn btn-light bg-white rounded-circle text-center p-0"
                            style={{
                              width: "1.6em",
                              height: "1.6em",
                              zIndex: "2",
                              marginLeft: "-1rem",
                              marginTop: "-0.5rem",
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} fixedWidth size="sm" className="mt-1 d-inline-block" />
                          </span>
                          <div className="card shadow-sm overflow-hidden border-0" style={{ width: "207px" }}>
                            <div
                              className="d-flex align-items-center justify-content-center bg-secondary"
                              style={{ height: "100px" }}
                            >
                              <img src={images[0].data_url} alt="..." className="mw-100 mh-100" />
                            </div>
                            <div className="card-body p-2">
                              <h5 className="card-title h6 fw-700 mb-1">
                                <a
                                  href="experiment-image-aoi.html"
                                  className="d-block text-decoration-none text-dark text-nowrap text-truncate fs-xs"
                                >
                                  {images[0].file.name}
                                </a>
                              </h5>
                              <p className="card-text fs-xs text-muted d-flex mb-0">{`${images[0].file.path
                                .split(".")
                                .pop()
                                .toUpperCase()} | ${(images[0].file.size / 1000000).toFixed(2)}MB`}</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <form>
                          <input
                            className="fs-sm w-100"
                            type="file"
                            onClick={(e) => {
                              e.preventDefault();
                              open();
                            }}
                            id="formFile"
                          />
                        </form>
                      )}
                    </div>
                  </section>
                  <section className={`border-bottom py-3 ${images.length ? "" : "o-40"}`}>
                    <header className="position-relative ms-3 ps-5">
                      <span
                        className="d-inline-block position-absolute start-0 top-0 bg-primary-10 rounded-circle text-primary text-center"
                        style={{ width: "2.4em", height: "2.4em", paddingTop: "8px" }}
                      >
                        <FontAwesomeIcon icon={faDrawSquare} fixedWidth size="lg" />
                      </span>
                      <h2 className="fs-6 mb-1">3. Set a dropzone</h2>
                      <p className="text-muted fs-xs">The area where content should be placed.</p>
                    </header>
                    <div className="px-3">
                      <form>
                        <div className="container px-2">
                          <div className="row">
                            <label className="d-block mb-1 px-1 fs-xxs fw-600">POSITION</label>
                            <div className="col px-1">
                              <div className="input-group input-group-sm mb-3">
                                <span className="input-group-text bg-white fw-600" id="basic-addon1">
                                  <span className="fs-xs">X</span>
                                </span>
                                <input
                                  type="number"
                                  min={0}
                                  max={
                                    contextShapeToDraw.length
                                      ? Math.round(images[0].scaledWidth - contextShapeToDraw[0].width)
                                      : 1
                                  }
                                  onChange={(e) => {
                                    const value = Math.max(
                                      e.target.min,
                                      Math.min(e.target.max, Number(e.target.value))
                                    );
                                    changeShape({ x: value });
                                  }}
                                  value={
                                    contextShapeToDraw && contextShapeToDraw.length
                                      ? Math.round(contextShapeToDraw[0].x)
                                      : 0
                                  }
                                  className="form-control"
                                  aria-label="width"
                                  aria-describedby="basic-addon1"
                                  disabled={!images.length}
                                />
                              </div>
                            </div>
                            <div className="col px-1">
                              <div className="input-group input-group-sm mb-3">
                                <span className="input-group-text bg-white fw-600" id="basic-addon1">
                                  <span className="fs-xs">Y</span>
                                </span>
                                <input
                                  type="number"
                                  min={0}
                                  max={
                                    contextShapeToDraw.length
                                      ? Math.round(images[0].scaledHeight - contextShapeToDraw[0].height)
                                      : 1
                                  }
                                  onChange={(e) => {
                                    const value = Math.max(
                                      e.target.min,
                                      Math.min(e.target.max, Number(e.target.value))
                                    );
                                    changeShape({ y: value });
                                  }}
                                  value={
                                    contextShapeToDraw && contextShapeToDraw.length
                                      ? Math.round(contextShapeToDraw[0].y)
                                      : 0
                                  }
                                  className="form-control"
                                  aria-label="height"
                                  aria-describedby="basic-addon1"
                                  disabled={!images.length}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <label className="d-block mb-1 px-1 fs-xxs fw-600">DIMENSIONS</label>
                            <div className="col px-1">
                              <div className="input-group input-group-sm mb-3">
                                <span className="input-group-text bg-white fw-600" id="basic-addon1">
                                  <span className="fs-xs">W</span>
                                </span>
                                <input
                                  type="number"
                                  min={0}
                                  max={
                                    contextShapeToDraw.length
                                      ? Math.round(images[0].scaledWidth - contextShapeToDraw[0].x)
                                      : 1
                                  }
                                  onChange={(e) => {
                                    const value = Math.max(
                                      e.target.min,
                                      Math.min(e.target.max, Number(e.target.value))
                                    );
                                    changeShape({ width: value });
                                  }}
                                  value={
                                    contextShapeToDraw && contextShapeToDraw.length
                                      ? Math.round(contextShapeToDraw[0].width)
                                      : 0
                                  }
                                  className="form-control"
                                  aria-label="width"
                                  aria-describedby="basic-addon1"
                                  disabled={!images.length}
                                />
                              </div>
                            </div>
                            <div className="col px-1">
                              <div className="input-group input-group-sm mb-3">
                                <span className="input-group-text bg-white fw-600" id="basic-addon1">
                                  <span className="fs-xs">H</span>
                                </span>
                                <input
                                  type="number"
                                  min={0}
                                  max={
                                    contextShapeToDraw.length
                                      ? Math.round(images[0].scaledHeight - contextShapeToDraw[0].y)
                                      : 1
                                  }
                                  onChange={(e) => {
                                    const value = Math.max(
                                      e.target.min,
                                      Math.min(e.target.max, Number(e.target.value))
                                    );
                                    changeShape({ height: value });
                                  }}
                                  value={
                                    contextShapeToDraw && contextShapeToDraw.length
                                      ? Math.round(contextShapeToDraw[0].height)
                                      : 0
                                  }
                                  className="form-control"
                                  aria-label="height"
                                  aria-describedby="basic-addon1"
                                  disabled={!images.length}
                                />
                              </div>
                            </div>
                          </div>
                          {/*<div className="row">*/}
                          {/*<div className="col px-1">*/}
                          {/*<div className="form-check form-switch mb-3">*/}
                          {/*<input*/}
                          {/*className="form-check-input"*/}
                          {/*type="checkbox"*/}
                          {/*id="flexSwitchCheckDefault"*/}
                          {/*onChange={(e) => {*/}
                          {/*setSquareShape(e.target.checked);*/}
                          {/*}}*/}
                          {/*checked={squareShape}*/}
                          {/*disabled={!images.length}*/}
                          {/*/>*/}
                          {/*<label className="form-check-label fs-sm" htmlFor="flexSwitchCheckDefault">*/}
                          {/*Constrain proportions*/}
                          {/*</label>*/}
                          {/*</div>*/}
                          {/*</div>*/}
                          {/*</div>*/}
                          <div className="row">
                            <div className="col-12 px-1">
                              <div className="form-check form-switch mb-3">
                              <input
                                role="switch"
                                className="form-check-input"
                                name="background"
                                id="backgroundcheckboxes"
                                type="checkbox"
                                disabled={!images.length}
                                checked={bgColor}
                                onChange={(e) => {
                                  onChangeBackground(e.target.checked);
                                }}
                              />
                              <label className="d-block mb-1 p-1 fs-xxs fw-600 custom-control-label" htmlFor="userCasecheckboxes">SET BACKGROUND (OPTIONAL)</label>
                            </div>
                            </div>
                            { bgColor &&
                            <div className="col px-1">
                              <input
                                type="color"
                                value={dropzoneBgColor}
                                className="form-control form-control-color"
                                id="exampleColorInput"
                                title="Choose your color"
                                onChange={(e) => {
                                  setDropzoneBgColor(e.target.value);
                                  setDropzoneBgOpacity("ff");
                                }}
                                disabled={!images.length}
                              />
                            </div>
                            }
                          </div>
                        </div>
                      </form>
                    </div>
                  </section>
                </div>
              )}
              <div className="h-100 flex-grow-1 p-3" onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
                <div
                  className="upload__image-wrapper"
                  onClick={() => {}}
                  {...getRootProps({ className: "dropzone h-100 w-100 position-relative full-width" })}
                >
                  <div
                    className={
                      isDragActive
                        ? "dropzone-area dropzone-overlay position-absolute top-0 start-0 w-100 h-100 p-3 ui-droppable-active ui-droppable-hover"
                        : images.length
                        ? ""
                        : "dropzone-area dropzone-overlay position-absolute top-0 start-0 w-100 h-100 p-3 ui-droppable-active"
                    }
                  >
                    <input {...getInputProps()} onClick={(e) => e.stopPropagation()} />
                    <div className="dropzone-inner d-flex align-items-center justify-content-center w-100 h-100 rounded border-2">
                      <div className="dropzone-content text-center dpzone-content">
                        <div
                          className={`zindex1 ${images.length ? "h-100 w-100" : ""} ${
                            images.length && isDragActive ? "dp-inner-content" : ""
                          }`}
                        >
                          {images.length > 0 && !uploadInProcess && (
                            <>
                              <div className="tab-pane h-100 w-100 fade show active">
                                <div className="h-100 w-100 text-center overflow-auto">
                                  <div
                                    className="mx-auto text-start mt-4"
                                    style={{ maxWidth: 1024, height: "calc(100vh - 100px)" }}
                                  >
                                    <div className="shadow-sm overflow-hidden bg-white">
                                      <div className="alignment-content-center" id="drawing-zone">
                                        {images.length > 0 && canvasDimensions.width > 0 && (
                                          <Stage
                                            width={canvasDimensions.width}
                                            height={canvasDimensions.height}
                                            onMouseDown={handleMouseDown}
                                            onMouseUp={handleMouseUp}
                                            onMouseMove={handleMouseMove}
                                            /* Center's the canvas using the top level element Konva creates on load */
                                            ref={(stage) => (stage ? (stage.content.className += " m-auto") : null)}
                                          >
                                            <Layer>
                                              <CreateImage
                                                url={images[0].data_url}
                                                forwardRef={imageRef}
                                                imageWidth={images[0].scaledWidth}
                                                imageHeight={images[0].scaledHeight}
                                              />
                                            </Layer>
                                            <Layer>
                                              {contextShapeToDraw.map((rect, i) => {
                                                return (
                                                  <TransformerRectangle
                                                    key={i}
                                                    canvasWidth={canvasDimensions.width}
                                                    canvasHeight={canvasDimensions.height}
                                                    shapeProps={rect}
                                                    fill={
                                                      dropzoneBgOpacity !== "00"
                                                        ? dropzoneBgColor + dropzoneBgOpacity
                                                        : null
                                                    }
                                                    isSelected={rect.id === selectedId}
                                                    setAspectRatio={setAspectRatio}
                                                    aspectRatio={aspectRatio}
                                                    onSelect={() => {
                                                      selectShape(rect.id);
                                                    }}
                                                    onChange={(newAttrs) => {
                                                      const rects = contextShapeToDraw.slice();
                                                      rects[i] = newAttrs;
                                                      setContextShapeToDraw(rects);
                                                      const ratio = roundAspectRatio(
                                                        Math.round(newAttrs.width),
                                                        Math.round(newAttrs.height)
                                                      );
                                                      setAspectRatio(ratio);
                                                    }}
                                                  />
                                                );
                                              })}
                                            </Layer>
                                          </Stage>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {images.length === 0 && (
                            <div className="dropzone-content text-center dpzone-content">
                              <div className="zindex1">
                                <p>
                                  <FontAwesomeIcon icon={falArrowToBotton} size="5x" />
                                </p>
                                <p className="fw-500 mb-2">
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      open();
                                    }}
                                  >
                                    Choose file
                                  </a>{" "}
                                  or drag them here
                                </p>
                                <p className="text-black-50 small">
                                  Max file size 10MB. Supported file types JPG, JPEG and PNG. <br />
                                  High resolution uploads are scaled to 1024px (w/h) for performance.
                                </p>
                                <p className="text-black-50 small">
                                  You can upload one piece of context imagery at this stage but add other <br />
                                  contexts into the editor should you wish to build up your analysis.
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {uploadInProcess && (
                <div className="h-100 w-100 modal-body bg-light progress-body">
                  <form className="form needs-validation mx-auto progress-width" noValidate>
                    {uploadProgress >= 0 && uploadProgress < images.length && (
                      <div className="text-center">
                        <div className="py-4">
                          <span className="d-inline-block m-auto mb-3 border rounded-circle text-center d-flex align-items-center justify-content-center text-dark border-dark border-2 progress-circle">
                            <FontAwesomeIcon icon={falSpinner} spin size="2x" />
                          </span>
                          <p className="lead fw-400">
                            {uploadProgress}/{images.length} uploaded
                          </p>

                          <p className="text-muted fs-sm">Please do not close the window.</p>
                          <div className="progress mb-5 progress-height">
                            <div
                              className="progress-bar progress-bar-striped progress-bar-animated"
                              role="progressbar"
                              style={{ width: `${(uploadProgress * 100) / images.length}%` }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddContextMediaModal;
