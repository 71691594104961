import { Field } from "formik";
import { FloatingLabel } from "react-bootstrap";

export const OtherTag = ({ label, placeholder = label, height = "100px", name = "otherTag", supportingMessage }) => {
  return (
    <FloatingLabel label={label} className="mt-2 textbox-transform-fix">
      <Field
        type="text"
        name={name}
        as="textarea"
        className="form-control"
        placeholder={placeholder}
        style={{ height: height }}
      ></Field>
      <p className="fw-light fs-sm mt-1">{supportingMessage}</p>
    </FloatingLabel>
  );
};
