import { useEffect, useState, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import Pagination from "@/components/Pagination";
import { GetAllStudies, GetProject, StudyFiltersSuccess, StudyFiltersRequested, GetAllStudiesRequested } from "../../../redux/actions/project-action-types";
import Loader from "@/components/Loader";
import { segmentAnalytics } from "@/utilities/genericFunctions"
import { Navbar } from "./Navbar";
import { NotFound } from "./NotFound";
import { Card } from "./Card";
import { useGetAllStudiesQuery } from "../api/studySlice";
import { Toaster } from "@/components/Toast";
import { useGetProjectQuery } from "@/features/projects/api/projectsSlice";

export const Studies = () => {
  const { project_id } = useParams();

  const dispatch = useDispatch();
  const [ isProjectArchived, setIsProjectArchived ] = useState(null);
  const [ isStudyArchived, setIsStudyArchived ] = useState(null);
  const [ fetchStudies, setFetchStudies ] = useState(false);
  const session = useSelector((state) => state.session);
  const StudyFilters = useSelector((state) => state.project.studyFilters);

  /*Get the project details and setting the page analytics*/
  useLayoutEffect(() => {

    const callback = (err, result) => {
      if (!err) {

        if (result.payload.project) {
          segmentAnalytics({ page: true, session, name: "Project", segmentData:{
            title: `${result.payload.project.name} | Dragonfly AI Studio` } });
        }
      }
    };

    dispatch(
      GetProject({ projectId: project_id, callback })
    );
    /*clear the study filters from reducer when component unmounted*/
    return () => {
      dispatch(GetAllStudiesRequested());
      dispatch(StudyFiltersRequested());
    };
  }, [ dispatch, project_id ]);

  /** Use effect called everytime to check if new build is uploaded
 * then auto refresh so that new changes are visible
 */

  /*Get the updated details of project after archived or un-archived the project */
  useEffect(() => {
    dispatch(
      GetProject({ projectId: project_id })
    );
    setIsProjectArchived(null);
  }, [ isProjectArchived, dispatch, project_id ]);


  /*Get the list of studies in a project after any changes in filters and search*/
  useEffect(() => {
    if (StudyFilters.query && StudyFilters.query.length === 0) {
      dispatch(
        GetAllStudies({
          view: StudyFilters.view,
          pageIndex: StudyFilters.pageIndex,
          itemsPerPage: StudyFilters.itemsPerPage,
          orderby: StudyFilters.orderby,
          sortby: StudyFilters.sortby,
          projectId: project_id,
        })
      );
    } else {
      dispatch(
        GetAllStudies({
          query: StudyFilters.query,
          view: StudyFilters.view,
          pageIndex: StudyFilters.pageIndex,
          itemsPerPage: StudyFilters.itemsPerPage,
          orderby: StudyFilters.orderby,
          sortby: StudyFilters.sortby,
          projectId: project_id,
        })
      );
    }
    setIsStudyArchived(null);
    setFetchStudies(false);
  }, [ StudyFilters, dispatch, isStudyArchived, project_id, fetchStudies ]);


  const {
    data: { project } = {},
  } = useGetProjectQuery({ projectId: project_id });

  const {
    data: { studies, maxPageIndex, maxRecordCount, pageIndex } = {},
    isSuccess,
    isLoading,
    isError,
  } = useGetAllStudiesQuery({
    view: StudyFilters.view,
    pageIndex: StudyFilters.pageIndex,
    itemsPerPage: StudyFilters.itemsPerPage,
    orderby: StudyFilters.orderby,
    sortby: StudyFilters.sortby,
    projectId: project_id,
  });

  if(isError) Toaster({ message: "Failed to fetch all Studies", type: "error" });

  return (
    <main className="h-100 pt-58">
      <Helmet><title> {`${project?.name} | Dragonfly AI Studio`}</title></Helmet>
      <div className="h-100 p-4 overflow-auto">
        <Navbar setIsProjectArchived={setIsProjectArchived} />

        {isLoading && 
          <Loader show={true} />
        }
        {isSuccess && studies.length <= 0 &&
          <NotFound />
        }

        {isSuccess &&
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 g-4">
            {studies.map((study) => (
              <Card key={study.id} study={study} projectId={project_id} />
            ))}
          </div>
        }

        <nav className="navbar navbar-expand-lg navbar-light mt-5 mb-0">
          {isSuccess && studies.length > 0 && <Pagination
            key={`${StudyFilters.itemsPerPage}`}
            maxPageIndex={maxPageIndex}
            pageIndex={pageIndex}
            totalRecords={maxRecordCount}
            pageLimit={StudyFilters.itemsPerPage}
            pageNeighbours={2}
            onPageChanged={({ currentPage }) => dispatch(StudyFiltersSuccess({ pageIndex: currentPage }))}
            onLimitChange={(itemsPerPage) => {
              dispatch(StudyFiltersSuccess({ itemsPerPage: itemsPerPage, pageIndex: 1 }));
            }}
          />}
        </nav>
      </div>
    </main>
  );
};
