import { RenameModal, ToggleModal } from "@/components/Modals";
import Method from "@/utilities/methods";
import { Button } from "react-bootstrap";
import { ImagePreviewModal } from "./ImagePreviewModal";
import { segmentAnalytics } from "@/utilities/genericFunctions";
import { useSelector } from "react-redux";

export const AssetPanel = ({
  showAssetPanel,
  contextSelection,
  updateFunction,
  mutationStatus,
  setContextSelection,
  setShowAssetPanel,
}) => {

  const session = useSelector((state) => state.session);
  const contextPreview = (name)=>{
    /** Segment track event for library context preview */
    segmentAnalytics({ page: false, session, name: "Library Context Preview", segmentData:{
      contextName: name, } });
  }

  return (
    <div
      className={`h-100 flex-shrink-0 border-start overflow-auto p-4 ${showAssetPanel ? "d-block" : "d-none"}`}
      style={{
        width: "16vw",
        maxWidth: "400px",
      }}
    >
      <h5 className="fw-700 mb-3 text-truncate">{contextSelection.filename}</h5>
      <dl>
        <dt className="fs-xxs text-uppercase mb-1">Use case</dt>
        <dd className="fs-xs mb-3">
          <span
            className="badge bg-info-10 text-info fw-500 text-uppercase fs-xxs p-1"
            style={{ backgroundColor: "rgba(6,78,142,.1)" }}
          >
            {contextSelection?.useCase?.name}
          </span>
        </dd>
        <dt className="fs-xxs text-uppercase mb-1">File</dt>
        <dd className="fs-xs mb-3">
          {contextSelection.filename} ({contextSelection.sizeInBytes})
        </dd>
        <dt className="fs-xxs text-uppercase mb-1">Dimensions</dt>
        <dd className="fs-xs mb-3">
          <span className="d-inline-block fw-700 fs-xxs" style={{ width: "0.875rem" }}>
            W
          </span>{" "}
          <span className="d-inline-block me-3">{contextSelection.width}</span>
          <span className="d-inline-block fw-700 fs-xxs" style={{ width: "0.875rem" }}>
            H
          </span>{" "}
          <span className="d-inline-block me-3">{contextSelection.height}</span>
        </dd>
        <dt className="fs-xxs text-uppercase mb-1">Dropzone</dt>
        <dd className="fs-xs mb-3">
          <span className="d-inline-block fw-700 fs-xxs" style={{ width: "0.875rem" }}>
            X
          </span>{" "}
          <span className="d-inline-block me-3">{contextSelection.dropzone[0].x}</span>
          <span className="d-inline-block fw-700 fs-xxs" style={{ width: "0.875rem" }}>
            Y
          </span>{" "}
          <span className="d-inline-block me-3">{contextSelection.dropzone[0].y}</span>
          <br />
          <span className="d-inline-block fw-700 fs-xxs" style={{ width: "0.875rem" }}>
            W
          </span>{" "}
          <span className="d-inline-block me-3">{contextSelection.dropzone[1].x - contextSelection.dropzone[0].x}</span>
          <span className="d-inline-block fw-700 fs-xxs" style={{ width: "0.875rem" }}>
            H
          </span>{" "}
          <span className="d-inline-block me-3">{contextSelection.dropzone[3].y - contextSelection.dropzone[0].y}</span>
        </dd>
        <dt className="fs-xxs text-uppercase mb-1">Added by</dt>
        <dd className="fs-xs mb-3">
          {contextSelection.user.firstName} {contextSelection.user.lastName}
        </dd>
        <dt className="fs-xxs text-uppercase mb-1">Uploaded</dt>
        <dd className="fs-xs mb-3">{`${Method.formatTime(contextSelection.createdAtTimeStamp)} ago`}</dd>
        <dt className="fs-xxs text-uppercase mb-1">Status</dt>
        <dd className="fs-xs mb-3">{contextSelection.isArchived ? "Archived" : "Active"}</dd>
      </dl>
      <ToggleModal
        dialogClassName="modal-fullscreen"
        toggle={(show) => (
          <Button variant="outline-secondary" size="sm" className="w-100 mb-2" onClick={e=>{contextPreview(contextSelection.filename); show()}}>
            Preview
          </Button>
        )}
        content={(hide) => (
          <ImagePreviewModal
            title={contextSelection.filename}
            imageUrl={contextSelection.scaledUrl}
            modalMeta={contextSelection}
            hide={hide}
          />
        )}
      />
      <ToggleModal
        toggle={(show) => (
          <Button variant="outline-secondary" size="sm" className="w-100 mb-2" onClick={show}>
            Rename
          </Button>
        )}
        content={(hide) => (
          <RenameModal
            updateFunction={updateFunction}
            mutationStatus={mutationStatus}
            cardMeta={contextSelection}
            title="Context"
            hide={hide}
          />
        )}
      />
      <Button
        variant="outline-secondary"
        size="sm"
        className="w-100 mb-2"
        onClick={() => {
          updateFunction({ isArchived: !contextSelection.isArchived }, contextSelection.id);
          setShowAssetPanel(false);
          setContextSelection(null);
        }}
      >
        Archive
      </Button>
    </div>
  );
};
