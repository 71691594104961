import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck as falCheck, faTable as falTable, faSpinner as falSpinner, faCircleCheck } from "@fortawesome/pro-light-svg-icons";
import { iconList } from "../../constants/reportSettings";
import { useProductAccess } from "../../hooks/useProductAccess";

function ExcelExportModal({ hide, handlechange, selectedReport, reports, exportProgress, exportInProcess, stopExport, exportExcel, defaultReport }) {
  const handleCloseClick = () => {
    if (exportInProcess) {
      stopExport()
    } else {
      hide()
    }
  }

  const hasContextAccess = useProductAccess("contexts");

  let excel_reports = [];
  if (reports && reports.length) {
    excel_reports = reports.filter(report => report.type == "aoi" || report.type == "gaze_path" || (report.type == "context" && hasContextAccess))
  }

  return (
    <>
      {/*----------------------------Export modal start------------------*/}

      <div className="modal-header border-darken-15 bg-white">
        <h5 className="modal-title fw-light" id="exampleModalLabel">Export</h5>
        <button type="button" className="btn btn-link text-muted ms-auto" data-bs-dismiss="modal" onClick={() => handleCloseClick()}>Cancel</button>
        <button type="button" className="btn btn-primary" disabled={exportInProcess || !Object.values(selectedReport).includes(true)} onClick={() => {
          exportExcel()
        }}>Export</button>
      </div>
      {!exportInProcess &&
        <div className="excel-export modal-body bg-light emd-body">
          <form className="form needs-validation mx-auto progress-width">
            <div className="text-center">
              <div className="py-4">
                <span className="d-inline-block m-auto mb-3 border rounded-circle text-center d-flex align-items-center justify-content-center text-dark border-dark border-2 progress-circle">
                  <FontAwesomeIcon icon={falTable} size="2x" />
                </span>
                <p className="lead fw-400">Export data</p>
                <p className="fs-sm text-muted">Select your export settings below.</p>
              </div>
            </div>
            <div className="p-5">
              {excel_reports && excel_reports.length > 0 && <div className="card border-info text-info mb-3 cardbg">
                <div className="card-body">
                  <p className="card-text"><small><strong>Tip:</strong> Only Contexts, Hotspots and Areas of Interest (AOI) reports currently support data exports. <a href="https://academy.dragonflyai.co/en/articles/5802594" target="_blank" rel="noreferrer" className="text-info">Learn more</a></small></p>
                </div>
              </div>}
              {excel_reports && excel_reports.length == 0 && <div className="card border-warning text-warning mb-3 warningbg">
                <div className="card-body">
                  <p className="card-text"><small><strong>Warning:</strong> Please create at least one Hotspots report or Areas of Interest report to enable data exports. <a href="https://academy.dragonflyai.co/en/articles/5802594" target="_blank" rel="noreferrer" className="text-warning">Learn more</a></small></p>
                </div>
              </div>}
              {excel_reports && excel_reports.length > 0 && <div className="form-group mb-5">
                <p className="fw-500">Select reports</p>
                {excel_reports && excel_reports.length > 0 && excel_reports.map((obj, index) => {
                  return (<div className="row mb-3" key={index}>

                    <div className="col-6">
                      <div className={`d-flex py-1 px-3 rounded-1 align-items-baseline flex-grow-1 border ${ defaultReport[`${obj.name}`] == false ? "disabled" :  "cursor-pointer"} ${selectedReport[`${obj.name}`] == true ? "border-primary text-primary" : "border-secondary text-secondary" }`} onClick={() => defaultReport[`${obj.name}`] == false ? null :  handlechange({ target:{ name: obj.name, type: "checkbox", checked: !selectedReport[`${obj.name}`] } })}>
                        <span className="flex-shrink-0 rounded text-center lh-sm me-2">
                          <FontAwesomeIcon icon={iconList[obj.type]}/>
                        </span>
                        <span className="flex-grow-1 text-nowrap text-truncate d-flex">
                          <span className="text-truncate cursor-pointer" data-bs-toggle="tooltip" data-bs-placement="top" title={obj.name}>{obj.name}</span>
                        </span>
                        <span className="flex-shrink-0 text-end"><FontAwesomeIcon icon={faCircleCheck} className={`align-items-end ${selectedReport[`${obj.name}`] == true ? "" : "text-gray" }`}/></span>
                      </div>
                      {defaultReport[`${obj.name}`] == false && obj.type == "aoi" && <p className="fs-xs text-danger mt-2 mb-0">There are no labelled Areas of Interest within this report.</p>}
                      {defaultReport[`${obj.name}`] == false && obj.type == "context" && <p className="fs-xs text-danger mt-2 mb-0">There are no context within this report.</p>}
                    </div>
                  </div>)
                }
                )}
              </div>}
            </div>
          </form>
        </div>

      }
      {/*----------------------------Export progess download modal start------------------*/}
      {exportInProcess && <div className="excel-export modal-body bg-light progress-body">
        <form className="form needs-validation mx-auto progress-width" noValidate >
          {exportProgress > 0 && exportProgress < 100 && <div className="text-center">
            <div className="py-4">
              <span className="d-inline-block m-auto mb-3 border rounded-circle text-center d-flex align-items-center justify-content-center text-dark border-dark border-2 progress-circle">
                <FontAwesomeIcon icon={falSpinner} spin size="2x" />
              </span>
              <p className="lead fw-400">{Math.round(exportProgress)}% complete</p>

              <p className="text-muted fs-sm">Please do not close the window.</p>
              <div className="progress mb-5 progress-height">
                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${Math.round(exportProgress)}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" />
              </div>
              <button type="button" className="btn btn-link text-muted mx-auto" data-bs-dismiss="modal" onClick={() => handleCloseClick()}>Cancel</button>
            </div>
          </div>}
          {exportProgress == 100 && <div className="text-center">
            <div className="py-4">
              <span className="d-inline-block m-auto mb-3 border rounded-circle text-center d-flex align-items-center justify-content-center text-dark border-dark border-2 progress-circle">
                <FontAwesomeIcon icon={falCheck} size="2x" />
              </span>
              <p className="lead fw-400">{exportProgress}% complete</p>
              <p className="text-muted fs-sm">Your download is ready.</p>
              <div className="progress mb-5 progress-height">
                <div className="progress-bar" role="progressbar" style={{ width: `${exportProgress}%` }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" />
              </div>
              <button type="button" className="btn btn-link text-muted mx-auto" data-bs-dismiss="modal" onClick={() => { stopExport(); hide(); }}>Close</button>
            </div>
          </div>}
        </form>
      </div>}
      {/*----------------------------Export progess download modal end------------------*/}

    </>
  );
}

export default ExcelExportModal;
