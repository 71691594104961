import * as Yup from "yup";
import { Form, Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes as falTimes } from "@fortawesome/pro-light-svg-icons";
import { Button, TextInput } from "../FormFields";

export const RenameModal = ({ hide, updateFunction, mutationStatus, cardMeta, title = "Rename Modal" }) => {
  return (
    <Formik
      initialValues={{ name: cardMeta?.name }}
      validationSchema={Yup.object({
        name: Yup.string()
          .required("Study name is required.")
          .matches(/^[A-Za-z 0-9]+$/, "Study name can contain letters, numbers and spaces"),
      })}
      onSubmit={(values) => {
        updateFunction(values, cardMeta.id);
        hide();
      }}
    >
      {({ dirty, errors, isValidating, handleSubmit }) => (
        <>
          <Form
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit();
              }
            }}
          >
            <div className="modal-header border-darken-15 bg-white">
              <h5 className="modal-title fw-light" id="exampleModalLabel">
                Rename {title}
              </h5>
              <button type="button" className="btn btn-link text-black-50 close" onClick={() => hide()}>
                <FontAwesomeIcon icon={falTimes} size="lg" className="mt-1" />
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group mb-3">
                <div className="form-label-group">
                  <TextInput
                    type="text"
                    name="name"
                    id="studyName"
                    className="form-control"
                    placeholder="Name"
                    required
                    autoFocus
                  />
                  <label htmlFor="inputEmail">Name</label>
                </div>
              </div>
            </div>
            <div className="modal-footer border-darken-15 bg-white">
              <button type="button" className="btn btn-link text-muted" onClick={() => hide()}>
                Cancel
              </button>
              <Button
                label={"Save changes"}
                className="btn btn-primary"
                dirty={dirty}
                errors={errors}
                isValidating={isValidating}
                isSubmitting={mutationStatus?.isLoading}
                onClick={handleSubmit}
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};
