import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicroscope } from "@fortawesome/pro-light-svg-icons";

import { ToggleModal, NewStudyModal } from "../../../components/Modals";

export const NotFound = () => {
  let navigate = useNavigate();
  const Project = useSelector((state) => state.project.getProject?.data);

  return (
    <div className="p-5 text-center mb-3">
      <div className="py-5">
        <span className="d-inline-block m-auto mb-3 op-20 border rounded-circle text-center d-flex align-items-center justify-content-center text-dark border-dark border-3 icon-size5">
          <FontAwesomeIcon icon={faMicroscope} size="3x" />
        </span>
        <p className="text-dark fs-sm fw-500 lh-base mx-auto fst-italic text-muted mw-36">Studies allow you to analyse up to 26 media assets side by side (e.g. multiple versions of a new design you're working on).</p>
        <ToggleModal
          toggle={(show) => (
            <button className="btn btn-primary" onClick={show}>
              New study
            </button>
          )}
          content={(hide) => <NewStudyModal hide={(createSuccess = false) => {
            hide();
            if (createSuccess) {
              navigate(`${createSuccess.payload.study.id}`);
            }
          }} project={Project} />}
        />
      </div>
    </div>
  );
};
