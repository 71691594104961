import { Form, Formik, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes as falTimes } from "@fortawesome/pro-light-svg-icons";
import { faShare as fasShare, faLock as fasLock } from "@fortawesome/pro-solid-svg-icons";
import { Button } from "../FormFields";
import { useUpdateProjectMutation } from "../../features/projects/api/projectsSlice";
import { Toaster } from "../Toast";

function ManageAccessProjectModal({ hide, project }) {
  const [ updateProjectAccess, { isLoading, isError }] = useUpdateProjectMutation();

  const onSubmit = async (values) => {
    try {
      await updateProjectAccess({ data: { ...values, isArchived: project.isArchived }, projectId: project.id }).unwrap();
      if (!isError) hide(true);
      Toaster({ message: "Project access updated." });
    } catch (error) {
      Toaster({ message: "Project access update failed.", type: "error" });
    }
  };

  return (
    <Formik
      initialValues={{ name: project.name, visibility: project.visibility }}
      onSubmit={onSubmit}
    >
      {({ dirty, errors, handleSubmit }) => (
        <>
          <Form onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleSubmit();
            }
          }}>
            <div className="modal-header border-darken-15 bg-white">
              <h5 className="modal-title fw-light" id="exampleModalLabel">
                Manage access
            </h5>
              <button type="button" className="btn btn-link text-black-50 close" onClick={() => hide()}>
                <FontAwesomeIcon icon={falTimes} size="lg" className="mt-1" />
              </button>
            </div>
            <div className="modal-body manage-access-project-modal">
              <div className="form needs-validation">
                <div className="form-group mb-3">
                  <p className="fw-500">Who has access?</p>
                  <div className="form-check d-flex align-items-center mb-3">
                    <Field className="form-check-input" type="radio" name="visibility" value="private" id="flexRadioDefault2" />
                    <label className="form-check-label position-relative lpl" htmlFor="flexRadioDefault2">
                      <span
                        className="position-absolute top-0 start-0 bg-primary text-white shadow-sm d-flex align-items-center justify-content-center text-center rounded label-icon"
                      >
                        <FontAwesomeIcon icon={fasLock} size="lg" />
                      </span>
                      <span className="fw-500">Private</span>
                      <p className="fs-xs text-muted mb-0">Visible only to you and account admins.</p>
                    </label>
                  </div>
                  <div className="form-check d-flex align-items-center mb-3">
                    <Field className="form-check-input" type="radio" name="visibility" value="public" id="flexRadioDefault1" />
                    <label className="form-check-label position-relative lpl" htmlFor="flexRadioDefault1">
                      <span
                        className="position-absolute top-0 start-0 bg-primary text-white shadow-sm d-flex align-items-center justify-content-center text-center rounded label-icon"
                      >
                        <FontAwesomeIcon icon={fasShare} size="lg" />
                      </span>
                      <span className="fw-500">Shared</span>
                      <p className="fs-xs text-muted mb-0">Visible to everyone in your account.</p>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-darken-15 bg-white">
              <button type="button" className="btn btn-link text-muted" onClick={() => hide()}>
                Cancel
            </button>
              <Button label={"Save changes"}
                className="btn btn-primary"
                dirty={dirty}
                errors={errors}
                isSubmitting={isLoading}
                onClick={handleSubmit} />
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}

export default ManageAccessProjectModal;
