import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes as falTimes } from "@fortawesome/pro-light-svg-icons";
import { Form, Formik } from "formik";
import { Button, TextInput } from "../FormFields";
import { Toaster } from "../Toast";
import { useDeleteStudyMutation } from "../../features/studies/api/studySlice";


function DeleteStudyModal({ hide, study, projectId }) {
  const [deleteStudy, { isLoading, isError }] = useDeleteStudyMutation();

  const onSubmit = async () => {
    try {
      await deleteStudy({ studyId: study.id, projectId: projectId }).unwrap();
      if (!isError) hide(true);
      Toaster({ message: "Study removed successfully." });
    } catch (error) {
      Toaster({ message: "Removing study failed.", type: "error" });
    }
  };

  return (
    <Formik
      initialValues={{ name: "" }}
      validationSchema={Yup.object({
        name: Yup.string()
          .required("Confirmation is required.")
          .test("confirm-name", "Please type DELETE correctly.", (value) => "DELETE" === value),
      })}
      onSubmit={onSubmit}
    >
      {({ dirty, errors, isValidating, handleSubmit }) => (
        <>
          <Form onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleSubmit();
            }
          }}>
            <div className="modal-header border-darken-15 bg-white">
              <h5 className="modal-title fw-light" id="exampleModalLabel">
                Delete study
            </h5>
              <button type="button" className="btn btn-link text-black-50 close" onClick={() => hide()}>
                <FontAwesomeIcon icon={falTimes} size="lg" className="mt-1" />
              </button>
            </div>
            <div className="modal-body">
              <div className="alert alert-danger" role="alert">
                <p className="mb-0">
                  Are you sure you want to delete <b>{study.name}</b> ? This action is immediate and cannot be undone. All media in the study will also
                be deleted.
              </p>
              </div>
              <div className="form-group mb-3">
                <h6 className="mb-3">
                  Type <b>DELETE</b> to confirm:
                </h6>
                <TextInput id="projectName" name="name" type="text" className="form-control" placeholder="DELETE" required autoFocus />
              </div>
            </div>
            <div className="modal-footer border-darken-15 bg-white">
              <button type="button" className="btn btn-link text-muted" onClick={() => hide()}>
                Cancel
            </button>
              <Button label={"Delete study"}
                className="btn btn-primary"
                dirty={dirty}
                errors={errors}
                isValidating={isValidating}
                isSubmitting={isLoading}
                onClick={handleSubmit} />
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}

export default DeleteStudyModal;
