import { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes as falTimes, faFolder as falFolder } from "@fortawesome/pro-light-svg-icons";
import { Toaster } from "../Toast";
import Method from "../../utilities/methods";
import { useMoveStudyMutation } from "../../features/studies/api/studySlice";
import { useGetAllProjectsQuery } from "../../features/projects/api/projectsSlice";

const MoveStudyModal = ({ hide, study, projectId }) => {
  const [ pageIndex, setPageIndex ] = useState(1);
  const [ query, setQuery ] = useState("");
  const [ showList, setShowList ] = useState(false);
  const [ targetProjectId, setTargetProjectId ] = useState(null);
  const inputRef = useRef(null);
  const itemsPerPage = 20;
  const view = "all";
  const orderby = "createdat";
  const sortby = "desc";

  const onSearch = (e) => {
    setShowList(() => true);
    setQuery(e.target.value.trimStart());
    setPageIndex(1);
    setTargetProjectId(null);
  };

  const {
    data: { projects } = {},
    isSuccess: isProjectsSuccess,
  } = useGetAllProjectsQuery({ query, view, pageIndex, itemsPerPage, orderby, sortby });


  const [ moveStudy, { isError }] = useMoveStudyMutation();

  const onSubmit = async () => {
    try {
      await moveStudy({ data: { targetProjectId }, studyId: study.id, projectId: projectId }).unwrap();
      if (!isError) hide(true);
      Toaster({ message: "Study successfully moved." });
    } catch (error) {
      Toaster({ message: "Study move failed.", type: "error" });
    }
  };


  return (
    <div className="move-modal">
      <div className="modal-header border-darken-15 bg-white">
        <h5 className="modal-title fw-light" id="exampleModalLabel">
          Move study
        </h5>
        <button type="button" className="btn btn-link text-black-50 close" onClick={() => hide()}>
          <FontAwesomeIcon icon={falTimes} size="lg" className="mt-1" />
        </button>
      </div>
      <div className="modal-body">
        <div className="action-box">
          <form className="form needs-validation"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSubmit();
                }
              }}>
            <div className="form-group">
              <h6>Select a project</h6>
              <div className="form-typeahead dropdown d-relative i-max-width">
                <input
                  ref={inputRef}
                  id="dLabel"
                  role="menuitem"
                  type="text"
                  className="form-control"
                  placeholder="Search projects..."
                  value={query}
                  onChange={(e) => onSearch(e)}
                  onKeyPress={e => {
                    const keyCode = e.keyCode || e.which;
                    if (13 === keyCode) {
                      e.preventDefault();
                      return false
                    }
                  }}
                  onClick={(e)=>setShowList(showList => !showList)}
                  data-bs-toggle="dropdown"
                  aria-label="Search projects..."
                  aria-expanded={showList}
                />
                <div className={showList ? "dropdown-menu w-100 show down-list" : "dropdown-menu w-100 down-list"} aria-labelledby="dLabel">
                  {isProjectsSuccess && projects.map((project) => (
                    <span key={project.id} onClick={(e) => { setTargetProjectId(project.id); setQuery(project.name); setShowList(showList => false); inputRef.current.focus() }} className="dropdown-item d-block position-relative cursor-pointer down-list-item">
                      <span
                        className="icon border rounded-circle position-absolute top-0 start-0 text-center d-flex align-items-center justify-content-center text-info border-info down-list-icon"
                      >
                        <FontAwesomeIcon icon={falFolder} size="lg" />
                      </span>
                      <p className="mb-0">
                        <span className="d-block text-decoration-none text-dark text-nowrap text-truncate fs-sm">
                          {`${project.name} `}
                          <span className="position-relative d-inline-block bg-dark text-white fs-xxs rounded rounded-1 down-list-text">
                            {project.visibility === "public" ? "Shared" : "Private"}
                          </span>
                        </span>
                      </p>
                      <p className="fs-xs text-muted d-flex mb-0">{`Modified ${Method.formatTime(project.updatedAtTimeStamp)} ago`}</p>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </form>  
        </div>
      </div>

      <div className="modal-footer border-darken-15 bg-white">
        <button type="button" className="btn btn-link text-muted" onClick={() => hide()}>
          Cancel
        </button>
        <button type = "submit" className="btn btn-primary" onClick={() => onSubmit()}>Save changes</button>
      </div>

    </div>
  );
}

export default MoveStudyModal;
