import { REHYDRATE } from "redux-persist";

import {
  GET_ALL_PROJECTS_FAILURE,
  GET_ALL_PROJECTS_REQUESTED,
  GET_ALL_PROJECTS_SUCCESS,

  GET_SEARCH_PROJECTS_FAILURE,
  GET_SEARCH_PROJECTS_REQUESTED,
  GET_SEARCH_PROJECTS_SUCCESS,

  GET_PROJECT_SUCCESS,

  UPDATE_PROJECT_SUCCESS,

  GET_STUDY_REQUESTED,
  GET_STUDY_SUCCESS,
  GET_STUDY_FAILURE,

  GET_ALL_STUDIES_FAILURE,
  GET_ALL_STUDIES_REQUESTED,
  GET_ALL_STUDIES_SUCCESS,

  UPDATE_STUDY_REQUESTED,
  UPDATE_STUDY_SUCCESS,

  UPDATE_MEDIA_STUDY_SUCCESS,

  UPDATE_SETTING_REQUESTED,
  UPDATE_SETTING_SUCCESS,
  PROJECT_FILTERS_REQUESTED,
  PROJECT_FILTERS_SUCCESS,
  STUDY_FILTERS_REQUESTED,
  STUDY_FILTERS_SUCCESS,
} from "../actions/project-action-types";
import { SIGNOUT_SUCCESS } from "../actions/session-action-types";
const initialState = {
  allProjects: {},
  getProject: {},
  allStudies: {},
  getStudy: {},
  setting:{},
  projectFilters: { query: "", view: "all", pageIndex: 1, itemsPerPage: 10, orderby: "createdat", sortby: "desc" },
  studyFilters: { query: "", view: "all", pageIndex: 1, itemsPerPage: 10, orderby: "createdat", sortby: "desc" }
};

export default function projects(state = initialState, { payload, type }) {
  const settingData = { ...state.setting };
  const projectFilters = { ...state.projectFilters };
  const studyFilters = { ...state.studyFilters };
  switch (type) {
    case GET_ALL_PROJECTS_REQUESTED:
    case GET_SEARCH_PROJECTS_REQUESTED:
      return {
        ...state,
        allProjects: { isLoading: true, isFetched: false, data: null },
      };

    case GET_ALL_PROJECTS_FAILURE:
    case GET_SEARCH_PROJECTS_FAILURE:
      return {
        ...state,
        allProjects: { isLoading: false, isFetched: true, data: null },
      };

    case GET_ALL_PROJECTS_SUCCESS:
    case GET_SEARCH_PROJECTS_SUCCESS:
      return {
        ...state,
        allProjects: { isLoading: false, isFetched: true, data: payload },
      };

    case GET_PROJECT_SUCCESS:
    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        getProject: {
          loading: false,
          data: payload,
        },
      };

    case GET_ALL_STUDIES_REQUESTED:
      return {
        ...state,
        allStudies: { isLoading: true, isFetched: false, data: null },
      };

    case GET_ALL_STUDIES_FAILURE:
      return {
        ...state,
        allStudies: { isLoading: false, isFetched: true, data: null },
      };

    case GET_ALL_STUDIES_SUCCESS:
      return {
        ...state,
        allStudies: { isLoading: false, isFetched: true, data: payload },
      };

    case UPDATE_SETTING_REQUESTED:
      return {
        ...state,
        setting: {},
      };

    case UPDATE_SETTING_SUCCESS:
      return {
        ...state,
        setting: { ...settingData, ...payload },
      };

    case GET_STUDY_FAILURE:
      return {
        ...state,
        getStudy: { loading: false, data: null },
      };
    case GET_STUDY_REQUESTED:
    case UPDATE_STUDY_REQUESTED:
      return {
        ...state,
        getStudy: { loading: true, data: null, },
      };
    case UPDATE_STUDY_SUCCESS:
    case GET_STUDY_SUCCESS:
    case UPDATE_MEDIA_STUDY_SUCCESS:
      return {
        ...state,
        getStudy: {
          loading: false,
          data: payload,
        },
      };
    case PROJECT_FILTERS_SUCCESS:
      return {
        ...state,
        projectFilters: { ...projectFilters, ...payload },
      };
    case STUDY_FILTERS_SUCCESS:
      return {
        ...state,
        studyFilters: { ...studyFilters, ...payload },
      };
    case PROJECT_FILTERS_REQUESTED:
      return {
        ...state,
        projectFilters: { ...initialState.projectFilters }
      };
    case STUDY_FILTERS_REQUESTED:
      return {
        ...state,
        studyFilters: { ...initialState.studyFilters }
      };

    case SIGNOUT_SUCCESS:
      return { 
        ...initialState 
      };
    case REHYDRATE: {
      let data = { 
        ...initialState 
      };
      if (payload?.project) {
        data = payload.project;
      }
      return data;
    }
    default:
      return state;
  }
}
