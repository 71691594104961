const Method = {

  getColor: (num) => {
    let v = parseFloat((num / 100).toFixed(2))
    let red = 1.0
    let green = 1.0
    let blue = 1.0
    let vmin = 0.0
    let vmax = 1.0
    let dv = vmax - vmin
    if (v < (vmin + 0.25 * dv)) {
      red = 0
      green = 4 * (v - vmin) / dv
    } else if (v < (vmin + 0.5 * dv)) {
      red = 0
      blue = 1 + 4 * (vmin + 0.25 * dv - v) / dv
    } else if (v < (vmin + 0.75 * dv)) {
      red = 4 * (v - vmin - 0.5 * dv) / dv
      blue = 0
    } else {
      green = 1 + 4 * (vmin + 0.75 * dv - v) / dv
      blue = 0
    }
    red = Math.abs(red * 255)
    green = Math.abs(green * 255)
    blue = Math.abs(blue * 255)
    return `rgba(${red}, ${green}, ${blue}, 1)`
  },

  getMedians: arr => {
    const mid = Math.floor(arr.length / 2)
    return arr.length % 2 !== 0 ? [ arr[mid] ] : [ arr[mid], arr[mid + 1] ]
  },

  getHighlightCells: (values) => {
    const sortArr = values.flat(Infinity).sort((a, b) => b - a)
    const medians = Method.getMedians(sortArr)
    const highestValues = sortArr.slice(0, 5)
    return [ ...highestValues, ...medians ]
  },

  formatTime: (timeCreated) => {
    let diff = Date.now() / 1000 - timeCreated;
    if (diff > 31536000) {
      return Math.floor(diff / 31536000) + " years";
    } else if (diff > 2592000) {
      return Math.floor(diff / 2592000) + " months";
    } else if (diff > 86400) {
      return Math.floor(diff / 86400) + " days";
    } else if (diff > 3600) {
      return Math.floor(diff / 3600) + " hours";
    } else if (diff > 60) {
      return Math.floor(diff / 60) + " minutes";
    }
    return Math.abs(Math.floor(diff)) + " seconds";
  },

  indexOf2dArray: (array, item) => {

    let indexes = []
    for (let r = 0; r < array.length; ++r) {
      const nsDetails = array[r];
      for (let c = 0; c < nsDetails.length; ++c) {
        if (array[r][c] == item) {

          let row = r;
          let col = c;
          indexes.push([ row, col ]);
        }
      }
    }
    return indexes;
  },

  generateAllRowCol: async (gridData) => {
    return new Promise((resolve, reject) => {
      let values = [];

      for (let a = 0; a < gridData.scores.length; a++) {
        for (let b = 0; b < gridData.scores[a]?.length; b++) {
          values.push({ x: a, y: b })
        }
      }
      resolve(values)
    })
  },
  trimString: (str, len, addDots)=> {
    return (
      (str && str.length > len) ?
        (addDots !== false ?
          str.substring(0, len - 3) :
          str.substring(0, len - 3) + "...") :
        str);
  }


};

export default Method;
