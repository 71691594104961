import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, InputGroup } from "react-bootstrap";
import debounce from "lodash.debounce";

export const SearchBar = ({ onSearch, debounceAmount = 500 }) => {
  // debounce the search to limit API hits and improve performance
  const debouncedOnChange = debounce(onSearch, debounceAmount);

  return (
    <Form className="d-flex">
      <InputGroup>
        <Button variant="light" className="bg-white border border-form-control">
          <FontAwesomeIcon icon={faSearch} fixedWidth />
        </Button>
        <Form.Control
          type="search"
          placeholder="Search..."
          className="me-2"
          aria-label="Search"
          onChange={(e) => debouncedOnChange(e)}
        />
      </InputGroup>
    </Form>
  );
};
