import { faUmbrellaBeach } from "@fortawesome/pro-light-svg-icons";
// import { faMicroscope } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const NotFound = ({ componentText, icon = faUmbrellaBeach }) => {
  return (
    <div className="p-5 text-center mb-3">
      <div className="py-5">
        <span className="d-inline-block m-auto mb-3 op-20 border rounded-circle text-center d-flex align-items-center justify-content-center text-dark border-dark border-3 icon-size5">
          <FontAwesomeIcon icon={icon} size="3x" />
        </span>
        <p className="text-dark fs-sm fw-500 lh-base mx-auto fst-italic mw-36 o-40">
          {componentText || "It's all quiet in here"}
        </p>
      </div>
    </div>
  );
};
