import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { reportTypes } from "../../constants/reportSettings";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { useProductAccess } from "../../hooks/useProductAccess";

function AddReportModal({ hideModal, closeButton, reportsName, setReportsName, currentReports }) {
  /* cards Ref Arr */
  const cardsRef = useRef([]);
  const hasContextAccess = useProductAccess("contexts");
  const reportTypesCopy = [...reportTypes];

  /* Calculate length based on if they have access to the feature or not */
  const reportsLength = !hasContextAccess ? reportTypesCopy.length - 1 : reportTypesCopy.length;

  const handleSelection = (arrIndex, selectedReport) => {
    /* Toggle the selected class to visually show the selection */
    cardsRef.current[arrIndex].classList.toggle("selected");
    if (cardsRef.current[arrIndex].classList.contains("selected")) {
      /* Add the report to state if the className is there */
      setReportsName([...reportsName, { name: selectedReport.name, type: selectedReport.type }]);
    } else {
      setReportsName(reportsName.filter((report) => report.name !== selectedReport.name));
    }
  };

  const handleSelectAll = (selectType, e) => {
    e.preventDefault();

    /* Check length so that you can't spam add multiple versions of the same report */
    if (
      reportsName.length !== cardsRef.current.length &&
      reportsName.length < reportsLength &&
      selectType === "select"
    ) {
      /* Clear any previously added reports */
      setReportsName([]);

      /* Loop through and show the selected class/ add them to state */
      cardsRef.current.forEach((card, index) => {
        // check if they have context or if the report is disabled
        if ((reportTypesCopy[index].type === "context" && !hasContextAccess) || reportTypesCopy[index].disabled) return;
        card.classList.add("selected");
        setReportsName((report) => [
          ...report,
          { name: reportTypesCopy[index].name, type: reportTypesCopy[index].type },
        ]);
      });
    }

    /* Unselect all cards */
    if (selectType === "deselect") {
      cardsRef.current.forEach((card, index) => {
        // check if the report is disabled so you don't remove the selected styling
        if (reportTypesCopy[index].disabled) return;
        card.classList.remove("selected");
        setReportsName([]);
      });
    }
  };

  return (
    <Container fluid className="" style={{ overflowY: "scroll", paddingBottom: "2rem" }}>
      <div className="modal-header border-darken-15 bg-white mb-2">
        <h5 className="modal-title fw-light" id="exampleModalLabel">
          Select reports to add to your study
        </h5>
        <div>
          {closeButton && (
            <button type="button" className="btn btn-link text-muted" onClick={() => hideModal()}>
              Cancel
            </button>
          )}
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => hideModal(reportsName)}
            disabled={!reportsName.length}
          >
            {reportsName.length > 1 ? "Create Reports" : "Create Report"}
          </button>
        </div>
      </div>
      <Container fluid style={{ maxWidth: "1600px" }}>
        <a href="#" className="d-inline-block my-2" onClick={(e) => handleSelectAll("select", e)}>
          Select all
        </a>
        <span style={{ color: "#f2318a" }}> | </span>
        <a href="#" className="d-inline-block pl-1 my-2" onClick={(e) => handleSelectAll("deselect", e)}>
          Deselect all
        </a>

        <Row xs={1} md={2} xxl={3} className="g-4">
          {reportTypesCopy &&
            reportTypesCopy.length &&
            reportTypesCopy.map((report, index) => (
              <Col key={index}>
                <Card
                  className={`g-4 h-100 py-3 reports-card ${
                    report.type === "context" && !hasContextAccess ? "disabled" : null
                  }`}
                  ref={(card) => (cardsRef.current[index] = card)}
                  onClick={() =>
                    (report.type === "context" && !hasContextAccess) || report.disabled
                      ? null
                      : handleSelection(index, report)
                  }
                >
                  <span className="selected-indicator"></span>
                  {report.newFeature && <span className="new-feature">new</span>}
                  <span className="report-tick-empty"></span>
                  <FontAwesomeIcon icon={faCircleCheck} size="2x" className="report-tick" />
                  <Container fluid className="h-100">
                    <Card.Body className="p-3 h-100 d-flex justify-content-between flex-column">
                      <Row className="text-center">
                        <FontAwesomeIcon
                          icon={report.icon}
                          size="xl"
                          className="text-primary mb-3"
                          style={{ boxSizing: "border-box" }}
                        />
                        <Card.Title as={"h3"}>{report.name}</Card.Title>
                        <Card.Title as={"h6"}>{report.tagline}</Card.Title>
                      </Row>
                      <Row className="d-flex align-items- pt-4">
                        <Col>
                          <Card.Text className="fw-light">{report.description}</Card.Text>
                          <Card.Text className="fw-light">
                            <strong style={{ fontWeight: "700" }}>Tip: </strong>
                            {report.tip}
                          </Card.Text>
                        </Col>
                        <Col className="col-separator">
                          <Image src={report.previewImage} fluid />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Container>
                  <Card.Footer style={{ border: "none", backgroundColor: "transparent" }}>
                    <Container>
                      {!hasContextAccess && report.type === "context" ? (
                        <span className="fs-xs text-danger">Report is not currently available on your plan</span>
                      ) : (
                        <a href={report.infoURL} target="_blank" rel="noreferrer">
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </a>
                      )}
                    </Container>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
        </Row>
      </Container>
    </Container>
  );
}

export default AddReportModal;
