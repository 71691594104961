import { ToggleModal } from "@/components/Modals";
import { faFrame, faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "react-bootstrap";
import { ImagePreviewModal } from "./ImagePreviewModal";
import { faLayerGroup, faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import { ActionDropdown } from "@/components/Cards";
import { segmentAnalytics } from "@/utilities/genericFunctions";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";

export const LibraryCard = ({
  cardMeta,
  cardCoverImage = cardMeta?.thumbUrl,
  cardIcon = faFrame,
  setContextSelection,
  navigateModal: NavigateModal,
  onClick,
  multiSelect = false,
  previewOverlay = false,
  stackNumber,
  updateFunction,
  title,
  status,
  cardSelected,
  actions,
  isContextOnly = false,
}) => {
  const session = useSelector((state) => state.session);

  const cardTitle = cardMeta?.name ? cardMeta.name : cardMeta.filename;
  const useCaseDisplay = cardMeta?.useCase?.name;
  const isCloned = cardMeta?.isClone;

  const handleContextSelection = (e) => {
    if (e.target.checked) {
      setContextSelection((prev) => [...prev, cardMeta]);
    } else {
      setContextSelection((prev) => prev.filter((item) => item.id !== cardMeta.id));
    }
  };

  const contextPreview = (title, name) => {
    /** Segment track event for library context preview */
    if (title === "Context") {
      segmentAnalytics({
        page: false,
        session,
        name: "Library Context Preview",
        segmentData: {
          contextName: name,
        },
      });
    }
  };

  return (
    <Card
      className={`shadow-sm reveal-overlay-hover me-3 mb-3 border ${
        cardSelected ? "border-primary shadow-primary" : ""
      }`}
      style={{ width: "16rem" }}
    >
      {NavigateModal ? (
        <ToggleModal
          toggle={(show) => (
            <Card.Img
              as="div"
              variant="top"
              className="cursor-pointer position-relative img-box"
              style={{
                backgroundImage: `url(${cardCoverImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              onClick={() => show()}
            >
              {multiSelect && (
                <div className="position-absolute top-0 start-0 p-2 m-2 lh-1 bg-white shadow-sm rounded">
                  <input
                    className="form-check-input p-0 m-0"
                    type="checkbox"
                    id="flexCheckDefault"
                    onChange={handleContextSelection}
                    checked={cardSelected ? true : false}
                  />
                </div>
              )}
              {previewOverlay && (
                <ToggleModal
                  dialogClassName="modal-fullscreen"
                  toggle={(show) => (
                    <button
                      onClick={show}
                      className="overlay position-absolute top-0 end-0 btn btn-dark rounded-circle text-center p-0 m-2 shadow-sm"
                      style={{ width: "2rem", height: "2rem" }}
                    >
                      <FontAwesomeIcon icon={faMagnifyingGlass} fixedWidth />
                    </button>
                  )}
                  content={(hide) => (
                    <ImagePreviewModal
                      imageUrl={cardMeta.scaledUrl}
                      title={cardTitle}
                      cardMeta={cardMeta}
                      hide={hide}
                    />
                  )}
                />
              )}
              {stackNumber && stackNumber.length !== 0 ? (
                <span className="position-absolute d-inline-block p-2 bg-secondary text-white fs-xs bottom-0 start-0">
                  <FontAwesomeIcon icon={faLayerGroup} /> {stackNumber}
                </span>
              ) : null}
            </Card.Img>
          )}
          content={(hide) => <NavigateModal modalMeta={cardMeta} hide={() => hide()} />}
          dialogClassName={"modal-fullscreen"}
          contentClassName={"bg-light"}
        />
      ) : (
        <Card.Img
          as="div"
          variant="top"
          className="cursor-pointer position-relative img-box"
          style={{
            backgroundImage: `url(${cardCoverImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          onClick={onClick ? onClick : null}
        >
          {multiSelect && (
            <div className="position-absolute top-0 start-0 p-2 m-2 lh-1 bg-white shadow-sm rounded">
              <input
                className="form-check-input p-0 m-0"
                type="checkbox"
                id="flexCheckDefault"
                onChange={handleContextSelection}
                checked={cardSelected ? true : false}
              />
            </div>
          )}
          {previewOverlay && (
            <ToggleModal
              dialogClassName="modal-fullscreen"
              toggle={(show) => (
                <button
                  onClick={(e) => {
                    contextPreview(title, cardTitle);
                    show();
                  }}
                  className="overlay position-absolute top-0 end-0 btn btn-dark rounded-circle text-center p-0 m-2 shadow-sm"
                  style={{ width: "2rem", height: "2rem" }}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} fixedWidth />
                </button>
              )}
              content={(hide) => (
                <ImagePreviewModal imageUrl={cardMeta.scaledUrl} title={cardTitle} cardMeta={cardMeta} hide={hide} />
              )}
            />
          )}
          {stackNumber && stackNumber.length !== 0 ? (
            <span className="position-absolute d-inline-block p-2 bg-secondary text-white fs-xs bottom-0 start-0">
              <FontAwesomeIcon icon={faLayerGroup} /> {stackNumber}
            </span>
          ) : null}
        </Card.Img>
      )}
      <Card.Body style={{ padding: "0rem" }}>
        <div className="position-relative" style={{ padding: "1rem", paddingLeft: "4.25rem" }}>
          <span
            className="icon border rounded-circle position-absolute top-0 start-0 text-center d-flex align-items-center justify-content-center text-info border-info"
            style={{ width: "2.75rem", height: "2.75rem", margin: "0.75rem 0 0 0.75rem" }}
          >
            <FontAwesomeIcon icon={cardIcon} size="lg" />
          </span>
          <Card.Title
            as="h6"
            className="fw-700 mb-1 text-truncate text-nowrap pe-5"
            data-tooltip-id="library-card-title"
            data-tooltip-content={cardTitle}
          >
            {cardTitle}
          </Card.Title>
          <Tooltip
            place="bottom"
            effect="solid"
            variant="light"
            id="library-card-title"
            style={{ boxShadow: "1px 1px 6px rgba(0, 0, 0, 0.05)" }}
          />

          {updateFunction && (
            <ActionDropdown
              updateFunction={updateFunction}
              cardMeta={cardMeta}
              title={title}
              actions={actions}
              contextPreview={contextPreview}
            />
          )}
          {isContextOnly && status && (
            <Card.Text as="p" className="card-text text-uppercase fs-xxs m-0">
              {status}
            </Card.Text>
          )}
          <Card.Text
            as="p"
            className="badge text-info fw-500 text-uppercase fs-xxs p-1 m-0"
            style={{ backgroundColor: "rgba(6,78,142,.1)" }}
          >
            {useCaseDisplay}
          </Card.Text>
        </div>

        {!isContextOnly && (
          <div className="card-footer d-flex text-muted fs-xs">
            {isCloned && (
              <span className="d-inline-block text-nowrap text-truncate w-50 text-decoration-none text-secondary">
                <img src="/img/dragonfly-min-icon.svg" height={12} style={{ marginTop: "-0.1rem" }} alt="" />
                &nbsp;Dragonfly
              </span>
            )}
            {!isCloned && (
              <span className="d-inline-block text-nowrap text-truncate w-50 text-decoration-none text-secondary">
                <FontAwesomeIcon icon={faUserCircle} fixedWidth />
                {` ${cardMeta?.user.firstName} ${cardMeta?.user.lastName}`}
              </span>
            )}
            {status && (
              <span className="text-decoration-none text-secondary ms-auto">
                {status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
              </span>
            )}
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
