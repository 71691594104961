import { useSelector } from "react-redux";

export const useProductAccess = (productName) => {
  /* 
    Custom hook to check the status of a product name from the productAccess array that is passed through from CB
    example array: ["studio", "admin", "contexts"]
    further changes and updates to the array are likely to happen in the future due to more complicated product access
    i.e "only X number can be created etc"
  */
  const hasAccess = useSelector((state) => state.session.productAccess)?.includes(productName);

  return hasAccess;
}