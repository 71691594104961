import { useEffect, useState } from "react";
import {
  useGetAllContextPacksQuery,
  useGetAllContextsQuery,
  useUpdateContextPackMutation,
} from "@/features/library/api/librarySlice";
import { faCircleInfo, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LibraryCard } from "@/features/library/components/LibraryCard";
import { Loader, Pagination } from "@/components";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { Toaster } from "../Toast";
import { NotFound } from "@/features/library/components/NotFound";
import { useDispatch, useSelector } from "react-redux";
import { updateContextFilter } from "@/features/library/stores/filtersSlice";
import { LibraryNavbar } from "@/features/library/components/LibraryNavbar";
import { faLayerGroup, faLayerPlus } from "@fortawesome/pro-light-svg-icons";
import { CardSmall } from "../Cards/CardSmall";
import { segmentAnalytics } from "@/utilities/genericFunctions";

export const ContextPackModal = ({ hide, modalMeta }) => {
  const { name, status, id, useCase } = modalMeta;
  const [contextSelection, setContextSelection] = useState({});

  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);
  const contextPackFilters = useSelector((state) => state.libraryFilters.contextPackFilters);
  const contextFilters = useSelector((state) => state.libraryFilters.contextFilters);

  const {
    data: { filteredPack } = {},
    isSuccess: packIsSuccess,
    isLoading: packIsLoading,
  } = useGetAllContextPacksQuery({ ...contextPackFilters, useCaseIds: [useCase.id], contextPackId: id });

  const {
    data: {
      contexts,
      maxPageIndex: contextMaxPageIndex,
      maxRecordCount: contextMaxRecordCount,
      pageIndex: contextPageIndex,
    } = {},
    isSuccess: contextsIsSuccess,
    isLoading: contextsIsLoading,
  } = useGetAllContextsQuery({ ...contextFilters, useCaseIds: [useCase.id], contextPackId: id });

  const [updateContextPack] = useUpdateContextPackMutation();

  const savePack = async (type) => {
    let packData = {};
    type === "publish" ? (packData.status = "published") : (packData.status = "draft");
    packData.contextIds = contextSelection.map((context) => context.id);
    try {
      await updateContextPack({ data: packData, contextPackId: id }).unwrap();

      /** Segment track event for context pack publish */
      segmentAnalytics({
        page: false,
        session,
        name: "Library Context Pack Publish",
        segmentData: {
          contextPackName: name,
        },
      });

      Toaster({ message: "Context pack successfully updated." });
      hide();
    } catch (e) {
      Toaster({ message: "Updating context pack failed.", type: "error" });
    }
  };

  useEffect(() => {
    if (packIsSuccess && filteredPack) {
      setContextSelection(filteredPack.contexts);
    }
  }, [filteredPack]);

  return (
    <>
      <Modal.Header className="border-darken-15 bg-white">
        <Modal.Title as="h5" className="fw-light">
          {name} <span className="badge bg-light text-dark fs-xxs">{status?.toUpperCase()}</span>
        </Modal.Title>

        <div>
          <Button
            variant="primary"
            className="me-2"
            onClick={() => savePack("draft")}
            disabled={!contextSelection.length}
          >
            Save
          </Button>
          <Dropdown className="d-inline-block">
            <Dropdown.Toggle
              variant="outline-primary"
              id="dropdown-basic"
              className="me-2"
              disabled={!contextSelection.length}
            >
              Save &amp; Publish
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-3" style={{ width: "12rem" }} align="end">
              <p className="fs-sm mb-2">
                <FontAwesomeIcon icon={faCircleInfo} className="me-1" /> Hint
              </p>
              <p className="fs-xs text-muted">
                Once published, your pack can't be edited. If you want to make a change, you'll need to create a new
                pack.
              </p>
              <p className="mb-0">
                <Button variant="outline-primary" className="btn-sm" onClick={() => savePack("publish")}>
                  Confirm
                </Button>
                <a href="" className="float-end fs-xs text-muted mt-2">
                  Learn more
                </a>
              </p>
            </Dropdown.Menu>
          </Dropdown>
          <Button variant="link" className="text-black-50" onClick={() => hide()}>
            <FontAwesomeIcon icon={faTimes} size="lg" className="mt-1" />
          </Button>
        </div>
      </Modal.Header>
      <div className="modal-body p-0 position-relative">
        <section className="border-bottom py-3">
          <header className="position-relative ms-3 ps-5">
            <span
              className="d-inline-block position-absolute start-0 top-0 bg-primary-10 rounded-circle text-primary text-center"
              style={{ width: "2.4em", height: "2.4em", paddingTop: "8px" }}
            >
              <FontAwesomeIcon icon={faLayerPlus} size="lg" />
            </span>
            <h2 className="fs-6 mb-1">Select contexts</h2>
            <p className="text-muted fs-xs mb-0">Select one or more contexts below to build your pack.</p>
          </header>
        </section>
        <div className="h-100 w-100 d-flex flex-row">
          <div className="h-100 flex-grow-1 p-4 overflow-auto" style={{ width: "90%" }}>
            <LibraryNavbar
              filterDisplay={{ name: "Name", createdat: "Created", owner: "Owner" }}
              filterState={contextFilters}
              filterStore={updateContextFilter}
              displayUseCase={useCase?.name}
            />
            {packIsLoading && contextsIsLoading && <Loader show={true} />}
            {packIsSuccess && contextsIsSuccess && contexts.length === 0 && (
              <NotFound
                icon={faLayerGroup}
                componentText="When you upload contexts to your library, they will be available here to add to your pack."
              />
            )}
            {packIsSuccess && contextsIsSuccess && contexts?.length > 0 && (
              <div className="d-flex flex-row flex-wrap">
                {contexts.map((packContext, i) => (
                  <LibraryCard
                    key={`col-${i}`}
                    cardMeta={packContext}
                    setContextSelection={setContextSelection}
                    contextSelection={contextSelection}
                    multiSelect={true}
                    cardSelected={
                      contextSelection?.length > 0
                        ? contextSelection?.find((item) => item.id === packContext.id)
                        : false
                    }
                  />
                ))}
              </div>
            )}
            <nav className="navbar navbar-expand-lg navbar-light mt-5 mb-0">
              {contextsIsSuccess && (
                <Pagination
                  key={`${contextFilters.itemsPerPage}`}
                  maxPageIndex={contextMaxPageIndex}
                  pageIndex={contextPageIndex}
                  totalRecords={contextMaxRecordCount}
                  pageLimit={contextFilters.itemsPerPage}
                  pageNeighbours={2}
                  onPageChanged={({ currentPage }) => dispatch(updateContextFilter({ pageIndex: currentPage }))}
                  onLimitChange={(itemsPerPage) => {
                    dispatch(updateContextFilter({ itemsPerPage, pageIndex: 1 }));
                  }}
                />
              )}
            </nav>
          </div>
          <div className="h-100 p-3 overflow-auto" style={{ width: "20rem" }}>
            {contextSelection && contextSelection.length > 0 ? (
              <>
                <h1 className="h5 fw-700 mb-3">Selection ({contextSelection.length})</h1>
                <div className="media-items">
                  {contextSelection && contextSelection?.length > 0 && (
                    <>
                      <div className="d-flex flex-row flex-wrap">
                        {contextSelection.map((packContext, i) => (
                          <CardSmall
                            key={`col-${i}`}
                            cardMeta={packContext}
                            setContextSelection={setContextSelection}
                            contextSelection={contextSelection}
                            // setShowAssetPanel={setShowAssetPanel}
                            // showAssetPanel={showAssetPanel}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <div className="w-100 h-100">
                <div className="text-center mb-3">
                  <div className="py-4">
                    <span
                      className="d-inline-block m-auto mb-3 border rounded-circle text-center d-flex align-items-center justify-content-center text-dark border-dark border-2 o-20"
                      style={{ width: "4rem", height: "4rem" }}
                    >
                      <i className="fal fa-2x fa-empty-set" aria-hidden="true"></i>
                    </span>
                    <span className="text-dark fw-500 o-40 fs-sm">0 items selected</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
