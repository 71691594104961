import React, { useState, useEffect } from "react";
import packageJson from "../package.json";

const ClearCache = ({ showPopUp, setShowPopUp }) => {
  const [ promptRefresh, setPromptRefresh ] = useState(false);

  useEffect(() => {
    /* Time stamp is used in fetch request due to aggresive caching and the query param forces the load of live data */
    const timestamp = new Date().getTime();
    fetch(`/meta.json?t=${timestamp}`)
      .then((response) => response.json())
      .then(({ buildDate }) => {
        /* Call the buildDate from the meta json to get the build date from the latest release */
        const latestBuildDate = buildDate;

        /* Get the build date currently being loaded */
        const currentBuildDate = packageJson.buildDate;

        /* If the latest release date is after the current build date being loaded then they need to refresh */
        const shouldForceRefresh = latestBuildDate > currentBuildDate ? true : false;

        if (shouldForceRefresh) {
          setPromptRefresh(true);
          setShowPopUp(true);
        } else {
          setPromptRefresh(false);
        }
      });
  }, [ setShowPopUp ]);

  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };

  return (
    <>
      {
        promptRefresh && showPopUp ? <div className="alert alert-dark d-flex justify-content-between  w-auto align-items-center position-absolute bottom-0 start-40  right-0 justify-content-center mx-auto mb-3 zindex1000">
          <p className="m-0 p-0">
            Studio has just been updated, click "Refresh" to start using the latest release!
          </p>
          <button className="btn btn-primary mx-3" onClick={() => refreshCacheAndReload()}>Refresh</button>
        </div> 
        : null
      }
    </>
  );
}

export default ClearCache;