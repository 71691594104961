import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faArchive,
  faTrashAlt,
  faUmbrellaBeach,
  faEllipsisV,
  faExternalLinkSquare,
  faImagePolaroid,
} from "@fortawesome/pro-regular-svg-icons";
import { faImagePolaroid as faImagePolaroidFilled } from "@fortawesome/pro-solid-svg-icons";
import { ToggleModal, DeleteStudyModal, MoveStudyModal, RenameStudyModal } from "@/components/Modals";
import Method from "@/utilities/methods";
import { useUpdateStudyMutation } from "../api/studySlice";
import { Toaster } from "@/components/Toast";
import { Card as CardBS } from "react-bootstrap";
import { UpdateUseCaseModal } from "@/components/Tags";

export const Card = ({ study, projectId }) => {
  const [updateStudy] = useUpdateStudyMutation();

  const archivedStudy = async () => {
    try {
      await updateStudy({
        data: { name: study.name, isArchived: !study.isArchived },
        projectId,
        studyId: study.id,
      }).unwrap();
      Toaster({ message: `Study successfully ${study.isArchived ? "unarchived" : "archived"}.` });
    } catch (error) {
      Toaster({ message: `Study ${study.isArchived ? "unarchived" : "archived"} failed.`, type: "error" });
    }
  };

  return (
    <div className="col">
      <div className="card border-0 shadow-sm reveal-overlay-hover">
        {!Object.hasOwn(study, "useCase") ? (
          <ToggleModal
            toggle={(show) => (
              <div className="position-relative img-box bg-secondary cursor-pointer" onClick={show}>
                <span className="d-block">
                  {(study.thumbUrl === "" || !study.thumbUrl) && (
                    <div>
                      <div className="d-flex flex-column align-items-center justify-content-center text-center position-absolute top-0 start-0 w-100 h-100">
                        <span className="border rounded-circle text-center op-50 d-flex align-items-center justify-content-center text-light border-light border-2 mb-2 icon-size">
                          <FontAwesomeIcon icon={faUmbrellaBeach} size="lg" />
                        </span>
                        <span className="fs-sm text-light fw-400 op-50">Awaiting genius content</span>
                      </div>
                    </div>
                  )}
                  {study.thumbUrl && (
                    <img src={study.thumbUrl} className="card-img-top" alt={study.thumbUrl ? "..." : ""} />
                  )}
                </span>
              </div>
            )}
            content={(hide) => <UpdateUseCaseModal hide={hide} study={study} projectId={projectId} />}
          />
        ) : (
          <NavLink to={`/projects/${projectId}/studies/${study.id}`}>
            <div className="position-relative img-box bg-secondary">
              <span className="d-block">
                {(study.thumbUrl === "" || !study.thumbUrl) && (
                  <div>
                    <div className="d-flex flex-column align-items-center justify-content-center text-center position-absolute top-0 start-0 w-100 h-100">
                      <span className="border rounded-circle text-center op-50 d-flex align-items-center justify-content-center text-light border-light border-2 mb-2 icon-size">
                        <FontAwesomeIcon icon={faUmbrellaBeach} size="lg" />
                      </span>
                      <span className="fs-sm text-light fw-400 op-50">Awaiting genius content</span>
                    </div>
                  </div>
                )}
                {study.thumbUrl && (
                  <img src={study.thumbUrl} className="card-img-top" alt={study.thumbUrl ? "..." : ""} />
                )}
              </span>
            </div>
          </NavLink>
        )}
        <div className="card-body position-relative pl-4r">
          <span
            className={`icon border border-2 rounded-circle position-absolute top-0 start-0 text-center d-flex align-items-center justify-content-center ${
              study?.useCase?.id ? "text-primary border-primary" : "text-secondary"
            } icon-size icon-size-margin`}
            style={study?.useCase?.id ? { opacity: "1" } : { opacity: "0.3" }}
          >
            {study?.useCase?.id ? (
              <FontAwesomeIcon icon={faImagePolaroid} size="xl" />
            ) : (
              <FontAwesomeIcon icon={faImagePolaroidFilled} size="xl" />
            )}
          </span>
          <h5 className="card-title h6 fw-700 pe-5 mb-1">
            {!Object.hasOwn(study, "useCase") ? (
              <ToggleModal
                toggle={(show) => (
                  <a
                    className="d-block text-decoration-none text-dark text-nowrap text-truncate fs-sm cursor-pointer"
                    onClick={show}
                  >
                    {study.name}
                  </a>
                )}
                content={(hide) => <UpdateUseCaseModal hide={hide} study={study} projectId={projectId} />}
              />
            ) : (
              <NavLink
                to={`/projects/${projectId}/studies/${study.id}`}
                className="d-block text-decoration-none text-dark text-nowrap text-truncate fs-sm"
              >
                {study.name}
              </NavLink>
            )}
          </h5>
          <div className="dropdown position-absolute top-0 end-0 mt-2 me-2">
            <button
              className="btn btn-light bg-white border-0"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-display="static"
            >
              <FontAwesomeIcon icon={faEllipsisV} />
            </button>
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
              <li>
                <h6 className="dropdown-header">Actions</h6>
              </li>
              <li>
                <ToggleModal
                  toggle={(show) => (
                    <span className="dropdown-item cursor-pointer" onClick={show}>
                      <FontAwesomeIcon icon={faPencil} fixedWidth className="me-1" /> Rename
                    </span>
                  )}
                  content={(hide) => <RenameStudyModal hide={hide} study={study} projectId={projectId} />}
                />
              </li>
              <li>
                <ToggleModal
                  toggle={(show) => (
                    <span className="dropdown-item cursor-pointer" onClick={show}>
                      <FontAwesomeIcon icon={faExternalLinkSquare} fixedWidth className="me-1" /> Move
                    </span>
                  )}
                  content={(hide) => <MoveStudyModal hide={hide} study={study} projectId={projectId} />}
                />
              </li>
              <li>
                <span className="dropdown-item cursor-pointer" onClick={archivedStudy}>
                  <FontAwesomeIcon icon={faArchive} fixedWidth className="me-1" />{" "}
                  {study.isArchived ? "Unarchive" : "Archive"}
                </span>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <ToggleModal
                  toggle={(show) => (
                    <span className="dropdown-item text-danger cursor-pointer" onClick={show}>
                      <FontAwesomeIcon icon={faTrashAlt} fixedWidth className="me-1" /> Delete
                    </span>
                  )}
                  content={(hide) => <DeleteStudyModal hide={hide} study={study} projectId={projectId} />}
                />
              </li>
            </ul>
          </div>
          <>
            <CardBS.Text
              as="p"
              className="badge text-info fw-500 text-uppercase fs-xxs p-1 mb-1"
              style={{ backgroundColor: "rgba(6,78,142,.1)" }}
            >
              {"useCase" in study ? study.useCase?.name : "No use case"}
            </CardBS.Text>
            <p className="card-text fs-xs text-muted d-flex mb-0">{`Modified ${Method.formatTime(
              study.updatedAtTimeStamp
            )} ago`}</p>
          </>
        </div>
      </div>
    </div>
  );
};
