import { createAction } from "redux-actions";

export const GENERATE_GRIDS = "GENERATE_GRIDS";
export const GenerateGrids = createAction(GENERATE_GRIDS);

export const GENERATE_GRID_REQUESTED = "GENERATE_GRID_REQUESTED";
export const GenerateGridRequested = createAction(GENERATE_GRID_REQUESTED);

export const GENERATE_GRID_FAILURE = "GENERATE_GRID_FAILURE";
export const GenerateGridFailure = createAction(GENERATE_GRID_FAILURE);

export const GENERATE_GRID_SUCCESS = "GENERATE_GRID_SUCCESS";
export const GenerateGridSuccess = createAction(GENERATE_GRID_SUCCESS);