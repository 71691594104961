import { useState } from "react";
import { LibraryNavbar } from "./LibraryNavbar";
import { useGetAllContextsQuery, useUpdateContextMutation } from "../api/librarySlice";
import { Loader, Pagination } from "@/components";
import { NotFound } from "./NotFound";
import { LibraryCard } from "./LibraryCard";
import { useDispatch, useSelector } from "react-redux";
import { AssetPanel } from "./AssetPanel";
import { updateContextFilter } from "../stores/filtersSlice";
import { AddContextMediaModal } from "@/components/Modals";
import { Toaster } from "@/components/Toast";
import { faFrame } from "@fortawesome/pro-light-svg-icons";

export const Contexts = () => {
  const dispatch = useDispatch();
  const contextFilters = useSelector((state) => state.libraryFilters.contextFilters);

  const [contextSelection, setContextSelection] = useState({});
  const [showAssetPanel, setShowAssetPanel] = useState(false);

  const {
    data: { contexts, maxPageIndex, maxRecordCount, pageIndex } = {},
    isSuccess: contextsIsSuccess,
    isLoading: contextsIsLoading,
  } = useGetAllContextsQuery(contextFilters);

  const [updateContext, mutationStatus] = useUpdateContextMutation();

  const onSubmit = async (values, cardId) => {
    try {
      await updateContext({ data: values, contextId: cardId }).unwrap();
      Toaster({ message: "Context updated successfully." });
    } catch (error) {
      Toaster({ message: "Context update failed.", type: "error" });
    }
  };

  return (
    <>
      <div className="h-100 flex-grow-1 p-4 overflow-auto">
        <LibraryNavbar
          filterDisplay={{ name: "Name", createdat: "Created", owner: "Owner" }}
          filterState={contextFilters}
          filterStore={updateContextFilter}
          uploadContentText="Upload"
          uploadComponent={AddContextMediaModal}
          modalClassNames="modal-fullscreen"
        />
        {contextsIsLoading && <Loader show={true} />}
        {contextsIsSuccess && contexts?.length <= 0 && <NotFound icon={faFrame} />}
        {contextsIsSuccess && (
          <div className="d-flex flex-row flex-wrap">
            {contexts.map((context, i) => (
              <LibraryCard
                key={`col-${i}`}
                cardMeta={context}
                setContextSelection={setContextSelection}
                setShowAssetPanel={setShowAssetPanel}
                showAssetPanel={showAssetPanel}
                previewOverlay={true}
                onClick={() => {
                  setShowAssetPanel(true);
                  setContextSelection(context);
                }}
                title="Context"
                updateFunction={onSubmit}
                updateHook={updateContext}
                mutationStatus={mutationStatus}
                actions={["preview", "rename", "archive"]}
                isContextOnly
              />
            ))}
          </div>
        )}
        <nav className="navbar navbar-expand-lg navbar-light mt-5 mb-0">
          {contextsIsSuccess && (
            <Pagination
              key={`${contextFilters.itemsPerPage}`}
              maxPageIndex={maxPageIndex}
              pageIndex={pageIndex}
              totalRecords={maxRecordCount}
              pageLimit={contextFilters.itemsPerPage}
              pageNeighbours={2}
              onPageChanged={({ currentPage }) => dispatch(updateContextFilter({ pageIndex: currentPage }))}
              onLimitChange={(itemsPerPage) => {
                dispatch(updateContextFilter({ itemsPerPage, pageIndex: 1 }));
              }}
            />
          )}
        </nav>
      </div>
      {showAssetPanel && (
        <AssetPanel
          showAssetPanel={showAssetPanel}
          contextSelection={contextSelection}
          setContextSelection={setContextSelection}
          setShowAssetPanel={setShowAssetPanel}
          updateFunction={onSubmit}
          updateHook={updateContext}
          mutationStatus={mutationStatus}
        />
      )}
    </>
  );
};
