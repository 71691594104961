import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes as falTimes } from "@fortawesome/pro-light-svg-icons";
import { Form, Formik } from "formik";
import { Button, TextInput } from "../FormFields";
import { useDeleteProjectMutation } from "../../features/projects/api/projectsSlice";
import { Toaster } from "../Toast";


function DeleteProjectModal({ hide, project }) {
  const [deleteProject, { isLoading, isError }] = useDeleteProjectMutation();

  const onSubmit = async () => {
    try {
      await deleteProject({ id: project.id }).unwrap();
      if (!isError) hide(true);
      Toaster({ message: "Project removed successfully." });
    } catch (error) {
      Toaster({ message: "Removing project failed.", type: "error" });
    }
  };

  return (
    <Formik
      initialValues={{ name: "" }}
      validationSchema={Yup.object({
        name: Yup.string()
          .required("Confirmation is required.")
          .test("confirm-name", "Please type DELETE correctly.", (value) => "DELETE" === value),
      })}
      onSubmit={onSubmit}
    >
      {({ dirty, errors, isValidating, handleSubmit }) => (
        <>
          <Form onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleSubmit();
            }
          }}>
            <div className="modal-header border-darken-15 bg-white">
              <h5 className="modal-title fw-light" id="exampleModalLabel">
                Delete project
          </h5>
              <button type="button" className="btn btn-link text-black-50 close" onClick={() => hide()}>
                <FontAwesomeIcon icon={falTimes} className="mt-1" size="lg" />
              </button>
            </div>
            <div className="modal-body">
              <div className="alert alert-danger" role="alert">
                <p className="mb-0">
                  Are you sure you want to delete <b>{project.name}</b> ? This action is immediate and cannot be undone. All studies and media in the
              project will also be deleted.
            </p>
              </div>
              <div className="form-group mb-3">
                <h6 className="mb-3">
                  Type <b>DELETE</b> to confirm:
              </h6>
                <TextInput id="projectName" name="name" type="text" className="form-control" placeholder="DELETE" required autoFocus />
              </div>
            </div>
            <div className="modal-footer border-darken-15 bg-white">
              <button type="button" className="btn btn-link text-muted" onClick={() => hide()}>
                Cancel
          </button>
              <Button label={"Delete project"}
                className="btn btn-primary"
                dirty={dirty}
                errors={errors}
                isValidating={isValidating}
                isSubmitting={isLoading}
                onClick={handleSubmit} />
            </div>
          </Form>
        </>)}
    </Formik>
  );
}

export default DeleteProjectModal;
