import { api } from "./apiSlice";
import { Toaster } from "@/components/Toast";

export const extendedApiSlice2 = api.injectEndpoints({
  endpoints: (builder) => ({
    getUseCases: builder.query({
      query: ({ accountId }) => `/manage/accounts/${accountId}/usecases`,
      keepUnusedDataFor: 0,
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          Toaster({ message: "Failed to fetch all use cases", type: "error" });
        }
      },
      transformResponse: ({ payload }) => {
        let filteredUseCases = [];
        if(payload && payload.useCases && payload.useCases.length) {
          const sortedUseCases = payload.useCases.sort((usecase, preUseCase) => usecase.category.localeCompare(preUseCase.category) || usecase.name.localeCompare(preUseCase.name));

          let category = null;
          let otherCategory = [];
          sortedUseCases.forEach((usecase, key) => {
            if(usecase.category.toLowerCase() === "other") {
              if(usecase.category !== category) {
                otherCategory = [ ...otherCategory, { id: key, category: usecase.category, isCategory: true }];
              }
              otherCategory = [ ...otherCategory, usecase ];
              category = usecase.category;
              return;
            }
            if(usecase.category !== category) {
              filteredUseCases = [ ...filteredUseCases, { id: key, category: usecase.category, isCategory: true }];
            }
            filteredUseCases = [ ...filteredUseCases, usecase ];
            category = usecase.category;
          });
          filteredUseCases = [ ...filteredUseCases, ...otherCategory ];
        }
        return {
          useCases: payload.useCases,
          filteredUseCases: filteredUseCases,
        };
      }
    }),
  }),
});

export const {
  useGetUseCasesQuery,
} = extendedApiSlice2;
