import { SearchBar, SortDropdown, UseCaseFilter } from "@/components/Filters";
import { ToggleModal } from "@/components/Modals";
import { ContextPackModal } from "@/components/Modals/ContextPackModal";
import { faLock } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Navbar } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const LibraryNavbar = ({
  filterDisplay,
  uploadComponent: UploadComponent,
  filterState,
  filterStore,
  uploadContentText,
  modalClassNames = "",
  displayUseCase,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderChange = (orderVal) => {
    dispatch(filterStore({ orderby: orderVal }));
  };

  const onSearch = (e) => {
    dispatch(filterStore({ query: e.target.value.trimStart(), pageIndex: 1 }));
  };

  const sortChange = (sortVal) => {
    dispatch(filterStore({ sortby: sortVal }));
  };

  const onUseCaseFilter = (useCaseSelection) => {
    dispatch(filterStore({ useCaseIds: useCaseSelection, pageIndex: 1 }));
  };

  // show state for pack modal after creation
  const [showPackModal, setShowPackModal] = useState(false);
  // pack data to pass to the modal
  const [packData, setPackData] = useState({});

  useEffect(() => {
    if (uploadContentText === "New pack" && packData && "useCase" in packData) {
      setShowPackModal(true);
    }
  }, [packData]);

  return (
    <Navbar className="mb-3">
      <SearchBar onSearch={onSearch} />

      {displayUseCase ? (
        <div className="d-flex ms-auto  me-lg-2">
          <span className="btn btn-transparent bg-info-10 text-info me-2 pe-none rounded-pill">
            <FontAwesomeIcon icon={faLock} className="me-1" /> {displayUseCase}
          </span>
        </div>
      ) : (
        <UseCaseFilter filterState={filterState} filterStore={filterStore} onUseCaseFilter={onUseCaseFilter} />
      )}

      <SortDropdown
        filterDisplay={filterDisplay}
        filterState={filterState}
        orderChange={orderChange}
        sortChange={sortChange}
      />

      {uploadContentText && UploadComponent ? (
        <ToggleModal
          toggle={(show) => (
            <div className="btn-group" role="group" aria-label="Third group" onClick={show}>
              <button className="btn btn-primary" onClick={show}>
                {uploadContentText}
              </button>
            </div>
          )}
          content={(hide) => (
            <UploadComponent
              hide={(data) => {
                hide();
                if (data && data.payload) {
                  if (uploadContentText === "New benchmark") {
                    navigate(`${data.payload.benchmark.id}`, { state: { benchmark: data.payload.benchmark } });
                  }
                  if (uploadContentText === "New pack") {
                    setPackData(data.payload.contextPack);
                  }
                }
              }}
            />
          )}
          contentClassName={"bg-light"}
          dialogClassName={modalClassNames}
        />
      ) : null}

      {/* when a user create a context pack we need to show the modal for said pack */}
      <ToggleModal
        toggle={(show) => {
          if (showPackModal) {
            // in the current interation of the modal, the show condition has to sit within a timeout
            setTimeout(() => {
              show();

              // set it back to false otherwise it will show again after saving contexts against it inside the modal
              setShowPackModal(false);
            }, 200);
          }
        }}
        content={(hide) => (
          <ContextPackModal
            hide={() => {
              setShowPackModal(false);
              hide();
            }}
            modalMeta={packData}
          />
        )}
        contentClassName={"bg-light"}
        dialogClassName="modal-fullscreen"
      />
    </Navbar>
  );
};
