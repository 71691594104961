import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes as falTimes } from "@fortawesome/pro-light-svg-icons";
import { Form, Formik } from "formik";
import { Button, TextInput } from "../FormFields";

export const DeleteModal = ({ hide, updateFunction, mutationStatus, cardMeta, title = "Modal" }) => {
  return (
    <Formik
      initialValues={{ name: "" }}
      validationSchema={Yup.object({
        name: Yup.string()
          .required("Confirmation is required.")
          .test("confirm-name", "Please type DELETE correctly.", (value) => "DELETE" === value),
      })}
      onSubmit={() => {
        updateFunction(cardMeta.id);
        hide();
      }}
    >
      {({ dirty, errors, isValidating, handleSubmit }) => (
        <>
          <Form
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit();
              }
            }}
          >
            <div className="modal-header border-darken-15 bg-white">
              <h5 className="modal-title fw-light" id="exampleModalLabel">
                Delete {title}
              </h5>
              <button type="button" className="btn btn-link text-black-50 close" onClick={() => hide()}>
                <FontAwesomeIcon icon={falTimes} className="mt-1" size="lg" />
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group mb-3">
                <h6 className="mb-3">
                  Type <b>DELETE</b> to confirm:
                </h6>
                <TextInput
                  id="assetName"
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="DELETE"
                  required
                  autoFocus
                />
              </div>
            </div>
            <div className="modal-footer border-darken-15 bg-white">
              <button type="button" className="btn btn-link text-muted" onClick={() => hide()}>
                Cancel
              </button>
              <Button
                label={`Delete ${title}`}
                className="btn btn-primary"
                dirty={dirty}
                errors={errors}
                isValidating={isValidating}
                isSubmitting={mutationStatus?.isLoading}
                onClick={handleSubmit}
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};
