import { all, call, put, takeEvery } from "redux-saga/effects";
import { Toaster } from "../../components/Toast";
import HttpClient from "../../config/http-client";
import {
  GenerateMapFailure,
  GenerateMapRequested,
  GenerateMapSuccess,
  GENERATE_MAP,
  GenerateContextMapFailure,
  GenerateContextMapRequested,
  GenerateContextMapSuccess,
  GENERATE_CONTEXT_MAP,
} from "../actions/processing-action-types";

export function* GenerateMap({ payload: { key, type, bias, indexId, blend, callback } }) {
  yield put(GenerateMapRequested());

  const payload = {
    method: "post",
    url: "/web/images/maps",
    data: {
      key: key,
      type,
      bias,
      blend,
    },
    // responseType: "blob"
  };

  const { error, result } = yield call(HttpClient, payload, true, true);

  if (error) {
    yield put(GenerateMapFailure(error));
    Toaster({ message: `Failed to generate ${type}map`, type: "error" });
  } else {
    yield put(GenerateMapSuccess({ result, indexId, type }));
    // Toaster({message:`${type}map generated successfully`});
  }
  if (callback) callback(error, result);

  return { error, result };
}
export function* GenerateContextMap({ payload: { key, type, bias, indexId, blend, contextId, callback } }) {
  yield put(GenerateContextMapRequested());

  const payload = {
    method: "post",
    url: "/web/images/contexts/maps",
    data: {
      key: key,
      contextId,
      type,
      bias: bias,
      blend,
    },
    // responseType: "blob"
  };

  const { error, result } = yield call(HttpClient, payload, true, true);

  if (error) {
    yield put(GenerateContextMapFailure(error));
    Toaster({ message: `Failed to generate context ${type}map`, type: "error" });
  } else {
    yield put(GenerateContextMapSuccess({ result, indexId, type }));
  }
  if (callback) callback(error, result);

  return { error, result };
}

function* Processing() {
  yield all([takeEvery(GENERATE_MAP, GenerateMap), takeEvery(GENERATE_CONTEXT_MAP, GenerateContextMap)]);
}

export default Processing;
