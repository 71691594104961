import React from "react"
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner as falSpinner } from "@fortawesome/pro-light-svg-icons";

const Loader = ( show ) => {
  const visible = useSelector((state) => state.file?.visible) || show;
  return (
    <div className="loader">
      {
        visible
          ? (
          <div className="p-5 rounded text-center mb-3">
            <div className="py-5">
            <span className="d-inline-block m-auto mb-3 text-center d-flex align-items-center justify-content-center text-dark border-dark border-3 op-20 folder-icon">
              <FontAwesomeIcon icon={falSpinner} spin size="3x"/>
            </span>
            </div>
          </div>
        )
          : null
      }
    </div>

  )
};

export default Loader;
