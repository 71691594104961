import { createAction } from "redux-actions";

// Create Project
export const CREATE_PROJECT = "CREATE_PROJECT";
export const CreateProject = createAction(CREATE_PROJECT);
export const CREATE_PROJECT_REQUESTED = "CREATE_PROJECT_REQUESTED";
export const CreateProjectRequested = createAction(CREATE_PROJECT_REQUESTED);
export const CREATE_PROJECT_FAILURE = "CREATE_PROJECT_FAILURE";
export const CreateProjectFailure = createAction(CREATE_PROJECT_FAILURE);
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CreateProjectSuccess = createAction(CREATE_PROJECT_SUCCESS);

// Update Project
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UpdateProject = createAction(UPDATE_PROJECT);
export const UPDATE_PROJECT_REQUESTED = "UPDATE_PROJECT_REQUESTED";
export const UpdateProjectRequested = createAction(UPDATE_PROJECT_REQUESTED);
export const UPDATE_PROJECT_FAILURE = "UPDATE_PROJECT_FAILURE";
export const UpdateProjectFailure = createAction(UPDATE_PROJECT_FAILURE);
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UpdateProjectSuccess = createAction(UPDATE_PROJECT_SUCCESS);

// DeleteProject
export const DELETE_PROJECT = "DELETE_PROJECT";
export const DeleteProject = createAction(DELETE_PROJECT);
export const DELETE_PROJECT_REQUESTED = "DELETE_PROJECT_REQUESTED";
export const DeleteProjectRequested = createAction(DELETE_PROJECT_REQUESTED);
export const DELETE_PROJECT_FAILURE = "DELETE_PROJECT_FAILURE";
export const DeleteProjectFailure = createAction(DELETE_PROJECT_FAILURE);
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DeleteProjectSuccess = createAction(DELETE_PROJECT_SUCCESS);

// GetProject
export const GET_PROJECT = "GET_PROJECT";
export const GetProject = createAction(GET_PROJECT);
export const GET_PROJECT_REQUESTED = "GET_PROJECT_REQUESTED";
export const GetProjectRequested = createAction(GET_PROJECT_REQUESTED);
export const GET_PROJECT_FAILURE = "GET_PROJECT_FAILURE";
export const GetProjectFailure = createAction(GET_PROJECT_FAILURE);
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";
export const GetProjectSuccess = createAction(GET_PROJECT_SUCCESS);

// GetAllProjects
export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS";
export const GetAllProjects = createAction(GET_ALL_PROJECTS);
export const GET_ALL_PROJECTS_REQUESTED = "GET_ALL_PROJECTS_REQUESTED";
export const GetAllProjectsRequested = createAction(GET_ALL_PROJECTS_REQUESTED);
export const GET_ALL_PROJECTS_FAILURE = "GET_ALL_PROJECTS_FAILURE";
export const GetAllProjectsFailure = createAction(GET_ALL_PROJECTS_FAILURE);
export const GET_ALL_PROJECTS_SUCCESS = "GET_ALL_PROJECTS_SUCCESS";
export const GetAllProjectsSuccess = createAction(GET_ALL_PROJECTS_SUCCESS);

// GetSearchProjects
export const GET_SEARCH_PROJECTS = "GET_SEARCH_PROJECTS";
export const GetSearchProjects = createAction(GET_SEARCH_PROJECTS);
export const GET_SEARCH_PROJECTS_REQUESTED = "GET_SEARCH_PROJECTS_REQUESTED";
export const GetSearchProjectsRequested = createAction(GET_SEARCH_PROJECTS_REQUESTED);
export const GET_SEARCH_PROJECTS_FAILURE = "GET_SEARCH_PROJECTS_FAILURE";
export const GetSearchProjectsFailure = createAction(GET_SEARCH_PROJECTS_FAILURE);
export const GET_SEARCH_PROJECTS_SUCCESS = "GET_SEARCH_PROJECTS_SUCCESS";
export const GetSearchProjectsSuccess = createAction(GET_SEARCH_PROJECTS_SUCCESS);

// CreateStudy
export const CREATE_STUDY = "CREATE_STUDY";
export const CreateStudy = createAction(CREATE_STUDY);
export const CREATE_STUDY_REQUESTED = "CREATE_STUDY_REQUESTED";
export const CreateStudyRequested = createAction(CREATE_STUDY_REQUESTED);
export const CREATE_STUDY_FAILURE = "CREATE_STUDY_FAILURE";
export const CreateStudyFailure = createAction(CREATE_STUDY_FAILURE);
export const CREATE_STUDY_SUCCESS = "CREATE_STUDY_SUCCESS";
export const CreateStudySuccess = createAction(CREATE_STUDY_SUCCESS);

// GetStudy
export const GET_STUDY = "GET_STUDY";
export const GetStudy = createAction(GET_STUDY);
export const GET_STUDY_REQUESTED = "GET_STUDY_REQUESTED";
export const GetStudyRequested = createAction(GET_STUDY_REQUESTED);
export const GET_STUDY_FAILURE = "GET_STUDY_FAILURE";
export const GetStudyFailure = createAction(GET_STUDY_FAILURE);
export const GET_STUDY_SUCCESS = "GET_STUDY_SUCCESS";
export const GetStudySuccess = createAction(GET_STUDY_SUCCESS);

// DeleteStudy
export const DELETE_STUDY = "DELETE_STUDY";
export const DeleteStudy = createAction(DELETE_STUDY);
export const DELETE_STUDY_REQUESTED = "DELETE_STUDY_REQUESTED";
export const DeleteStudyRequested = createAction(DELETE_STUDY_REQUESTED);
export const DELETE_STUDY_FAILURE = "DELETE_STUDY_FAILURE";
export const DeleteStudyFailure = createAction(DELETE_STUDY_FAILURE);
export const DELETE_STUDY_SUCCESS = "DELETE_STUDY_SUCCESS";
export const DeleteStudySuccess = createAction(DELETE_STUDY_SUCCESS);

// GetAllStudies
export const GET_ALL_STUDIES = "GET_ALL_STUDIES";
export const GetAllStudies = createAction(GET_ALL_STUDIES);
export const GET_ALL_STUDIES_REQUESTED = "GET_ALL_STUDIES_REQUESTED";
export const GetAllStudiesRequested = createAction(GET_ALL_STUDIES_REQUESTED);
export const GET_ALL_STUDIES_FAILURE = "GET_ALL_STUDIES_FAILURE";
export const GetAllStudiesFailure = createAction(GET_ALL_STUDIES_FAILURE);
export const GET_ALL_STUDIES_SUCCESS = "GET_ALL_STUDIES_SUCCESS";
export const GetAllStudiesSuccess = createAction(GET_ALL_STUDIES_SUCCESS);

// Update Study
export const UPDATE_STUDY = "UPDATE_STUDY";
export const UpdateStudy = createAction(UPDATE_STUDY);
export const UPDATE_STUDY_REQUESTED = "UPDATE_STUDY_REQUESTED";
export const UpdateStudyRequested = createAction(UPDATE_STUDY_REQUESTED);
export const UPDATE_STUDY_FAILURE = "UPDATE_STUDY_FAILURE";
export const UpdateStudyFailure = createAction(UPDATE_STUDY_FAILURE);
export const UPDATE_STUDY_SUCCESS = "UPDATE_STUDY_SUCCESS";
export const UpdateStudySuccess = createAction(UPDATE_STUDY_SUCCESS);

// Move Study
export const MOVE_STUDY = "MOVE_STUDY";
export const MoveStudy = createAction(MOVE_STUDY);
export const MOVE_STUDY_REQUESTED = "MOVE_STUDY_REQUESTED";
export const MoveStudyRequested = createAction(MOVE_STUDY_REQUESTED);
export const MOVE_STUDY_FAILURE = "MOVE_STUDY_FAILURE";
export const MoveStudyFailure = createAction(MOVE_STUDY_FAILURE);
export const MOVE_STUDY_SUCCESS = "MOVE_STUDY_SUCCESS";
export const MoveStudySuccess = createAction(MOVE_STUDY_SUCCESS);

// Update Media Study
export const UPDATE_MEDIA_STUDY = "UPDATE_MEDIA_STUDY";
export const UpdateMediaStudy = createAction(UPDATE_MEDIA_STUDY);
export const UPDATE_MEDIA_STUDY_REQUESTED = "UPDATE_MEDIA_STUDY_REQUESTED";
export const UpdateMediaStudyRequested = createAction(UPDATE_MEDIA_STUDY_REQUESTED);
export const UPDATE_MEDIA_STUDY_FAILURE = "UPDATE_MEDIA_STUDY_FAILURE";
export const UpdateMediaStudyFailure = createAction(UPDATE_MEDIA_STUDY_FAILURE);
export const UPDATE_MEDIA_STUDY_SUCCESS = "UPDATE_MEDIA_STUDY_SUCCESS";
export const UpdateMediaStudySuccess = createAction(UPDATE_MEDIA_STUDY_SUCCESS);

// Update Media Study
export const UPDATE_SETTING = "UPDATE_SETTING";
export const UpdateSetting = createAction(UPDATE_SETTING);
export const UPDATE_SETTING_REQUESTED = "UPDATE_SETTING_REQUESTED";
export const UpdateSettingRequested = createAction(UPDATE_SETTING_REQUESTED);
export const UPDATE_SETTING_FAILURE = "UPDATE_SETTING_FAILURE";
export const UpdateSettingFailure = createAction(UPDATE_SETTING_FAILURE);
export const UPDATE_SETTING_SUCCESS = "UPDATE_SETTING_SUCCESS";
export const UpdateSettingSuccess = createAction(UPDATE_SETTING_SUCCESS);

// Update Project Filters
export const PROJECT_FILTERS = "PROJECT_FILTERS";
export const ProjectFilters = createAction(PROJECT_FILTERS);
export const PROJECT_FILTERS_REQUESTED = "PROJECT_FILTERS_REQUESTED";
export const ProjectFiltersRequested = createAction(PROJECT_FILTERS_REQUESTED);
export const PROJECT_FILTERS_FAILURE = "PROJECT_FILTERS_FAILURE";
export const ProjectFiltersFailure = createAction(PROJECT_FILTERS_FAILURE);
export const PROJECT_FILTERS_SUCCESS = "PROJECT_FILTERS_SUCCESS";
export const ProjectFiltersSuccess = createAction(PROJECT_FILTERS_SUCCESS);

// Update Study Filters
export const STUDY_FILTERS = "STUDY_FILTERS";
export const StudyFilters = createAction(STUDY_FILTERS);
export const STUDY_FILTERS_REQUESTED = "STUDY_FILTERS_REQUESTED";
export const StudyFiltersRequested = createAction(STUDY_FILTERS_REQUESTED);
export const STUDY_FILTERS_FAILURE = "STUDY_FILTERS_FAILURE";
export const StudyFiltersFailure = createAction(STUDY_FILTERS_FAILURE);
export const STUDY_FILTERS_SUCCESS = "STUDY_FILTERS_SUCCESS";
export const StudyFiltersSuccess = createAction(STUDY_FILTERS_SUCCESS);
