import { all, call, put, takeEvery } from "redux-saga/effects";
import { Toaster } from "../../components/Toast";
import HttpClient from "../../config/http-client";
import {
  UPLOAD_CONTEXT,
  UploadContextFailure,
  UploadContextSuccess,
  UploadContextRequested,
  GET_CONTEXT_MEDIA,
  GetContextMediaSuccess,
  GetContextMediaRequested,
  GetContextMediaFailure,
  DeleteContextFailure,
  DeleteContextRequested,
  DeleteContextSuccess,
  DELETE_CONTEXT,
  CALCULATE_INCONTEXT_SCORE,
  CalculateInContextScoreFailure,
  CalculateInContextScoreRequested,
  CalculateInContextScoreSuccess,
} from "../actions/context-action-types";
import { preSignedAPIFlow } from "@/utilities/preSignedAPIFlow";
import { deepCopy } from "@/utilities/genericFunctions";

export function* UploadContext({
  payload: { image, filename, dropzone, dropzoneBackground, useCaseId, includeInLibrary, callback },
}) {
  const tempId = image.name + Date.now();
  yield put(UploadContextRequested({ tempId }));

  try {
    const data = {
      filename,
      name: filename,
      dropzone: dropzone,
      dropzoneBackground,
      useCaseId,
      includeInLibrary,
    };
    const { result } = yield preSignedAPIFlow(image, "/web/contexts/upload/url", "/web/contexts/upload/process", data);

    if (callback) {
      let response = deepCopy(result);
      callback(null, response);
    }
    yield put(UploadContextSuccess({ api: result, tempId }));
    Toaster({ message: "Upload context success" });
    return { error: null, result: result };
  } catch (error) {
    if (error && error.response.status === 401) {
      yield put(CalculateInContextScoreFailure(error));
      Toaster({ message: "You do not have this feature enabled - please contact your Account Manager", type: "error" });
    } else {
      yield put(UploadContextFailure({ error, tempId }));
      Toaster({ message: "Failed to upload context", type: "error" });
    }
    if (callback) {
      callback(error, null);
    }
    return { error, result: null };
  }
}

export function* GetContextMedia({ payload: { contextIDs, itemsPerPage, callback } }) {
  yield put(GetContextMediaRequested());

  /* 
  
  ContextIDs is an array of context IDs, we will pass them through as query params using the same param name of ids
  For examples: ?ids=13503d0f-dda1-4414-a78a-2ff15e7df4ae&ids=fff35bee-f3ed-432e-bacf-76b98e81b514
  The endpoint will then return the media response for both contextID's in an array
  
  */
  const payload = {
    method: "get",
    url: `/web/contexts?ids=${contextIDs}&itemsPerPage=${itemsPerPage}`,
  };

  const { error, result } = yield call(HttpClient, payload);

  if (error && error.response.status === 401) {
    yield put(CalculateInContextScoreFailure(error));
    Toaster({ message: "You do not have this feature enabled - please contact your Account Manager", type: "error" });
  } else if (error) {
    yield put(GetContextMediaFailure(error));
    Toaster({ message: "Failed to get context media", type: "error" });
  } else {
    yield put(GetContextMediaSuccess(result));
  }
  if (callback) callback(error, result);
  return { error, result };
}

export function* DeleteContext({ payload: { key, callback } }) {
  yield put(DeleteContextRequested());

  const payload = {
    method: "delete",
    url: `/web/contexts/${key}`,
  };

  const { error, result } = yield call(HttpClient, payload);

  if (error) {
    yield put(DeleteContextFailure(error));
    Toaster({ message: "Failed to delete context", type: "error" });
  } else {
    yield put(DeleteContextSuccess({ key }));
    Toaster({ message: "Delete context success" });
  }
  if (callback) callback(error, result);
  return { error, result };
}

export function* CalculateInContextScore({ payload: { key, contextId, bias, callback } }) {
  yield put(CalculateInContextScoreRequested());

  let data = {
    key,
    contextId,
    bias,
  };

  const payload = {
    method: "post",
    url: "/web/images/contexts",
    data,
  };

  const { error, result } = yield call(HttpClient, payload, true, true);

  if (error && error.response.status === 401) {
    yield put(CalculateInContextScoreFailure(error));
    Toaster({ message: "You do not have this feature enabled - please contact your Account Manager", type: "error" });
  } else if (error) {
    yield put(CalculateInContextScoreFailure(error));
    Toaster({ message: "Failed to calculate incontext score", type: "error" });
  } else {
    yield put(CalculateInContextScoreSuccess({ result }));
  }
  if (callback) callback(error, { contextPerformance: result, contextId });

  return { error, result };
}

function* Context() {
  yield all([
    takeEvery(UPLOAD_CONTEXT, UploadContext),
    takeEvery(GET_CONTEXT_MEDIA, GetContextMedia),
    takeEvery(DELETE_CONTEXT, DeleteContext),
    takeEvery(CALCULATE_INCONTEXT_SCORE, CalculateInContextScore),
  ]);
}

export default Context;
