import { all, call, put, takeEvery } from "redux-saga/effects";
import { Toaster } from "../../components/Toast";
import HttpClient from "../../config/http-client";
import {
  GenerateGridFailure,
  GenerateGridSuccess,
  GenerateGridRequested,
  GENERATE_GRIDS
} from "../actions/grids-action-types";

export function* GenerateGrid({ payload: { key, scale, indexId, type, bias, callback } }) {
  yield put(GenerateGridRequested());

  let data = {
    key,
    scale,
    bias,
    "type": "local",
  }

  const payload = {
    method: "post",
    url: "/web/images/grids",
    data
  };

  const { error, result } = yield call(HttpClient, payload, true, true);

  if (error) {
    yield put(GenerateGridFailure(error));
    Toaster({ message:"Failed to generate grid", type:"error" });
  } else {
    yield put(GenerateGridSuccess({ result, indexId, type }));
  }
  if (callback) callback(error, result);

  return { error, result };
}

function* Grid() {
  yield all([
    takeEvery(GENERATE_GRIDS, GenerateGrid),
  ]);
}

export default Grid;
