import React, { useEffect, useState, Fragment } from "react";
import { ReactSortable } from "react-sortablejs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDrawSquare } from "@fortawesome/pro-regular-svg-icons";
import { faTimes as falTimes } from "@fortawesome/pro-light-svg-icons";
import { faSitemap as fasSitemap } from "@fortawesome/pro-solid-svg-icons";
import { segmentAnalytics, deepCopy } from "../../utilities/genericFunctions";

function VisualHierarchyModal({ hide, labelList, activeReport, session, projectId, studyId }) {
  const initialList = activeReport.settings.targetHierarchy && activeReport.settings.targetHierarchy.length ? deepCopy(activeReport.settings.targetHierarchy) : [ { labels:[], index: 1 }, { labels:[], index: 2 }, { labels:[], index: 3 } ];

  const [ labels, setLabels ] = useState(labelList);
  const [ priorityLevel, setPriorityLevel ] = useState(initialList.length);
  const [ priorityLevelList, setPriorityLevelList ] = useState(initialList);

  /**
   * Updating the left side AOI label list w.r.t priority list
   **/
  useEffect(() => {
    let filteredArray= [];
    if(activeReport.settings.targetHierarchy && activeReport.settings.targetHierarchy.length) {
      activeReport.settings.targetHierarchy.forEach((hierarchy, listIndex) => {
        hierarchy.labels.forEach((label, index) => {
          filteredArray.push(label.valueOf());
        })
      });
    }
    filteredArray = labelList.filter(label => filteredArray.indexOf(label) == -1);

    setLabels(filteredArray);
  }, []);


  /**
   * Displaying the number of priority drop area on change of dropdown numbers
   **/
  const changePriorityLevel = (value) => {

    /** Segment track event for Visual Hierarchy Priority Count Changed */
    segmentAnalytics({ page: false, session, name: "Visual Hierarchy Priority Count Changed", segmentData:{
      reportType: activeReport.type,
      projectId: projectId,
      studyId: studyId,
      reportId: activeReport.id } });

    if(priorityLevel > value) {
      let priorityLabelsList = [ ...priorityLevelList ];
      [ ...Array(priorityLevel) ].map((values, index)=> {
        if(value <= index) {
          priorityLabelsList[value-1].labels = [ ...priorityLabelsList[value-1].labels, ...priorityLabelsList[index].labels ];
        }
      });

      priorityLabelsList = priorityLabelsList.splice(0, value);
      setPriorityLevelList(priorityLabelsList);
    } else {
      let priorityList = [ ...priorityLevelList ];
      [ ...Array(value) ].map((value, index)=> {
        if(priorityLevel <= index) {
          priorityList.push({ labels: [], index: index + 1 });
        }
      });
      setPriorityLevelList(priorityList);
    }
    setPriorityLevel(value);
  };

  const onSubmit = () => {
    let emptyList = 0;
    priorityLevelList.forEach(level => {
      if(level.labels && level.labels.length) {
        emptyList = 1;
      }
    });
    const targetHierarchy = emptyList ? priorityLevelList : [];
    hide(targetHierarchy);
  };

  const priorityAssigned = (from, to) => {
    let labelTo = to.slice(-1);
    let labelFrom = from.slice(-1);
    /** Segment track event for AOI label priority assigned */
    segmentAnalytics({ page: false, session, name: "AOI label priority assigned", segmentData:{
      from : labelFrom == 0 ? "-1" : labelFrom,
      to : labelTo == 0 ? "-1" : labelTo,
      reportType: activeReport.type,
      projectId: projectId,
      studyId: studyId,
      reportId: activeReport.id } });
  };

  const clearLabels= ()=> {
    let priorityList = [];
    setLabels(labelList);
    [ ...Array(priorityLevel) ].map((value, index)=> {
      priorityList.push({ labels:[], index: index+1 });
    });
    setPriorityLevelList(priorityList);
  };

  return (
    <div>
      <div className="modal-header border-darken-15 bg-white">
        <h5 className="modal-title fw-light" id="exampleModalLabel">
          Visual hierarchy
        </h5>
        <button type="button" className="btn btn-link text-black-50 close" onClick={() => hide(null)}>
          <FontAwesomeIcon icon={falTimes} size="lg" className="mt-1" />
        </button>
      </div>
      <div className="modal-body">
        <div className="">
          <header className="text-center px-4">
            <p className="fs-xs text-muted readable-md mx-auto">A clear visual hierarchy helps viewers know where to focus their attention and ensures they are guided towards consuming each design element in the order of intended importance. <a href="https://academy.dragonflyai.co/en/articles/5605679-areas-of-interest" target="_blank" rel="noreferrer">Learn more</a></p>
          </header>
        </div>


        <div className="container border bg-white rounded">
          <div className="row">
            <div className="col p-3 border-end">
              <header className="mb-1">
                <h6 className="fs-xs fw-700 text-uppercase mb-1 pt-1">Areas of Interest</h6>
                <p className="fs-xs text-muted mb-2">Drag & drop to assign AOIs to priority tiers.</p>
              </header>
              <ReactSortable
                list={labels}
                setList={(newState) => setLabels(newState)}
                animation={150}
                group="shared"
                ghostClass="hide-element"
                onChange={(evt) => {
                  const priorityLists=document.querySelector(".priority-lists");
                  const listFound=priorityLists.querySelectorAll(".static");
                  listFound.forEach(list=> {
                    list.style.setProperty("visibility", "visible", "important");
                  });
                }}
                onEnd={evt => {
                  if(evt.from.id !== evt.to.id) {
                    priorityAssigned(evt.from.id, evt.to.id);
                  }
                }}
                id="sortable0"
                className="connectedSortable py-1 px-2 border rounded bg-darken-5 mb-3 mh-49 areainterest-scroll">
                { labels && labels.length > 0 && labels.map((label, labelId) =>
                  <div className="ui-state-default bg-white border rounded fs-sm my-1 py-1 px-2 cs-grab" key={labelId}>
                    <FontAwesomeIcon icon={faDrawSquare} fixedWidth className="text-primary"/> {label}
                  </div>
                )}
              </ReactSortable>
            </div>
            <div className="col p-3 priority-lists">
              <div className="mb-3">
                <div className="row">
                  <a className="col-sm-3 fs-xs fw-700 text-dark" href="#" onClick={e=> {e.preventDefault(); clearLabels()}}>Clear</a>
                  <label htmlFor="colFormLabelSm" className="col-sm-5 pe-2 col-form-label col-form-label-sm text-end fw-700 text-uppercase fs-xs">Priority levels</label>
                  <div className="col-sm-4 ps-0">
                    <select defaultValue={priorityLevel} className="form-select form-select-sm fs-xs" onChange={(e) => changePriorityLevel(Number(e.target.value))}>
                      { [ ...Array(5) ].map(( value, index ) => (
                        <option key={index+1} value={ index+1 }>
                          { index+1 }
                        </option>
                      ))}
                    </select>

                  </div>
                </div>
              </div>
              <div className="pro-scroll">
              {priorityLevelList.map(( value, levelIndex ) =>
              <Fragment key={levelIndex}>
              <header className="mb-1">
                <h6 className="fs-xs fw-700 text-uppercase mb-1"><FontAwesomeIcon icon={fasSitemap} fixedWidth /> { `Priority ${value.index}` }</h6>
                <p className="fs-xs text-muted mb-2">I want my audience to see these AOIs {value.index}</p>
              </header>
                <ReactSortable
                  list={value.labels}
                  group="shared"
                  filter=".static"
                  ghostClass="hide-element"
                  setList={(newState) => {
                    setPriorityLevelList((sourceList) => {
                      const tempList = [ ...sourceList ];
                      const _blockIndex = [ levelIndex ];
                      const lastIndex = _blockIndex.pop();
                      const lastArr = _blockIndex.reduce(
                        (arr, i) => arr[i]["labels"],
                        tempList
                      );
                      lastArr[lastIndex]["labels"] = newState;
                      return tempList;
                    });
                  }
                  }
                  animation={150}
                  onChange={(evt) => {
                    let selectedDiv;
                    const priorityLists=document.querySelector(".priority-lists");
                    const listFound=priorityLists.querySelectorAll(".static");
                    listFound.forEach(list=> {
                      list.style.setProperty("visibility", "visible", "important");
                    });
                    if(!(value.labels && value.labels.length)){
                      selectedDiv = document.querySelector(`#${evt.to.id} .static`);
                      if(selectedDiv) {
                        selectedDiv.style.setProperty("visibility", "hidden", "important");
                      }
                    }
                  }}
                  onEnd={evt => {
                    if(evt.from.id !== evt.to.id) {
                      priorityAssigned(evt.from.id, evt.to.id);
                    }
                  }}
                  id={`sortable${value.index}`}
                  className="connectedSortable py-1 px-2 border border border-darken-10 rounded bg-darken-5 mb-3 mh-49">
                  { value.labels && value.labels.length > 0 ? value.labels.map((label, labelId) =>
                    <div className="ui-state-default bg-white border rounded fs-sm my-1 py-1 px-2 cs-grab" key={labelId}>
                      <FontAwesomeIcon icon={faDrawSquare} fixedWidth className="text-primary" /> {label}
                    </div>
                  ) : <div className="text-center d-flex align-items-center justify-content-center h-39 static">
                    <span className="fs-xs op-50">Drop here to assign</span>
                  </div>
                  }
                </ReactSortable>
              </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="modal-footer border-darken-15 bg-white">
        <button type="button" className="btn btn-link text-muted" onClick={() => hide(null)}>
          Cancel
        </button>
        <button
          className="btn btn-primary" onClick={e=>onSubmit(e)} >Save changes</button>
      </div>
    </div>
  );
}

export default VisualHierarchyModal;
