import LogRocket from "logrocket";

export const ConfigureLogrocket = (user) => {

  /* childDomains needed for auth iframe to work */
  if ([ "prod", "staging", "dev" ].includes(process.env.REACT_APP_ENV)) {
    LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID, {
      mergeIframes: true,
      childDomains: [ "https://dev.id.dragonflyai.co","https://staging.id.dragonflyai.co","https://id.dragonflyai.co" ],
      network: {
        isEnabled: false
      },
    });

    if (user) {

      /**
       * Identifying your users helps you find you find their sessions in the LogRocket dashboard.
       * If the name or email keys are given they will be displayed instead of the UID in the UI. 
       * They can also be used in the search box to quickly find user sessions:
       */

      LogRocket.identify(user.id, {
        name: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName,
        email: user.email,
      });
    }
  }
}



export default ConfigureLogrocket;



