import { Toaster } from "@/components/Toast";
import { api } from "@/redux/toolkit/apiSlice";
import { generateQueryParam } from "../utils/generateQueryParam";

export const extendedApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    // Library endpoints
    getAllContexts: builder.query({
      query: ({ query, view, pageIndex, itemsPerPage, orderby, sortby, useCaseIds }) => {
        if (useCaseIds && useCaseIds.length > 0) {
          const useCaseArrayString = generateQueryParam(useCaseIds, "useCaseIds");
          return `/web/contexts?query=${query}&view=${view}&orderBy=${orderby}&sortBy=${sortby}&pageIndex=${pageIndex}&itemsPerPage=${itemsPerPage}&includeInLibrary=true${useCaseArrayString}`;
        }
        return `/web/contexts?query=${query}&view=${view}&orderBy=${orderby}&sortBy=${sortby}&pageIndex=${pageIndex}&itemsPerPage=${itemsPerPage}&includeInLibrary=true`;
      },
      providesTags: ["Contexts"],
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          // if there's an error show a toast
          Toaster({ message: "Failed to fetch all contexts", type: "error" });
        }
      },
      transformResponse: (response) => {
        return {
          contexts: response.payload.contexts,
          maxPageIndex: response.payload.maxPageIndex,
          maxRecordCount: response.payload.maxRecordCount,
          pageIndex: response.payload.pageIndex,
        };
      },
    }),
    updateContext: builder.mutation({
      query: ({ data, contextId }) => ({
        url: `/web/contexts/${contextId}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Contexts"],
    }),
    getAllContextPacks: builder.query({
      query: ({ query, view, pageIndex, itemsPerPage, orderby, sortby, useCaseIds, contextPackId }) => {
        if (useCaseIds && useCaseIds.length > 0) {
          const useCaseArrayString = generateQueryParam(useCaseIds, "useCaseIds");
          return `/web/contexts/packs?query=${query}&view=${view}&orderBy=${orderby}&sortBy=${sortby}&pageIndex=${pageIndex}&itemsPerPage=${itemsPerPage}${
            contextPackId ? "&ids=" + contextPackId : ""
          }${useCaseArrayString}`;
        }
        return `/web/contexts/packs?query=${query}&view=${view}&orderBy=${orderby}&sortBy=${sortby}&pageIndex=${pageIndex}&itemsPerPage=${itemsPerPage}${
          contextPackId ? "&ids=" + contextPackId : ""
        }`;
      },
      providesTags: ["ContextPacks"],
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          // if there's an error show a toast
          Toaster({ message: "Failed to fetch all context packs", type: "error" });
        }
      },
      transformResponse: (response, meta, arg) => {
        const filterPack = arg.contextPackId
          ? response.payload.contextPacks.filter((contextPack) => contextPack.id === arg.contextPackId)
          : [];

        const filteredPacksContexts = filterPack.length > 0 ? filterPack[0] : [];

        return {
          contextPacks: response.payload.contextPacks,
          filteredPack: filteredPacksContexts,
          maxPageIndex: response.payload.maxPageIndex,
          maxRecordCount: response.payload.maxRecordCount,
          pageIndex: response.payload.pageIndex,
        };
      },
    }),
    createContextPack: builder.mutation({
      query: ({ data }) => ({
        url: "/web/contexts/packs",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ContextPacks"],
    }),
    addToContextPack: builder.mutation({
      query: ({ data, contextPackId, contextId }) => ({
        url: `/web/contexts/packs/${{ contextPackId }}/contexts/${{ contextId }}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ContextPacks"],
    }),
    updateContextPack: builder.mutation({
      query: ({ data, contextPackId, cardId }) => ({
        url: `/web/contexts/packs/${contextPackId || cardId}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["ContextPacks"],
    }),
    getContextScoresAgainstImage: builder.query({
      query: ({ contextPackId, imageId }) => `/web/contexts/packs/${contextPackId}/imageScores/${imageId}`,
    }),
    /* Benchmarks */
    getAllBenchmarks: builder.query({
      query: ({ query, view, pageIndex, itemsPerPage, orderby, sortby, useCaseIds, benchmarkId }) => {
        if (useCaseIds && useCaseIds.length > 0) {
          const useCaseArrayString = generateQueryParam(useCaseIds, "useCaseIds");
          return `/web/benchmarks?query=${query}&view=${view}&orderBy=${orderby}&sortBy=${sortby}&pageIndex=${pageIndex}&itemsPerPage=${itemsPerPage}${
            benchmarkId ? "&ids=" + benchmarkId : ""
          }${useCaseArrayString}`;
        }
        return `/web/benchmarks?query=${query}&view=${view}&orderBy=${orderby}&sortBy=${sortby}&pageIndex=${pageIndex}&itemsPerPage=${itemsPerPage}${
          benchmarkId ? "&ids=" + benchmarkId : ""
        }`;
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: ["Benchmarks"],
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          // if there's an error show a toast
          Toaster({ message: "Failed to fetch all benchmarks", type: "error" });
        }
      },
      transformResponse: (response, meta, arg) => {
        return {
          benchmarks: response.payload.benchmarks,
          maxPageIndex: response.payload.maxPageIndex,
          maxRecordCount: response.payload.maxRecordCount,
          pageIndex: response.payload.pageIndex,
        };
      },
    }),
    addPreSignedBenchmarkAsset: builder.mutation({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBaseQuery) {
        // Get the pre-signed URL
        const getPreSignedURL = await fetchWithBaseQuery("/web/benchmarks/assets/upload/url");
        if (getPreSignedURL.error) return { error: getPreSignedURL.error };
        const preSignedURL = getPreSignedURL.data.url;
        // Upload the file to S3
        const uploadToS3 = await fetchWithBaseQuery({
          url: preSignedURL,
          method: "PUT",
          body: _arg.file,
          headers: {
            "Content-Type": _arg.file.type,
          },
        });
        if (uploadToS3.error) return { error: uploadToS3.error };
        // Process the asset
        const processAsset = await fetchWithBaseQuery({
          url: "/web/benchmarks/assets/upload/process",
          method: "POST",
          body: {
            key: getPreSignedURL.data.key,
            name: _arg.filename,
            filename: _arg.filename,
            benchmarkId: _arg.benchmarkId,
            useCaseId: _arg.useCaseId,
          },
        });
        return processAsset.data ? { data: processAsset.data } : { error: processAsset.error };
      },
      invalidatesTags: ["Benchmarks"],
    }),
    addBenchmarkAsset: builder.mutation({
      query: ({ file, filename, name, benchmarkId, useCaseId }) => {
        let bodyFormData = new FormData();
        bodyFormData.append("file", file);
        bodyFormData.append("filename", filename);
        bodyFormData.append("name", filename); // Currently no way to change the name in the UI so map it to filename
        bodyFormData.append("benchmarkId", benchmarkId);
        bodyFormData.append("useCaseId", useCaseId);
        return {
          url: "/web/benchmarks/assets",
          method: "POST",
          body: bodyFormData,
          formData: true,
        };
      },
      invalidatesTags: ["Benchmarks"],
    }),
    createBenchmark: builder.mutation({
      query: ({ data }) => ({
        url: "/web/benchmarks",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Benchmarks"],
    }),
    deleteBenchmarkAsset: builder.mutation({
      query: ({ benchmarkId, assetId }) => ({
        url: `/web/benchmarks/${benchmarkId}/assets/${assetId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Benchmarks"],
    }),
    updateBenchmark: builder.mutation({
      query: ({ data, benchmarkId, cardId }) => ({
        url: `/web/benchmarks/${benchmarkId || cardId}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Benchmarks"],
    }),
    getBenchmarkContextPackScores: builder.query({
      query: ({ benchmarkId, contextPackId }) => `/web/benchmarks/${benchmarkId}/contextpacks/${contextPackId}/scores`,
    }),
    getBenchmarkContextPackScoresAgainstContext: builder.query({
      query: ({ benchmarkId, contextPackId, contextId }) =>
        `/web/benchmarks/${benchmarkId}/contextpacks/${contextPackId}/contexts/${contextId}/scores`,
    }),
  }),
});

export const {
  useGetAllContextsQuery,
  useGetAllContextPacksQuery,
  useGetAllBenchmarksQuery,
  useCreateBenchmarkMutation,
  useAddBenchmarkAssetMutation,
  useCreateContextPackMutation,
  useAddToContextPackMutation,
  useUpdateContextPackMutation,
  useUpdateBenchmarkMutation,
  useUpdateContextMutation,
  useLazyGetContextScoresAgainstImageQuery,
  useLazyGetBenchmarkContextPackScoresQuery,
  useLazyGetBenchmarkContextPackScoresAgainstContextQuery,
  useDeleteBenchmarkAssetMutation,
  useAddPreSignedBenchmarkAssetMutation,
} = extendedApiSlice;
