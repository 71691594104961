import { useEffect } from "react";

export const useSignoutUser = () => {
  /*
    This hook will sign out the user by redirecting to the authentication iframe's signout route.
    This is because we need to get rid of the SSO session as well but if we routed inside the auth iframe
    it wouldn't work.
  */
  useEffect(() => {
    window.location.replace(`${process.env.REACT_APP_AUTHENTICATION_IFRAME_URL}/signout`);
  }, []);
};
