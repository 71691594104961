import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation as falTriangleExclamation } from "@fortawesome/pro-light-svg-icons";

const ErrorFallback = () => {

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div className="d-flex flex-column min-vh-100 min-vw-100">
      <div className="d-flex flex-grow-1 justify-content-center align-items-center">
        <div className="text-center">
          <div className="py-4">
            <span className="d-inline-block m-auto mb-3 op-20 border rounded-circle text-center d-flex align-items-center justify-content-center text-dark border-dark border-3 icon-size5">
              <FontAwesomeIcon icon={falTriangleExclamation} size="3x"/>
            </span>
            <p className="lead fw-400">Oops, something went wrong!</p>
            <p className="fs-sm text-muted">Try <span className="cursor-pointer btn-link" onClick={()=>refreshPage()}>refreshing the page</span>. If the problem persists, please contact support via <a href="mailto:support@dragonflyai.co">support@dragonflyai.co</a></p>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ErrorFallback;