import {
  faAngleDown,
  faCalendarAlt,
  faLongArrowDown,
  faLongArrowUp,
  faTag,
  faUserCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup, Dropdown, Form } from "react-bootstrap";

export const SortDropdown = ({ filterDisplay, filterState, orderChange, sortChange }) => {
  /* 
        filterDisplay contains the display names for the sort options
        e.g. { name: "Name", createdat: "Created", owner: "Owner" }
    */

  // icons to be used for each sort option - filterDisplay keys determine usage
  const sortIcons = { name: faTag, createdat: faCalendarAlt, owner: faUserCircle };

  return (
    <Form className="d-flex ms-auto ms-lg-0 me-lg-2">
      <ButtonGroup>
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle
            variant="light"
            className="bg-white border border-form-control controlled-toggle"
            style={{ borderTopRightRadius: "0", borderBottomRightRadius: "0" }}
          >
            {filterDisplay[filterState?.orderby]}
            <FontAwesomeIcon icon={faAngleDown} className="ms-2" fixedWidth />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Header>Sort by</Dropdown.Header>
            {Object.keys(filterDisplay).map((item, i) => (
              <Dropdown.Item key={`sort-${i}`} onClick={() => orderChange(item)}>
                <FontAwesomeIcon icon={sortIcons[item]} className="me-1" fixedWidth /> {filterDisplay[item]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Button
          variant="light"
          className="bg-white border border-form-control"
          onClick={() => sortChange(filterState.sortby === "asc" ? "desc" : "asc")}
        >
          <FontAwesomeIcon icon={filterState.sortby === "asc" ? faLongArrowDown : faLongArrowUp} fixedWidth />
        </Button>
      </ButtonGroup>
    </Form>
  );
};
