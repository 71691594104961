import { useEffect, useState, useRef } from "react";
import { Rect, Transformer, Label, Tag, Text } from "react-konva";
import { roundAspectRatio } from "../../../../utilities/genericFunctions";


const TransformerRectangle = ({ shapeProps, isSelected, onSelect, onChange, canvasWidth, canvasHeight, fill, setAspectRatio, aspectRatio }) => {
  const shapeRef = useRef();
  const trRef = useRef();
  const [ drag, setDrag ] = useState(false);
  const [ transform, setTransform ] = useState(false);
  const [ transformShape, setTransformShape ] = useState(null);
  useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([ shapeRef.current ]);
      trRef.current.getLayer().batchDraw();
    }
  }, [ isSelected ]);

  return (
    <>
      <Rect
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragStart={(e)=>{
          setDrag(true);
        }}
        onDragEnd={(e) => {

          let x = e.target.x() <= 0 ? 0 : e.target.x() > (canvasWidth - shapeProps.width) ? (canvasWidth - shapeProps.width) : e.target.x();
          let y = e.target.y() <= 0 ? 0 : e.target.y() > (canvasHeight - shapeProps.height) ? (canvasHeight - shapeProps.height) : e.target.y();

          onChange({
            ...shapeProps,
            x: Math.round(x),
            y: Math.round(y),
          });
          e.target.x(x);
          e.target.y(y);
          setDrag(false);
        }}
        fill={fill ? fill : "rgba(255, 102, 173, 0.25)"}
        stroke="rgba(255, 102, 173, 1)"
        strokeWidth={3}
        onTransformEnd={(e) => {
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          node.scaleX(1);
          node.scaleY(1);

          // Make sure the rectangle size isn't greater than the canvas size
          let width = Math.max(5, node.width() * scaleX) > canvasWidth ? canvasWidth : Math.max(5, node.width() * scaleX);
          let height = Math.max(5, node.height() * scaleY) > canvasHeight ? canvasHeight : Math.max(5, node.height() * scaleY);

          // Make sure the x,y are positioned within the canvas
          let x = Math.round(node.x() <= 0 || width > canvasWidth ? 0 : node.x() > (canvasWidth - width) ? canvasWidth - width : node.x());
          let y = Math.round(node.y() <= 0 || height > canvasHeight ? 0 : node.y() > (canvasHeight - height) ? canvasHeight - height : node.y());

          node.x(x);
          node.y(y);
          onChange({
            ...shapeProps,
            x: x,
            y: y,
            // set minimal value
            width: width,
            height: height,
          });
          setTransform(false);
          setTransformShape(null);
        }}
      />

    {!drag &&
    <Label x={transform ? transformShape.x : shapeProps.x} y={transform ? transformShape.y : shapeProps.y}>
      <Tag
        fill={"#f2318a"}
        pointerDirection='none'
        cornerRadius={3}
        lineJoin='round'
        shadowColor={"#f2318a"}
      />

      <Text
        text={aspectRatio}
        fontSize={15}
        padding={5}
        fontStyle={"bold"}
        fill={"#ffffff"}
        wrap="word"
        align={"left"}
      />
    </Label>
    }
      {isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          keepRatio={false}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            setTransformShape(newBox);
            setTransform(true);
            const ratio = roundAspectRatio(Math.round(newBox.width), Math.round(newBox.height));
            setAspectRatio(ratio);
            return newBox;
          }}
        />
      )}
    </>
  );

}

export default TransformerRectangle;
