import { REHYDRATE } from "redux-persist";

import {
  SIGNOUT_SUCCESS,
  UPDATE_SESSION,
  UPDATE_USER_PASSWORD_REQUESTED,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_REQUESTED,
  UPDATE_USER_SUCCESS,
} from "../actions/session-action-types";

const initialState = {
  user: null,
  account: null,
  role: null,
  updatingUser: false,
};

export default function session(state = initialState, { payload, type }) {
  switch (type) {
    case UPDATE_SESSION:
      return {
        ...state,
        ...payload,
      };
    case UPDATE_USER_REQUESTED:
    case UPDATE_USER_PASSWORD_REQUESTED:
      return { 
        ...state, 
        updatingUser: true 
      };
    case UPDATE_USER_SUCCESS:
      return { 
        ...state, 
        user: payload, 
        updatingUser: 
        false 
      };
    case UPDATE_USER_PASSWORD_SUCCESS:
      return { 
        ...state, 
        updatingUser: 
        false 
      };
    case SIGNOUT_SUCCESS:
      return { 
        ...initialState 
      };
    case REHYDRATE: {
      let data = { 
        ...initialState 
      };
      if (payload?.session) {
        data = payload.session;
      }
      return data;
    }
    default:
      return state;
  }
}
