import { faFilter } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { Dropdown, Form, ListGroup, ListGroupItem } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useGetUseCasesQuery } from "@/redux/toolkit/accountSlice";

export const UseCaseFilter = ({ onUseCaseFilter }) => {
  const accountId = useSelector((state) => state.session?.account?.id);

  const {
    data: { useCases } = {},
  } = useGetUseCasesQuery({ accountId }, { refetchOnMountOrArgChange: true });

  const [useCaseSelection, setUseCaseSelection] = useState([]);

  const categories = useMemo(() => {
    let categories = [];
    useCases?.forEach((useCase) => {
      if (!categories.includes(useCase.category) && useCase.category !== "Other") {
        categories.push(useCase.category);
      }
    });
    return categories;
  }, [useCases]);

  useEffect(() => {
    onUseCaseFilter(useCaseSelection);
  }, [useCaseSelection]);

  return (
    <Form className="d-flex ms-auto  me-lg-2">
      <Dropdown>
        <Dropdown.Toggle variant="light" id="dropdown-basic" className="bg-white border border-form-control">
          <FontAwesomeIcon icon={faFilter} /> All use cases
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="fs-xs px-2 pb-2 border-bottom clearfix">
            <span className="float-start text-muted">{useCaseSelection.length} selected</span>
            <a className="float-end cursor-pointer" onClick={() => setUseCaseSelection([])}>
              Clear all
            </a>
          </div>
          <ListGroup variant="flush usecase-list">
            {categories?.length > 0 ? (
              categories.map((category, i) => {
                return (
                  <span key={`category-${i}`}>
                    <ListGroupItem className="p-0">
                      <h6 className="dropdown-header fs-xxs fw-600 text-uppercase mt-2">{category}</h6>
                    </ListGroupItem>
                    {useCases?.map((useCase, i) => {
                      if (useCase.category === category) {
                        return (
                          <ListGroupItem key={`useCase-${i}`} className="text-nowrap fs-sm py-1">
                            <input
                              className="form-check-input me-1"
                              type="checkbox"
                              onChange={() => {
                                if (useCaseSelection.includes(useCase.id)) {
                                  setUseCaseSelection(useCaseSelection.filter((id) => id !== useCase.id));
                                } else {
                                  setUseCaseSelection([...useCaseSelection, useCase.id]);
                                }
                              }}
                              checked={useCaseSelection.includes(useCase.id)}
                            />
                            {useCase?.name}
                          </ListGroupItem>
                        );
                      }
                    })}
                  </span>
                );
              })
            ) : (
              <li>
                <h6 className="dropdown-header fs-xxs fw-600 text-uppercase mt-2">No use cases available</h6>
              </li>
            )}
          </ListGroup>
        </Dropdown.Menu>
      </Dropdown>
    </Form>
  );
};
