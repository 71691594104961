import { Fragment, useState, useEffect, useCallback } from "react";
import { useField } from "formik";
import { Dropdown } from "react-bootstrap";
import { debounce } from "lodash";

// Generic dropdown for tags - use cases, region, brand, etc.
// Keep any tag specific logic in the parent component
export const TagsDropdown = ({ tag, setTag, noTagsMessage, displayValue = null, defaultDisplayValue, fieldName, isLoading, isError, isSuccess }) => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(fieldName);
  const [ useCases, setUseCases ] = useState(tag);
  const { setValue } = helpers;

  useEffect(()=>{
    setUseCases(tag);
  }, [ tag ])

  const debounceSearch = useCallback(debounce(function (e) {
    const value = e.target.value.trim();
    if(value===""){
      setValue(null);
    }
    if(value.length) {
      const list = tag.filter(tags=> tags.name && tags.name.toLowerCase().includes(value.toLowerCase()))
      setUseCases(list);
    } else {
      setUseCases(tag);
    }
  }, 300), [ tag ]);

  const onSearch= (e) => {
    setTag(e.target.value);
    debounceSearch(e);
  };

  return (
    <Dropdown className="mt-3">
      <Dropdown.Toggle
        id="dropdown-basic"
        className="w-100 d-flex align-items-center justify-content-between py-0 bg-white ps-0"
        style={{ textAlign: "left" }}
        variant="outline-secondary"
      >
        <input className="w-100 border-0 form-range" type="text" onChange={e=>{onSearch(e)}} placeholder={defaultDisplayValue} value={displayValue} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="w-100 usecase-list">
        {isLoading && <Dropdown.Item>Loading...</Dropdown.Item>}
        {isError && <Dropdown.Item>Unable to retrieve use cases</Dropdown.Item>}
        {isSuccess && useCases && useCases.length === 0 && <Dropdown.Item>{noTagsMessage}</Dropdown.Item>}
        {useCases &&
        useCases.length > 0 &&
        useCases.map((tag) => (
            <Fragment key={tag.id}>
            {tag.isCategory ? <Dropdown.Header
                as="div"
                key={tag.id}
                className="py-1 fw-600 text-primary"
              >
                {tag.category.toUpperCase()}
              </Dropdown.Header> :
              <Dropdown.Item
                as="div"
                key={tag.id}
                onClick={() => {
                  setTag(tag.name);
                  setValue(tag.id);
                }}
                className="py-2"
              >
                {tag.name}
              </Dropdown.Item>}
            </Fragment>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
