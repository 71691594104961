import {
  faAtomAlt,
  faShareAlt,
  faGem,
  faFireAlt,
  faTh,
  faEye,
  faDrawSquare,
  faTrophy,
  faFrame,
  faMessageLines,
  faUsers,
  faChartPieSimple,
  faChartSimple,
  faFireFlameCurved,
  faGridRound5,
  faEyes,
  faTableColumns,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faDrawSquare as falDrawSquare,
  faFrame as falFrame,
  faTh as falTh,
  faFireAlt as falFireAlt,
  faGridRound5 as falGridRound5,
  faTableColumns as falTableColumns,
} from "@fortawesome/pro-light-svg-icons";
import {
  attentionMapPreview,
  contextPreview,
  gridPreview,
  hotspotsPreview,
  AOIPreview,
} from "../assets/img/reportImagery";

/**
 * Constant for initial types reports
 */
export const reportSetting = {
  activeReport: 1,
  layout: 1,
  reports: [
    {
      id: 1,
      notes: "",
      name: "Overview",
      type: "original",
    },
    {
      id: 2,
      notes: "",
      name: "Attention Map",
      type: "attention_map",
      settings: {
        bias: "center",
        map: "heat",
      },
    },
    {
      id: 3,
      notes: "",
      name: "Grid",
      type: "hotspots",
      settings: {
        bias: "center",
        scale: 3.2,
        map: "heat",
      },
    },
    {
      id: 4,
      notes: "",
      name: "Hotspots",
      type: "gaze_path",
      settings: {
        bias: "center",
        metric: "probability",
        map: "heat",
      },
      features: {
        hotspots: true,
        gaze_path: false,
        digestibility: true,
      },
    },
    {
      id: 5,
      notes: "",
      name: "Areas of Interest",
      type: "aoi",
      settings: {
        bias: "center",
        metric: "probability",
        map: "heat",
        targetHierarchy: [],
      },
      features: {
        aoi_rank_path: true,
        vh_scorecard: true,
      },
    },
  ],
};

export const contextSetting = {
  id: 6,
  notes: "",
  name: "Contexts",
  type: "context",
  settings: {
    bias: "center",
    metric: "probability",
    map: "heat",
  },
};
export const iconList = {
  original: faTableColumns,
  attention_map: faGridRound5,
  hotspots: faTh,
  gaze_path: faFireAlt,
  aoi: faDrawSquare,
  context: faFrame,
};

export const featureIconList = {
  hotspots: faFireAlt,
  metric: faAtomAlt,
  gaze_path: faShareAlt,
  digestibility: faGem,
  aoi_rank_path: faTrophy,
  vh_scorecard: faMessageLines,
};

export const featureList = {
  hotspots: "Hotspots",
  metric: "Metric",
  gaze_path: "Gaze Path",
  digestibility: "Clarity",
  aoi_rank_path: "AOI Rank",
  vh_scorecard: "Digestibility",
};

export const metricName = {
  probability: "POP",
  share: "SOA",
  attention: "APF",
  local: "LAS",
};

export const metricIconList = {
  probability: faUsers,
  share: faChartPieSimple,
  attention: faChartSimple,
  local: faFireFlameCurved,
  clarity: faGem,
  digestibility: faMessageLines,
  visibility: faEyes,
};

export const AOI_Features = {
  aoi_rank_path: true,
  vh_scorecard: true,
};

export const gazePathFeatures = {
  hotspots: true,
  gaze_path: false,
  digestibility: true,
};

export const defaultSetting = {
  bias: "center",
  metric: "probability",
  map: "heat",
};

export const aoiSetting = {
  bias: "center",
  metric: "probability",
  map: "heat",
  targetHierarchy: [],
};

export const hotspotSetting = {
  bias: "center",
  scale: 3.2,
  map: "heat",
};

export const attentionMapSetting = {
  bias: "center",
  map: "heat",
};

export const reportTypes = [
  {
    name: "Attention map",
    icon: faEye,
    tagline: "Visualise the distribution of attention",
    description:
      "Attention Map reports provide a simple and intuitive visualisation of where attention is being directed within your image.",
    tip: "Use this report to get a quick sense of whether your creative execution is aligned to your design goals.",
    previewImage: attentionMapPreview,
    type: "attention_map",
    newFeature: false,
    infoURL: "https://academy.dragonflyai.co/en/articles/5605634-visualisations",
  },
  {
    name: "Contexts",
    icon: faFrame,
    tagline: "Measure performance in different environments",
    description: "Context reports help you measure whether creative stands out in visually competitive environments.",
    tip: "Use this report to test all kinds of contexts from packaging on-shelf to banner ads on websites.",
    previewImage: contextPreview,
    type: "context",
    newFeature: true,
    infoURL: "https://academy.dragonflyai.co/en/articles/6899237",
  },
  {
    name: "Areas of Interest",
    icon: faDrawSquare,
    tagline: "Measure specific Areas of Interest (AOIs)",
    description:
      "Areas of Interest (AOI) reports help you measure the performance of specific elements within a design (e.g. a logo or call-to-action).",
    tip: "Use this report to ensure that key information is seen and the visual hierarchy aligns to your target viewing order",
    previewImage: AOIPreview,
    type: "aoi",
    newFeature: false,
    infoURL: "https://academy.dragonflyai.co/en/articles/5605679-areas-of-interest-aoi",
  },
  {
    name: "Grid",
    icon: faTh,
    tagline: "Quantify the distribution of attention",
    description:
      "Grid reports tell you how salient the most attention grabbing areas are versus the average for the image as a whole.",
    tip: "Use this report to minimise visual distractions and ensure your key information stands out.",
    previewImage: gridPreview,
    type: "hotspots",
    newFeature: false,
    infoURL: "https://academy.dragonflyai.co/en/articles/5605688-grid",
  },
  {
    name: "Hotspots",
    icon: faFireAlt,
    tagline: "Reveal the highest scoring areas",
    description:
      "Hotspot reports automatically identify the most attention grabbing areas and provide a prediction of the order in which they will be seen.",
    tip: "Use this report to optimise the gaze path and minimise unnecessary distractions.",
    previewImage: hotspotsPreview,
    type: "gaze_path",
    newFeature: false,
    infoURL: "https://academy.dragonflyai.co/en/articles/5676742-hotspots-gaze-path-digestibility",
  },
];

export const tipsHeader = {
  attention_map: {
    title: "Attention Maps",
    subTitle: "Sense check what viewers see first.",
    icon: falGridRound5,
  },
  context: {
    title: "Contexts",
    subTitle: "Maximize visibility in-context.",
    icon: falFrame,
  },
  aoi: {
    title: "Areas of Interest (AOIs)",
    subTitle: "Ensure elements are seen in the right order.",
    icon: falDrawSquare,
  },
  hotspots: {
    title: "Grid",
    subTitle: "Quantify attention levels across your image.",
    icon: falTh,
  },
  gaze_path: {
    title: "Hotspots",
    subTitle: "Identify & minimize distractions.",
    icon: falFireAlt,
  },
  original: {
    title: "Overview",
    subTitle: "Summarize attention performance.",
    icon: falTableColumns,
  },
};
