import { all, fork } from "redux-saga/effects";
import file from "./file";
import processing from "./processing";
import scoring from "./scoring";
import grid from "./grid";
import session from "./session";
import project from "./project";
import hotspot from "./hotspot";
import context from "./context";

export default function* rootSaga() {
  yield all([
    fork(file),
    fork(processing),
    fork(scoring),
    fork(grid),
    fork(session),
    fork(project),
    fork(hotspot),
    fork(context),
  ]);
}
