import { api } from "../../../redux/toolkit/apiSlice";

export const extendedApiSlice2 = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllStudies: builder.query({
      query: ({ query, view, pageIndex, itemsPerPage, orderby, sortby, projectId }) => {
        if(query) return  `/manage/projects/${projectId}/studies?query=${query}&view=${view}&orderBy=${orderby}&sortBy=${sortby}&pageIndex=${pageIndex}&itemsPerPage=${itemsPerPage}`;
        return `/manage/projects/${projectId}/studies?view=${view}&orderBy=${orderby}&sortBy=${sortby}&pageIndex=${pageIndex}&itemsPerPage=${itemsPerPage}`;
      },
      transformResponse: (response) => {
          return {
            studies: response.payload.studies,
            maxPageIndex: response.payload.maxPageIndex,
            maxRecordCount: response.payload.maxRecordCount,
            pageIndex: response.payload.pageIndex,
          };
      },
      providesTags: ["Study"],
    }),
    getStudy: builder.query({
      query: ({ projectId, studyId }) => `/manage/projects/${projectId}/studies/${studyId}`,
      transformResponse: ({ payload }) => {
        return {
          study: payload.study,
        };
      },
      providesTags: ["Study"],
    }),
    createStudy: builder.mutation({
      query: ({ projectId, data }) => ({
        url: `/manage/projects/${projectId}/studies`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Study"],
    }),
    deleteStudy: builder.mutation({
      query: ({ projectId, studyId }) => ({
        url: `/manage/projects/${projectId}/studies/${studyId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Study"],
    }),
    updateStudy: builder.mutation({
      query: ({ projectId, studyId, data, bias }) => ({
        url: `/manage/projects/${projectId}/studies/${studyId}`,
        method: "PATCH",
        body: { ...data, bias },
      }),
      invalidatesTags: ["Study"],
    }),
    moveStudy: builder.mutation({
      query: ({ projectId, studyId, data }) => ({
        url: `/manage/projects/${projectId}/studies/${studyId}/actions/move`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Study"],
    }),
  }),
});

export const {
  useGetAllStudiesQuery,
  useGetStudyQuery,
  useCreateStudyMutation,
  useDeleteStudyMutation,
  useUpdateStudyMutation,
  useMoveStudyMutation,
} = extendedApiSlice2;
