import { useRef, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes as falTimes, faFolder as falFolder } from "@fortawesome/pro-light-svg-icons";
import { faShare as fasShare, faLock as fasLock } from "@fortawesome/pro-solid-svg-icons";
import { Button, TextInput } from "../FormFields";
import { useCreateProjectMutation } from "../../features/projects/api/projectsSlice";
import { Toaster } from "../Toast";
import { segmentAnalytics } from "@/utilities/genericFunctions";

const AddProjectSchema = Yup.object({
  name: Yup.string()
    .required("Project name is a required.")
    .matches(/^[A-Za-z 0-9]+$/, "Project names can only contain letters, numbers and spaces."),
  visibility: Yup.string(),
});

function NewProjectModal({ hide }) {
  const formInitialValues = { name: "", visibility: "private" };
  const session = useSelector((state) => state.session);

  const [createProject, { isLoading,isError, data }] = useCreateProjectMutation();

  const onSubmit = async (value) => {
    try {
      const projectData = await createProject(value).unwrap();
      segmentAnalytics({ page: false, session, name: "Project Created", segmentData:{
        projectId: projectData.payload.project.id,
        projectName: projectData.payload.project.name,
      } });
      Toaster({ message: "Project added successfully." });
    } catch (error) {
      Toaster({ message: "Adding new project failed.", type: "error" });
    }
  };

  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 1);
    }
  }, []);

  useEffect(() => {
    if (data && !isError) {
      // data is the new project created which is used to navigate to the project page after creation
      hide(data);
    }
  }, [ data ]);

  return (
    <Formik
      key={"NewProjectForm"}
      initialValues={formInitialValues}
      validationSchema={AddProjectSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, errors, isValidating, handleSubmit }) => (
        <div className="new-project">
          <div className="modal-header border-darken-15 bg-white">
            <h5 className="modal-title fw-light" id="exampleModalLabel">
              New project
            </h5>
            <button type="button" className="btn btn-link text-black-50 close" onMouseDown={(e) => e.preventDefault()} onClick={() => hide()}>
              <FontAwesomeIcon icon={falTimes} size="lg" className="mt-1" />
            </button>
          </div>
          <div className="modal-body position-relative emd-body">
            <div className="d-flex align-items-center justify-content-center h-100 overflow-auto">
              <form
                className="form form-modal-fullscreen needs-validation"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSubmit();
                  }
                }}
              >
                <header className="form-header text-center mb-4">
                  <span className="d-inline-block m-auto mb-3  op-20 border rounded-circle text-center d-flex align-items-center justify-content-center text-dark border-dark border-3 folder-icon">
                    <FontAwesomeIcon icon={falFolder} size="3x" />
                  </span>
                  <p className="lead fw-400">Create a project</p>
                </header>
                <div className="form-group mb-3">
                  <div className="form-label-group">
                    <TextInput
                      id="projectName"
                      label="Name"
                      name="name"
                      type="text"
                      placeholder="Name"
                      className="form-control"
                      ref={inputRef}
                      required
                      autoFocus={true}
                    />
                    <label htmlFor="inputEmail">Name</label>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <p className="fw-500">Who has access?</p>
                  <div className="form-check d-flex align-items-center mb-3">
                    <Field
                      className="form-check-input"
                      type="radio"
                      name="visibility"
                      value="private"
                      id="flexRadioDefault1"
                    />
                    <label className="form-check-label position-relative lpl" htmlFor="flexRadioDefault1">
                      <span className="position-absolute top-0 start-0 bg-primary text-white shadow-sm d-flex align-items-center justify-content-center text-center rounded label-icon">
                        <FontAwesomeIcon icon={fasLock} size="lg" />
                      </span>
                      <span className="fw-500">Private</span>
                      <p className="fs-xs text-muted mb-0">Visible only to you and account admins.</p>
                    </label>
                  </div>
                  <div className="form-check d-flex align-items-center mb-3">
                    <Field
                      className="form-check-input"
                      type="radio"
                      name="visibility"
                      value="public"
                      id="flexRadioDefault2"
                    />
                    <label className="form-check-label position-relative lpl" htmlFor="flexRadioDefault2">
                      <span className="position-absolute top-0 start-0 bg-primary text-white shadow-sm d-flex align-items-center justify-content-center text-center rounded label-icon">
                        <FontAwesomeIcon icon={fasShare} size="lg" />
                      </span>
                      <span className="fw-500">Shared</span>
                      <p className="fs-xs text-muted mb-0">Visible to everyone in your account.</p>
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modal-footer border-darken-15 bg-white">
            <button type="button" className="btn btn-outline-dark" data-dismiss="modal" onMouseDown={(e) => e.preventDefault()} onClick={() => hide()}>
              Discard
            </button>
            <Button
              label={"Create project"}
              className="btn btn-primary"
              dirty={dirty}
              errors={errors}
              isValidating={isValidating}
              isSubmitting={isLoading}
              onClick={handleSubmit}
            />
          </div>
        </div>
      )}
    </Formik>
  );
}

export default NewProjectModal;
