import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contextFilters: {
    query: "",
    view: "all",
    pageIndex: 1,
    itemsPerPage: 10,
    orderby: "createdat",
    sortby: "desc",
  },
  contextPackFilters: {
    query: "",
    view: "all",
    pageIndex: 1,
    itemsPerPage: 10,
    orderby: "createdat",
    sortby: "desc",
    status: "published"
  }
};

export const ContextSelectionFiltersSlice = createSlice({
  name: "ContextSelectionFilters",
  initialState,
  reducers: {
    updateContextFilter: (state, action) => {
      state.contextFilters = { ...state.contextFilters, ...action.payload };
    },
    updateContextPackFilter: (state, action) => {
      state.contextPackFilters = { ...state.contextPackFilters, ...action.payload };
    }
  },
});

export const { updateContextFilter, updateContextPackFilter } = ContextSelectionFiltersSlice.actions;

export default ContextSelectionFiltersSlice.reducer;
