import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contextFilters: {
    query: "",
    view: "all",
    pageIndex: 1,
    itemsPerPage: 10,
    orderby: "createdat",
    sortby: "desc",
  },
  contextPackFilters: {
    query: "",
    view: "all",
    pageIndex: 1,
    itemsPerPage: 10,
    orderby: "createdat",
    sortby: "desc",
  },
  benchmarkFilters: {
    query: "",
    view: "all",
    pageIndex: 1,
    itemsPerPage: 10,
    orderby: "createdat",
    sortby: "desc",
  },
};

export const libraryFiltersSlice = createSlice({
  name: "libraryFilters",
  initialState,
  reducers: {
    updateContextFilter: (state, action) => {
      state.contextFilters = { ...state.contextFilters, ...action.payload };
    },
    updateContextPackFilter: (state, action) => {
      state.contextPackFilters = { ...state.contextPackFilters, ...action.payload };
    },
    updateBenchmarkFilter: (state, action) => {
      state.benchmarkFilters = { ...state.benchmarkFilters, ...action.payload };
    },
  },
});

export const { updateContextFilter, updateContextPackFilter, updateBenchmarkFilter } = libraryFiltersSlice.actions;

export default libraryFiltersSlice.reducer;
