import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faUser,
  faGraduationCap,
  faBracketsCurly,
  faSignOut,
  faFolders,
  faImages,
} from "@fortawesome/pro-regular-svg-icons";
import { faUserCircle as falUserCircle, faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { signout } from "@/redux/actions/session-action-types";
import ClearCache from "../ClearCache";
import { useLocation, matchPath } from "react-router";
import { NavLink } from "react-router-dom";
import { Nav, NavItem, Navbar } from "react-bootstrap";
import { useProductAccess } from "@/hooks/useProductAccess";

const DashboardNav = function (props) {
  const dispatch = useDispatch();

  const firstName = useSelector((state) => state.session.user?.firstName);
  const lastName = useSelector((state) => state.session.user?.lastName);
  const email = useSelector((state) => state.session.user?.email);
  const company = useSelector((state) => state.session.account?.company);
  const role = useSelector((state) => state.session.role);
  const [showPopUp, setShowPopUp] = React.useState(false);

  const { pathname } = useLocation();
  const isLibrary = matchPath("/library/*", pathname);

  // If user has access to contextsplus, show library in the nav
  const hasLibraryAccess = useProductAccess("contextsplus");

  const signOut = (e) => {
    // e.preventDefault();

    /** Remove intercom when log out of the app */
    if (window.Intercom) {
      window.Intercom("shutdown");
    }
    dispatch(signout());
  };

  return (
    <>
      <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-white bg-light shadow-sm">
        <div className="container-fluid">
          <ul className="navbar-nav align-items-center me-3">
            <li className="nav-item dropdown">
              <div className="btn-group bg-white border border-form-control rounded shadow-sm">
                <a
                  href={`https://${
                    process.env.REACT_APP_ENV === "prod" ? "" : `${process.env.REACT_APP_ENV}.`
                  }id.dragonflyai.co`}
                  className="btn border-end"
                >
                  <img src="/img/dragonflyai-ident.svg" height={26} className="float-left" alt="" />
                </a>
                <button
                  type="button"
                  className="btn dropdown-toggle-split text-nowrap"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src="/img/product-icon-studio.svg" height={20} className="float-left mx-2" alt="" />
                  Studio
                  <span className="d-inline-block position-relative text-black-50 ms-2 t-1">
                    <FontAwesomeIcon icon={faAngleDown} />
                  </span>
                </button>
                <ul className="dropdown-menu w-280">
                  <li>
                    <h6 className="dropdown-header">Products</h6>
                  </li>
                  <li>
                    <a className="dropdown-item py-2" href="#">
                      <span className="d-block position-relative ps-5">
                        <span className="d-inline-block position-absolute top-0 start-0 p-2 bg-white shadow-sm text-center rounded w-40">
                          <img src="/img/product-icon-studio.svg" height={20} alt="" />
                        </span>
                        <span className="d-block ps-2">
                          Studio
                          <br />
                          <p className="text-muted text-wrap fs-xs mb-0">
                            Measure and optimise digital assets, before you publish.
                          </p>
                        </span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item py-2"
                      href="https://apps.apple.com/au/app/dragonfly-ai/id1449901814"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="d-block position-relative ps-5">
                        <span className="d-inline-block position-absolute top-0 start-0 p-2 bg-white shadow-sm text-center rounded w-40">
                          <img src="/img/product-icon-app.svg" height={20} alt="" />
                        </span>
                        <span className="d-block ps-2">
                          App
                          <br />
                          <p className="text-muted text-wrap fs-xs mb-0">
                            Measure and optimise real-world assets and experiences, live.
                          </p>
                        </span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item py-2"
                      href="https://chrome.google.com/webstore/detail/dragonfly-ai-extension/gghcgiklappijoenldhohfifipkcpngj"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="d-block position-relative ps-5">
                        <span className="d-inline-block position-absolute top-0 start-0 p-2 bg-white shadow-sm text-center rounded w-40">
                          <img src="/img/product-icon-extension.svg" height={20} alt="" />
                        </span>
                        <span className="d-block ps-2">
                          Extension
                          <br />
                          <p className="text-muted text-wrap fs-xs mb-0">
                            Measure and optimise online customer journeys, in real-time.
                          </p>
                        </span>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <Navbar.Collapse id="basic-navbar-nav" className="navbar-nav">
            <Nav className="me-auto">
              <NavItem>
                <NavLink to="/projects" className="nav-link d-flex align-items-center">
                  <FontAwesomeIcon icon={faFolders} size="1x" className="me-2" /> Projects
                </NavLink>
              </NavItem>
              {hasLibraryAccess && (
                <NavItem>
                  <NavLink
                    to="/library/contexts"
                    className={`nav-link d-flex align-items-center ${isLibrary ? "active" : ""}`}
                  >
                    <FontAwesomeIcon icon={faImages} size="1x" className="me-2" /> Library
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </Navbar.Collapse>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto my-2 my-lg-0 px-lg-3">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDarkDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon={falUserCircle} size="lg" className="me-2" />
                  {`${firstName} ${lastName}`}
                </a>
                <ul className="dropdown-menu dropdown-menu-end p-4" aria-labelledby="navbarDarkDropdownMenuLink">
                  <li>
                    <div className="px-2 ps-5 mb-3 position-relative">
                      <span className="position-absolute top-0 start-0 py-2 d-flex align-items-center justify-content-center bg-primary rounded text-white w-225r">
                        {company ? company[0] : ""}
                      </span>
                      <p className="text-truncate mb-0 small fw-500">{company}</p>
                      <p className="text-truncate mb-0 small">{email}</p>
                    </div>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  {(role === "super" || role === "admin") && (
                    <li>
                      <a
                        className="dropdown-item p-2"
                        href={`https://${
                          process.env.REACT_APP_ENV === "prod" ? "" : `${process.env.REACT_APP_ENV}.`
                        }admin.dragonflyai.co`}
                      >
                        <FontAwesomeIcon icon={faBuilding} fixedWidth className="me-1" /> Account
                      </a>
                    </li>
                  )}
                  <li>
                    <a
                      className="dropdown-item p-2"
                      href={`https://${
                        process.env.REACT_APP_ENV === "prod" ? "" : `${process.env.REACT_APP_ENV}.`
                      }admin.dragonflyai.co/profile/basic-info`}
                    >
                      <FontAwesomeIcon icon={faUser} fixedWidth className="me-1" /> Profile
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item p-2"
                      href="http://academy.dragonflyai.co"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faGraduationCap} fixedWidth className="me-1" /> Academy
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item p-2"
                      href="http://docs.api.dragonflyai.co"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faBracketsCurly} fixedWidth className="me-1" /> API Documentation
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <span className="dropdown-item pointer p-2" href="#" onClick={(e) => signOut(e)}>
                      <FontAwesomeIcon icon={faSignOut} fixedWidth className="me-1" /> Sign out
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <ClearCache showPopUp={showPopUp} setShowPopUp={setShowPopUp} />
    </>
  );
};

export default DashboardNav;
