import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes as falTimes } from "@fortawesome/pro-light-svg-icons";
import { Button, TextInput } from "../FormFields";
import { Toaster } from "../Toast";
import { useSelector } from "react-redux";
import { TagsDropdown } from "../Tags";
import { segmentAnalytics } from "@/utilities/genericFunctions";
import { OtherTag } from "../Tags/OtherTag";
import { useCreateBenchmarkMutation } from "@/features/library/api/librarySlice";
import { useGetUseCasesQuery } from "@/redux/toolkit/accountSlice";

export function NewBenchmarkModal({ hide }) {
  const [createBenchmark, { isLoading, isError, data, isSuccess }] = useCreateBenchmarkMutation();

  const accountId = useSelector((state) => state.session?.account?.id);

  const {
    data: { filteredUseCases: useCases } = {},
    isSuccess: useCaseIsSuccess,
    isLoading: useCaseIsLoading,
    isError: useCaseIsError,
  } = useGetUseCasesQuery({ accountId }, { refetchOnMountOrArgChange: true });

  const session = useSelector((state) => state.session);

  // sets the display value in the dropdown to the use case name
  const [useCase, setUseCase] = useState("");

  const onSubmit = async (value) => {
    if (value.otherTag) {
      segmentAnalytics({
        page: false,
        session,
        name: "Use Case Suggestion",
        segmentData: {
          otherUseCase: value.otherTag,
          benchmarkName: value.name,
        },
      });

      Toaster({ message: "Use case suggestion added." });
    }

    try {
      await createBenchmark({ data: { name: value.name, useCaseID: value.useCaseId } }).unwrap();
      /** Segment track event for benchmark create */
      segmentAnalytics({
        page: false,
        session,
        name: "Library Benchmark Create",
        segmentData: {
          benchmarkName: value.name,
        },
      });
      Toaster({ message: "Benchmark successfully created." });
    } catch ({
      data: {
        error: { message },
      },
    }) {
      if (message === "The use case does not exist.") {
        Toaster({ message: "This use case no longer exists - please log out and try again", type: "error" });
      } else {
        Toaster({ message: "Adding benchmark failed.", type: "error" });
      }
    }
  };

  useEffect(() => {
    // data is the new project created which is used to navigate to the project page after creation
    if (data && !isError) {
      hide(data);
    }
  }, [isSuccess]);

  return (
    <Formik
      initialValues={{
        name: "",
        useCaseId: "",
        otherTag: "",
      }}
      validationSchema={Yup.object({
        name: Yup.string()
          .required("Study name is required.")
          .matches(/^[A-Za-z 0-9]+$/, "Study names can only contain letters, numbers and spaces."),
        useCaseId: Yup.string().required(),
        otherTag: useCase !== "Other" ? Yup.string() : Yup.string().required(),
      })}
      onSubmit={onSubmit}
    >
      {({ dirty, errors, isValidating, handleSubmit }) => (
        <>
          <Form
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit();
              }
            }}
          >
            <div className="modal-header border-darken-15 bg-white">
              <h5 className="modal-title fw-light" id="exampleModalLabel">
                New Benchmark
              </h5>
              <button type="button" className="btn btn-link text-black-50 close" onClick={() => hide()}>
                <FontAwesomeIcon icon={falTimes} size="lg" className="mt-1" />
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group mb-3">
                <div className="form-label-group drop-align">
                  <TextInput
                    id="studyName"
                    label="Name"
                    name="name"
                    type="text"
                    placeholder="Name"
                    className="form-control"
                    required
                    autoFocus={true}
                  />
                </div>
                <div className="form-label-group mb-2 mt-4 text-area-label-fix">
                  <TagsDropdown
                    tag={useCases}
                    setTag={setUseCase}
                    displayValue={useCase}
                    isError={useCaseIsError}
                    isSuccess={useCaseIsSuccess}
                    isLoading={useCaseIsLoading}
                    defaultDisplayValue="Select Use Case"
                    fieldName="useCaseId"
                    noTagsMessage="No use cases found"
                  />
                  {useCase === "Other" && (
                    <OtherTag
                      label="Feedback"
                      supportingMessage="Share your feedback here to let us know what use case options you'd like us to support."
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer border-darken-15 bg-white">
              <button
                type="button"
                className="btn btn-outline-dark"
                data-dismiss="modal"
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => hide()}
              >
                Discard
              </button>
              <Button
                label={"Create benchmark"}
                className="btn btn-primary"
                dirty={dirty}
                errors={errors}
                isValidating={isValidating}
                isSubmitting={isLoading}
                onClick={handleSubmit}
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}
