import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CardSmall = ({ cardMeta, contextSelection, setContextSelection }) => {
  const { name, filename, useCase, thumbUrl } = cardMeta;

  const removeContext = () => {
    setContextSelection(contextSelection.filter((context) => context.id !== cardMeta.id));
  };

  return (
    <div className="media-item mb-3 position-relative w-100">
      <a
        className="small position-absolute d-inline-block bg-white text-secondary text-center rounded-circle px-2 py-1 fw-700 shadow-sm cursor-pointer"
        style={{ width: "29px", zIndex: "2", top: "-0.5rem", right: "-0.5rem" }}
        onClick={removeContext}
      >
        <FontAwesomeIcon icon={faTimes} className="mt-1" />
      </a>
      <div className="card shadow-sm reveal-overlay-hover border border-white">
        <div className="position-relative">
          <img src={thumbUrl} className="card-img-top" alt="..." />
        </div>
        <div className="card-body position-relative">
          <h5 className="card-title h6 fw-700 mb-1 text-dark text-nowrap text-truncate fs-sm">{name || filename}</h5>
          <p className="badge bg-info-10 text-info fw-500 text-uppercase fs-xxs p-1 m-0">{useCase?.name}</p>
        </div>
      </div>
    </div>
  );
};
