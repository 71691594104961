import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import Chart from "chartjs-gauge";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { AOI_Features, gazePathFeatures, defaultSetting, aoiSetting, attentionMapSetting, hotspotSetting } from "@/constants/reportSettings"

export const flatpoints = (stages, selectedStageIndex, polygonindex, curMousePos, id) => {

  const flattenedPoints = stages[selectedStageIndex].aoi.find(stage => stage.reportId === id).shapes[polygonindex].points
    .concat(stages[selectedStageIndex].aoi.find(stage => stage.reportId === id).shapes[polygonindex].isFinished ? [] : curMousePos)
    .map(eachshape => Object.keys(eachshape).map(eachKey => eachshape[eachKey]))
    .reduce((a, b) => [ ...a, ...b ], []);
  return flattenedPoints;
}

export const createViews = (url, report, index) => {
  // const ReportType = imagereport == 'Attention_Map'?'Attention Map':imagereport == 'AOI'?'AOIs':imagereport;
  let imagedataUrl = {
    name: report.name,
    type: report.type,
    stageIndex: index,
    stagename: `Version ${String.fromCharCode(65 + index)}`,
    imagedataurl: url,
    imagename: `Version ${String.fromCharCode(65 + index)} - ${report.name}`,
    settings: report.settings,
    reportId: report.id,
    notes: report.notes
  }
  return imagedataUrl
}

export const getMousePos = (event, stage, stages, index) => {
  /* give mouse co-ordinates to build polygon */
  const pointerPosition = stage.getPointerPosition();
  const offset = { x: event.currentTarget.attrs.x, y: event.currentTarget.attrs.y };
  const point = {
    x: Math.floor((pointerPosition.x - (offset.x)) * (1 / stages[index].scale)),
    y: Math.floor((pointerPosition.y - (offset.y)) * (1 / stages[index].scale))
  };
  return point;
}

export const findShape = (PolygonId, stages, selectedStageIndex, id) => {
  /*Find the selected polygon on an Image in a stage*/
  let stageCopy = [ ...stages ]; // copy old stages
  let shapes = [];
  if (stages[selectedStageIndex].aoi && stages[selectedStageIndex].aoi.length && stages[selectedStageIndex].aoi.find(stage => stage.reportId === id)?.shapes && stages[selectedStageIndex].aoi.find(stage => stage.reportId === id).shapes.length) {
    shapes = stageCopy[selectedStageIndex].aoi.find(stage => stage.reportId === id).shapes;
  }
  // let shapes = stageCopy[selectedStageIndex].shapes[id] ? stageCopy[selectedStageIndex].shapes[id] : [];
  let shapeinfo = shapes.find(i => i.id == PolygonId);
  let polygonindex = shapes.indexOf(shapeinfo);
  return polygonindex;
}


export const setExportViews = (imagedata, report, folderformat, Study, exportProgress, setExportProgress, exportProcess, exportTime, session, activeReport, study_id, project_id) => {

  /* exporting all views of stages into single zip file */
  let imgreport, reportset = [];

  if (imagedata && imagedata.length > 0) {
    Object.keys(report).map(function (key, index) {

      if (report[key] == true) {
        imgreport = imagedata.filter(item => (item.name == key));
        reportset = [ ...reportset, ...imgreport ];
      }
    });
    /* only selected views will be zipped */

    if (reportset.length > 0) {

      let zip = new JSZip();
      let count = 0;
      reportset.forEach(function (item) {
        JSZipUtils.getBinaryContent(item.imagedataurl, function (err, data) {

          if (folderformat == "folders") {
            zip.folder(`${Study.name}`).folder(item.stagename).file(item.imagename + ".png", data, { binary: true });
            count++;
          } else if (folderformat == "flat") {
            zip.folder(`${Study.name}`).file(item.imagename + ".png", data, { binary: true });
            count++;
          }

          setExportProgress(exportProgress => exportProgress + 10)

          if (count == reportset.length && exportProcess) {
            zip.generateAsync({ type: "blob" }).then(function (content) {
              saveAs(content, `${Study.name}.zip`);
            })

            setExportProgress(exportProgress => 100)
            let timeConsumed = new Date().getTime() - exportTime;

            /** Segment track event **/
            segmentAnalytics({ page: false, session, name: "Study Exported", segmentData:{
              exportFormat: folderformat,
              exportTimeInSeconds: timeConsumed ? Math.round(timeConsumed / 1000) : 0,
              projectId: project_id,
              studyId: study_id,
              exportType : "Visuals" } });
          } else {
            setExportProgress(exportProgress => 0)
            return;
          }
        });
      });

    }
    return true;
  }

}

export const generateGaugeChart = (element, score, color) => {

  let chart = new Chart(element, {
    type: "gauge",
    cutout: 100,
    data: {
      datasets: [ {
        value: score,
        minValue: 0,
        data: [ score, 100 ],
        backgroundColor: [ color, "#DDDDDD" ],
        borderWidth: 0,
      } ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      cutoutPercentage: 85,
      needle: {
        radiusPercentage: 0,
        widthPercentage: 0,
        lengthPercentage: 0,
        color: "rgba(0, 0, 0, 1)"
      },
      valueLabel: {
        display: true,
        formatter: (value) => {
          return value;
        },
        color: "rgba(255, 255, 255, 1)",
        backgroundColor: color,
        fontSize: 12,
        borderRadius: 3,
        padding: {
          top: 4,
          bottom: 4
        },
        bottomMarginPercentage: 15
      }
    }
  });

  return chart;
}

/**
 *  * This function assign next numbering in the new duplicate report
**/
export const update = (string) => {
  let copyMatch = string.match(/^(.+ copy)$/);

  let copyCountMatch = string.match(/^(.+ copy )(\d+)$/);

  return copyCountMatch && copyCountMatch.length === 3
    ? copyCountMatch[1] + (+copyCountMatch[2] + 1)
    : (copyMatch && copyMatch.length === 2 ? string + " 1" : string + " copy");
}

/**
 *  This function create the duplicate report name with concating copy and numbering with the report name.
 **/
export const createDuplicateReportName = (name, setting) => {

  let nameExist = setting.reports.filter(el =>
    el.name.match(`^${name} copy$`)).map(e => e.name);
  let nameCountExist = setting.reports.filter(el =>
    el.name.match(`^${name} copy \\d+$`)).map(e => e.name);
  if (nameExist.length) {
    if (nameCountExist.length) {
      return update(nameCountExist[nameCountExist.length - 1]);
    } else {
      return update(nameExist[nameExist.length - 1]);
    }
  }
  else {
    return `${name} copy`
  }
}

/**
 *  * This function assign next numbering in the new report
**/
export const updates = (string) => {

  let copyCountMatch = string.match(/^(.+ )(\d+)$/);

  return copyCountMatch && copyCountMatch.length === 3
    ? copyCountMatch[1] + (+copyCountMatch[2] + 1)
    : string + " 1";
}

/**
 *  This function create the report name with concating numbering with the report name.
 **/
export const createReportName = (name, setting) => {

  let nameExist = setting.reports.filter(el =>
    el.name.match(`^${name}`)).map(e => e.name);
  let nameCountExist = setting.reports.filter(el =>
    el.name.match(`^${name} \\d+$`)).map(e => e.name);
  if (nameExist.length) {
    if (nameCountExist.length) {
      return updates(nameCountExist[nameCountExist.length - 1]);
    } else {
      return updates(nameExist[nameExist.length - 1]);
    }
  }
  else {
    return `${name}`
  }
}

/** Create new report */

export const addNewReport = (name, type, reportSetting, session, studyId, projectId, bias="center") => {

  return new Promise((resolve, reject) => {
    let setting;

    if (type == "attention_map") {
      setting = { ...attentionMapSetting, bias  };
    } else if (type == "hotspots") {
      setting = { ...hotspotSetting, bias };
    } else if (type == "aoi") {
      setting = { ...aoiSetting, bias };
    } else {
      setting = { ...defaultSetting, bias };
    }

    let newReport = {
      id: uuidv4(),
      notes: "",
      name,
      type,
      settings: setting
    }

    if (type == "aoi") {
      newReport.features = { ...AOI_Features };
    }

    if (type == "gaze_path") {
      newReport.features = { ...gazePathFeatures };
    }

    /** Segment track event */
    segmentAnalytics({ page: false, session, name: "Report Created", segmentData:{
      reportType: newReport.type,
      reportName: newReport.name,
      projectId: projectId,
      studyId: studyId,
      reportId: newReport.id } });

    reportSetting.reports.push(newReport)
    resolve(newReport)
  })

}

/**Function that return new stage dimensions as per zoomed scale */
export const generateZoomedStageScale = (value, stage, OneStageWidth, OneStageHeight, width=0, height=0) => {
  return new Promise((resolve, reject) => {
    let obj = {}
    let imageHeight = height ? height : stage.imageHeight;
    let imageWidth = width ? width : stage.imageWidth;
    if (value === 0) {
      const aspectRatio = OneStageWidth / OneStageHeight;
      const imageRatio = imageWidth / imageHeight;
      const imageScale = aspectRatio >= imageRatio ? (OneStageHeight - 60) / imageHeight : (OneStageWidth - 60) / imageWidth;
      obj = {
        ...stage, x: OneStageWidth / 2 - (imageWidth * imageScale) / 2,
        y: OneStageHeight / 2 - (imageHeight * imageScale) / 2,
        centerPointX: (imageWidth * imageScale) / 2,
        centerPointY: (imageHeight * imageScale) / 2,
        scale: imageScale
      }
    } else {
      obj = {
        ...stage, x: OneStageWidth / 2 - (imageWidth * value) / 2,
        y: OneStageHeight / 2 - (imageHeight * value) / 2,
        centerPointX: (imageWidth * value) / 2,
        centerPointY: (imageHeight * value) / 2,
        scale: value
      }
    }
    resolve(obj)
  })

}


export const generateStudyJSON = (dataList, selectedCells, deleteShapeWithoutLabel, backToProject=false) => {

  return new Promise((resolve, reject) => {

    /**
     * Here find the last stage that have the image dropped, as we dont need to
     * send empty stages, after the last stage where image is dropped, or key is present
     */
    let lastStageIndexWithImage = _.findLastIndex(dataList, function (o) {
      return o.imageKey && o.imageKey !== ""
    });

    /**
     * If last stage is not Z stage, then remove the extra stages with empty data
     */
    if (lastStageIndexWithImage < 25) {
      dataList.splice(lastStageIndexWithImage + 1, dataList.length)
    }

    dataList = dataList.map(data => {
      delete data.showLoader;
      delete data.showGrids;
      delete data.showAOIs;
      delete data.map;
      delete data.grids;
      delete data.hotspots;
      return data;
    });
    let studyData = {};

    studyData.stages = dataList;

    /** Set selected cells from api to global variable */

    for (const [ index, stage ] of studyData.stages.entries()) {
      let stageSelectedCell = selectedCells.filter(el => el.stageId == stage.id);
      stage.selectedCells = [];
      if (stageSelectedCell.length) {
        stage.selectedCells = stageSelectedCell[0].hotspots
      }

      if (stage.aoi && stage.aoi.length) {
        for (const  aoi of stage.aoi) {
          if (aoi.shapes && aoi.shapes.length) {

            for (const shape of aoi.shapes) {
              if (shape.type == "polygon") {
                if (!shape.isFinished) {
                  aoi.shapes.splice(index, 1)
                } else {
                  shape.points= shape.points.map(point=>({ x:Math.round(point.x), y: Math.round(point.y) }));
                  delete shape.isFinished;
                  delete shape.flattenedPoints;
                }
              }
              if (shape.pop === undefined && !shape.label) {
                aoi.shapes.splice(index, 1);
                if (backToProject) {
                  deleteShapeWithoutLabel(aoi.reportId, index);
                }
              }
            }
          }
        }
      }
    }
    resolve(studyData)
  })
}

export const delay = ms => new Promise(res => setTimeout(res, ms));

export const deepCopy = object => object!== undefined ? JSON.parse(JSON.stringify(object)): object;

const updateLabel = (string) => {

  let copyCountMatch = string.match(/^(.+)(\d+)$/);

  return copyCountMatch && copyCountMatch.length === 3
    ? copyCountMatch[1] + (+copyCountMatch[2] + 1)
    : string + "1";
}
export let setNewLabelName = (shapes, value) => {
  let labelName = value.toLowerCase();
  let nameExist = shapes.filter(el => el.label && el.label.toLowerCase().match(`^${labelName}`)).map(e => e.label);
  let nameCountExist = shapes.filter(el => el.label && el.label.toLowerCase().match(`^${labelName}\\d+$`)).map(e => e.label);
  if (nameExist.length) {
    if (nameCountExist.length) {
      return updateLabel(nameCountExist[nameCountExist.length - 1]);
    } else {
      return updateLabel(nameExist[nameExist.length - 1]);
    }
  }
  else {
    return `${value}`
  }
}


export function roundAspectRatio(width, height) {
  // Calculate the aspect ratio
  const aspectRatio = width / height;

  // Define the commonly used ratios
  const ratios = [ "16:9", "9:16", "1:1", "4:3", "3:4", "191:100", "100:191", "2:3", "3:2", "5:4", "4:5", "2:1", "1:2", "16:10", "10:16", "21:9", "9:21", "9:1", "1:9", "1371:1000", "1000:1371", "6:5", "5:6", "8:1", "1:8", "3:10", "10:3", "39:10", "10:39" ];

  // Loop through the ratios and check if the aspect ratio is within 5% tolerance
  for (let i = 0; i < ratios.length; i++) {
    const ratio = ratios[i];
    const [ numerator, denominator ] = ratio.split(":").map(Number);
    const tolerance = 0.05 * numerator / denominator;
    const lowerBound = ratio - tolerance;
    const upperBound = ratio + tolerance;
    if (aspectRatio >= lowerBound && aspectRatio <= upperBound) {
      // Return the rounded ratio if it's within tolerance
      return ratio;
    }
  }

  // If the aspect ratio is not within tolerance of any of the ratios, simplify the aspect ratio
  const gcd = getGcd(width, height);
  const simplifiedWidth = width / gcd;
  const simplifiedHeight = height / gcd;
  return `${simplifiedWidth}:${simplifiedHeight}`;
}

// Helper function to calculate the greatest common divisor (GCD) of two numbers
function getGcd(a, b) {
  if (b === 0) {
    return a;
  } else {
    return getGcd(b, a % b);
  }
}
export const aoiScoreCard = (report, stage, index, shapes) => {
  return new Promise((resolve, reject) => {
    let today = new Date();
    let scoreCard = `<table border="0" width="400px">
                <tr><td style="font-weight: bold">Version:</td><td style="font-weight: bold; color: #f2318a;">${String.fromCharCode(65 + index)}</td></tr>
                <tr><td style="font-weight: bold">File Name:</td><td style="font-weight: bold; color: #f2318a;">${stage.imageName}</td></tr>
                <tr><td>&nbsp;</td></tr>
                <tr><td style="font-weight: bold">Report Name:</td><td style="font-weight: bold; color: #f2318a;">${report.name}</td></tr>
                <tr><td style="font-weight: bold">Date of Export:</td><td style="font-weight: bold; color: #f2318a;">${today.getDate() <10? "0"+today.getDate() : today.getDate() }/${today.getMonth()+1 <10 ? "0"+(today.getMonth()+1) : today.getMonth()+1 }/${today.getFullYear()}</td></tr>
                <tr><td style="font-weight: bold">Visualisation:</td><td style="font-weight: bold; color: #f2318a;">${report.settings.map === "opacity" ? "Opacity" : report.settings.map === "heat" ? "Heat" : "Original"}</td></tr>
                <tr><td style="font-weight: bold">Bias:</td><td style="font-weight: bold; color: #f2318a;">${report.settings.bias == "center" ? "Centre Bias" : "Unbiased"}</td></tr>
                <tr><td style="font-weight: bold">Metric:</td><td style="font-weight: bold; color: #f2318a;">${report.settings.metric == "probability" ? "Probability of Perception" : (report.settings.metric == "share" ? "Share of Attention" : report.settings.metric == "local" ?"Local Attention Score" : "Attention Power Factor")}</td></tr>`;
    if(report.settings.targetHierarchy && report.settings.targetHierarchy.length) {
      scoreCard += `<tr><td>&nbsp;</td></tr>
                <tr><td style="font-weight: bold">Visual Hierarchy Score:</td><td>${stage.aoi.find(stage => stage.reportId === report.id).visualHierarchyScore}%</td></tr>
                <tr><td style="font-weight: bold">Digestibility Score:</td><td>${stage.aoi.find(stage => stage.reportId === report.id).digestibilityScore}%</td></tr>
                <tr><td style="font-weight: bold">Priority Alignment Score:</td><td>${stage.aoi.find(stage => stage.reportId === report.id).priorityAlignmentScore}%</td></tr>
                <tr><td>&nbsp;</td></tr>`;

      shapes.forEach(shape => {
        scoreCard += `<tr><td style="font-weight: bold">${shape.label}</td></tr> <tr><td>Priority:</td><td>${shape.priority}</td></tr><tr><td>Rank:</td><td>${shape.rank}</td></tr><tr><td>&nbsp;</td></tr>`
      })
    } else {
      scoreCard+= "<tr><td>&nbsp;</td></tr><tr><td colspan=\"2\" style=\"font-weight: bold; color: #f2318a;\">Draw AOIs and set visual hierarchy for Visual hierarchy score and ranking of the shapes.</td></tr>";
    }
    scoreCard+="</table>";
    resolve(scoreCard);
  })
}


export const contextScoreCard = (report, stage, index, stagesCopy) => {
  return new Promise((resolve, reject) => {
    let today = new Date();
    let contextPerformance = stagesCopy[index]["contextScore"][`${report.activeContext}`].contextPerformance;
    let benchmarkName = report.benchmarkId ? stagesCopy[index]["context"][`${report.id}`]["benchmarkDetail"][0].name : null;
    let benchmarkSingleScore = report.benchmarkId ? stagesCopy[index]["context"][`${report.id}`]["benchmarkSingleScore"] : null;
    let contextPackName = report.isContextPack ? stagesCopy[index]["context"][`${report.id}`]["contextPackDetail"].name : null;
    let score = report.settings.metric === "local" ? contextPerformance.las : (report.settings.metric === "probability" ? contextPerformance.pop : (report.settings.metric === "share" ? contextPerformance.soa : contextPerformance.pow));
    let benchmarkScore = report.benchmarkId ? report.settings.metric === "local" ? benchmarkSingleScore.las : (report.settings.metric === "probability" ? benchmarkSingleScore.pop : (report.settings.metric === "share" ? benchmarkSingleScore.soa : benchmarkSingleScore.pow)) : null;

    let scoreCard = `<table border="0" width="400px">
                <tr><td style="font-weight: bold">Version:</td><td colspan="2"  style="font-weight: bold; color: #f2318a;">${String.fromCharCode(65 + index)}</td></tr>
                <tr><td style="font-weight: bold">File Name:</td><td colspan="2"  style="font-weight: bold; color: #f2318a;">${stage.imageName}</td></tr>
                <tr><td>&nbsp;</td></tr>
                <tr><td style="font-weight: bold">Report Name:</td><td colspan="2"  style="font-weight: bold; color: #f2318a;">${report.name}</td></tr>
                <tr><td style="font-weight: bold">Date of Export:</td><td colspan="2"  style="font-weight: bold; color: #f2318a;">${today.getDate() < 10 ? "0" + today.getDate() : today.getDate() }/${today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1 }/${today.getFullYear()}</td></tr>
                <tr><td style="font-weight: bold">Visualisation:</td><td colspan="2"  style="font-weight: bold; color: #f2318a;">${report.settings.map === "opacity" ? "Opacity" : report.settings.map === "heat" ? "Heat" : "Original"}</td></tr>
                <tr><td style="font-weight: bold">Bias:</td><td colspan="2"  style="font-weight: bold; color: #f2318a;">${report.settings.bias == "center" ? "Centre Bias" : "Unbiased"}</td></tr>
                <tr><td style="font-weight: bold">Metric:</td><td colspan="2"  style="font-weight: bold; color: #f2318a;">${report.settings.metric == "probability" ? "Probability of Perception" : (report.settings.metric == "share" ? "Share of Attention" : report.settings.metric == "local" ? "Local Attention Score" : "Attention Power Factor")}</td></tr>`;

                if(report.isContextPack) {
                  scoreCard += `<tr><td style="font-weight: bold">Context Pack:</td><td colspan="2"  style="font-weight: bold; color: #f2318a;">${contextPackName}</td></tr>`
                }
                if(report.benchmarkId) {
                  scoreCard += `<tr><td style="font-weight: bold">Benchmark:</td><td colspan="2"  style="font-weight: bold; color: #f2318a;">${benchmarkName}</td></tr>`
                }
                scoreCard += `<tr><td>&nbsp;</td></tr>
                <tr><td style="font-weight: bold">Context Score:</td><td>${report.settings.metric === "attention" ? `${score.toFixed(1)}x` : `${score}%`}</td>`
                if(report.benchmarkId) {
                  scoreCard += `<td style="${score > benchmarkScore ? "color:#198754;" : "color:#dc3545;"}">${report.settings.metric === "attention" ? `${(score - benchmarkScore).toFixed(1)}` : `${score - benchmarkScore}`}</td>`
                }
                scoreCard +="</tr>";
    resolve(scoreCard);
  })
}


export const hotSpotScoreCard = (report, stage, index, stagesCopy) => {
  return new Promise((resolve, reject) => {
    let today = new Date();
    const scoreCard =`<table border="0" width="400px">
                <tr><td style="font-weight: bold">Version:</td><td style="font-weight: bold; color: #f2318a;">${String.fromCharCode(65 + index)}</td></tr>
                <tr><td style="font-weight: bold">File Name:</td><td style="font-weight: bold; color: #f2318a;">${stage.imageName}</td></tr>
                <tr><td>&nbsp;</td></tr>
                <tr><td style="font-weight: bold">Report Name:</td><td style="font-weight: bold; color: #f2318a;">${report.name}</td></tr>
                <tr><td style="font-weight: bold">Date of Export:</td><td style="font-weight: bold; color: #f2318a;">${today.getDate() < 10 ? "0" + today.getDate() : today.getDate() }/${today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1 }/${today.getFullYear()}</td></tr>
                <tr><td style="font-weight: bold">Visualisation:</td><td style="font-weight: bold; color: #f2318a;">${report.settings.map === "opacity" ? "Opacity" : report.settings.map === "heat" ? "Heat" : "Original"}</td></tr>
                <tr><td style="font-weight: bold">Bias:</td><td style="font-weight: bold; color: #f2318a;">${report.settings.bias == "center" ? "Centre Bias" : "Unbiased"}</td></tr>
                <tr><td style="font-weight: bold">Metric:</td><td style="font-weight: bold; color: #f2318a;">${report.settings.metric == "probability" ? "Probability of Perception" : (report.settings.metric == "share" ? "Share of Attention" : report.settings.metric == "local" ? "Local Attention Score" : "Attention Power Factor")}</td></tr>
                <tr><td>&nbsp;</td></tr>
                <tr><td style="font-weight: bold">Digestibility Score:</td><td>${stagesCopy[index]["hotspots"][`${report.settings.bias}`].digestibilityScore}%</td></tr>
                <tr><td style="font-weight: bold">Number of Hotspots:</td><td>${stagesCopy[index]["hotspots"][`${report.settings.bias}`].hotspots.length}</td></tr>`;
    resolve(scoreCard);
  })
}

export const segmentAnalytics = ({ page, name, segmentData, session }) => {
  let commonData = {
    userId: session.user && session.user.id ? session.user.id : "",
    accountName: session.account && session.account.company ? session.account.company : "",
    accountType: session.account && session.account.type ? session.account.type : "",
    accountId: session.account && session.account.id ? session.account.id : "",
    subscriptionId: session.subscription && session.subscription.id ? session.subscription.id : "",
    subscriptionChargebeeId: session.subscription && session.subscription.chargebeeId ? session.subscription.chargebeeId : "",
    subscriptionStatus: session.subscription && session.subscription.status ? session.subscription.status : "",
    planId: session.subscription && session.subscription.plan && session.subscription.plan.id ? session.subscription.plan.id : "",
    planChargebeeId: session.subscription && session.subscription.plan && session.subscription.plan.chargebeeId ? session.subscription.plan.chargebeeId : ""
  };

  if (session.account && session.account.organisation && session.account.organisation.id) {
    commonData.organizationId = session.account.organisation.id;
  }

  if(page) {
    window.analytics.page(name, {
      ...segmentData,
      ...commonData
    },
    {
      groupId: session.account && session.account.id ? session.account.id : ""
    });
  } else {
    window.analytics.track(name,{
      ...segmentData,
      ...commonData
    },
    {
      groupId: session.account && session.account.id ? session.account.id : ""
    });
  }

}