import { useMemo, useState } from "react";
import { useGetAllContextsQuery } from "@/features/library/api/librarySlice";
import {
  faTimes,
  faSearch,
  faLock,
  faAngleDown,
  faTag,
  faCalendarAlt,
  faLongArrowDown,
  faLongArrowUp,
  faUserCircle,
  faSpinner as falSpinner,
} from "@fortawesome/pro-regular-svg-icons";
import { faFrame, faEmptySet } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContextCard } from "@/components/Common/ContextCard";
import Loader from "../Loader";
import Pagination from "../Pagination";
import { Modal } from "react-bootstrap";
import { NotFound } from "@/features/library/components/NotFound";
import { GetContextMediaSuccess } from "@/redux/actions/context-action-types";
import { updateContextFilter } from "@/features/study/stores/filtersSlice";
import { useSelector, useDispatch } from "react-redux";
import { deepCopy, segmentAnalytics } from "@/utilities/genericFunctions";

export const ContextSelectionModal = ({
  hide,
  study,
  projectId,
  activeReport,
  updateContextSetting,
  setContextToStage,
}) => {
  const { useCase } = study;
  const [contextSelection, setContextSelection] = useState([]);
  const [addingContextToStudy, setAddingContextToStudy] = useState(false);
  const [showAssetPanel, setShowAssetPanel] = useState(false);
  const SORT_ARRAY = { name: "Name", owner: "Owner", createdat: "Created", updatedat: "Modified" };
  const dispatch = useDispatch();
  const contextFilters = useSelector((state) => state.contextSelectionFilters.contextFilters);

  const session = useSelector((state) => state.session);

  const Context = useSelector((state) => state.context?.contextMedia);

  const {
    data: { contexts, maxPageIndex, maxRecordCount, pageIndex } = {},
    isSuccess: contextsIsSuccess,
    isLoading: contextsIsLoading,
  } = useGetAllContextsQuery({ ...contextFilters, useCaseIds: [useCase.id] });

  const addToReport = async (type) => {
    setAddingContextToStudy(true);
    let activeReportCopy = deepCopy(activeReport);
    if (!(activeReportCopy.contextIds && activeReportCopy.contextIds.length)) {
      activeReportCopy.contextIds = [];
    }
    const contextList = [...Context.data, ...contextSelection];
    dispatch(GetContextMediaSuccess({ payload: { contexts: contextList } }));
    activeReportCopy.activeContext = contextSelection[0].id;
    activeReportCopy.contextIds.push(contextSelection[0].id);
    activeReportCopy.contextIds = activeReportCopy.contextIds.filter((ids) => ids != null);
    activeReportCopy.isContextPack = false;
    await updateContextSetting(activeReportCopy);
    await setContextToStage(contextSelection[0]);
    /** Segment track event for select context */
    segmentAnalytics({
      page: false,
      session,
      name: "Select Library Context",
      segmentData: {
        reportType: activeReport.type,
        projectId: projectId,
        studyId: study.id,
        reportId: activeReport.id,
      },
    });
    hide(true);
  };

  // useMemo to combine results from selectedContext and contexts
  const filterContexts = useMemo(() => {
    if (contextsIsSuccess) {
      if (Context && Context.data && Context.data.length) {
        const result = contexts.map((context) => {
          if (Context.data.some((selectedContext) => selectedContext.id === context.id)) {
            return (context = { ...context, disabled: true });
          } else {
            return context;
          }
        });
        return result;
      } else {
        return contexts;
      }
    }
  }, [Context, contexts]);

  const orderChange = (value) => {
    dispatch(updateContextFilter({ orderby: value }));
  };

  const sortChange = (value) => {
    dispatch(updateContextFilter({ sortby: value }));
  };

  const onSearch = (e) => {
    dispatch(updateContextFilter({ query: e.target.value.trimStart(), pageIndex: 1 }));
  };

  return (
    <>
      <Modal.Header className="border-darken-15 bg-white">
        <Modal.Title as="h5" className="fw-light">
          Context Selection
        </Modal.Title>

        <div>
          {!addingContextToStudy ? (
            <button type="button" className="btn btn-primary" onClick={addToReport} disabled={!contextSelection.length}>
              Add to report
            </button>
          ) : null}
          <button type="button" className="btn btn-link text-black-50 close" onClick={() => hide()}>
            <FontAwesomeIcon icon={faTimes} size="lg" className="mt-1" />
          </button>
        </div>
      </Modal.Header>
      <div className="modal-body p-0 position-relative media-modal">
        {addingContextToStudy ? (
          <div className="modal-body bg-light progress-body">
            <form className="form needs-validation mx-auto progress-width" noValidate>
              <div className="text-center">
                <div className="py-4">
                  <span className="d-inline-block m-auto mb-3 border rounded-circle text-center d-flex align-items-center justify-content-center text-dark border-dark border-2 progress-circle">
                    <FontAwesomeIcon icon={falSpinner} spin size="2x" />
                  </span>
                  <p className="lead fw-400">Adding context to study</p>

                  <p className="text-muted fs-sm">Please do not close the window.</p>
                  {/* <div className="progress mb-5 progress-height">
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        style={{ width: `${((addingContextToStudy + 1) * 100) / 4}%` }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      />
                    </div> */}
                </div>
              </div>
            </form>
          </div>
        ) : null}
        {!addingContextToStudy && (
          <div className="h-100 w-100 d-flex flex-row">
            <div className="h-100 flex-grow-1 border-end overflow-auto">
              <section className="border-bottom py-3">
                <header className="position-relative ms-3 ps-5">
                  <span
                    className="d-inline-block position-absolute start-0 top-0 bg-primary-10 rounded-circle text-primary text-center"
                    style={{ width: "2.4em", height: "2.4em", paddingTop: "8px" }}
                  >
                    <FontAwesomeIcon icon={faFrame} size="lg" fixedWidth />
                  </span>
                  <h2 className="fs-6 mb-1">Select contexts</h2>
                  <p className="text-muted fs-xs mb-0">Select a context to add to your report.</p>
                </header>
              </section>
              <div className="p-4 overflow-auto">
                <nav className="navbar mb-3 p-0">
                  <div className="container-fluid d-flex flex-row p-0">
                    <form className="d-none d-lg-flex me-2">
                      <div className="input-group">
                        <span className="input-group-text bg-white" id="basic-addon1">
                          <FontAwesomeIcon icon={faSearch} fixedWidth />
                        </span>
                        <input
                          type="search"
                          className="form-control"
                          id=""
                          onChange={(e) => onSearch(e)}
                          placeholder="Search..."
                        />
                      </div>
                    </form>
                    <form className="d-flex ms-auto">
                      <span className="btn btn-transparent bg-info-10 text-info me-2 pe-none rounded-pill">
                        <FontAwesomeIcon icon={faLock} fixedWidth className="me-1" />
                        {useCase?.name}
                      </span>
                      <div className="btn-group">
                        <button
                          type="button"
                          className="btn btn-light bg-white border border-form-control"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {SORT_ARRAY[contextFilters?.orderby]} <FontAwesomeIcon icon={faAngleDown} fixedWidth />
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end">
                          <li>
                            <h6 className="dropdown-header">Sort by</h6>
                          </li>
                          <li>
                            <span className="dropdown-item cursor-pointer" onClick={() => orderChange("name")}>
                              <FontAwesomeIcon icon={faTag} fixedWidth className="me-1" /> Name
                            </span>
                          </li>
                          <li>
                            <span className="dropdown-item cursor-pointer" onClick={() => orderChange("owner")}>
                              <FontAwesomeIcon icon={faUserCircle} fixedWidth className="me-1" /> Owner
                            </span>
                          </li>
                          <li>
                            <span className="dropdown-item cursor-pointer" onClick={() => orderChange("createdat")}>
                              <FontAwesomeIcon icon={faCalendarAlt} fixedWidth className="me-1" /> Created
                            </span>
                          </li>
                          <li>
                            <span className="dropdown-item cursor-pointer" onClick={() => orderChange("updatedat")}>
                              <FontAwesomeIcon icon={faCalendarAlt} fixedWidth className="me-1" /> Modified
                            </span>
                          </li>
                        </ul>
                        <button
                          type="button"
                          className="btn btn-light bg-white border border-form-control"
                          onClick={() => sortChange(contextFilters?.sortby === "asc" ? "desc" : "asc")}
                        >
                          <FontAwesomeIcon
                            icon={contextFilters?.sortby === "asc" ? faLongArrowDown : faLongArrowUp}
                            fixedWidth
                          />
                        </button>
                      </div>
                    </form>
                  </div>
                </nav>
                {contextsIsLoading && <Loader show={true} />}
                {contextsIsSuccess && filterContexts.length === 0 && <NotFound />}
                {contextsIsSuccess && filterContexts?.length > 0 && (
                  <>
                    <div className="d-flex flex-row flex-wrap">
                      {filterContexts.map((context, i) => (
                        <ContextCard
                          key={`col-${i}`}
                          cardMeta={context}
                          setContextSelection={setContextSelection}
                          contextSelection={contextSelection}
                          select={context?.disabled ? false : true}
                          setShowAssetPanel={setShowAssetPanel}
                          showAssetPanel={showAssetPanel}
                        />
                      ))}
                    </div>
                  </>
                )}
                <nav className="navbar navbar-expand-lg navbar-light mt-4 mb-0">
                  {contextsIsSuccess && filterContexts.length > 0 && (
                    <Pagination
                      key={`${contextFilters.itemsPerPage}`}
                      maxPageIndex={maxPageIndex}
                      pageIndex={pageIndex}
                      totalRecords={maxRecordCount}
                      pageLimit={contextFilters.itemsPerPage}
                      pageNeighbours={2}
                      onPageChanged={({ currentPage }) => dispatch(updateContextFilter({ pageIndex: currentPage }))}
                      onLimitChange={(itemsPerPage) => {
                        dispatch(updateContextFilter({ itemsPerPage, pageIndex: 1 }));
                      }}
                    />
                  )}
                </nav>
              </div>
            </div>
            <div
              className="h-100 p-3 flex-shrink-0 border-start overflow-auto d-block"
              style={{ width: "16vw", maxWidth: "400px" }}
            >
              {contextSelection && contextSelection.length > 0 ? (
                <>
                  <h1 className="h5 fw-700 mb-3">Selection ({contextSelection.length})</h1>
                  <div className="media-items">
                    {contextSelection && contextSelection?.length > 0 && (
                      <>
                        <div className="d-flex flex-row flex-wrap">
                          {contextSelection.map((context, i) => (
                            <ContextCard
                              key={`col-${i}`}
                              cardMeta={context}
                              setContextSelection={setContextSelection}
                              contextSelection={contextSelection}
                              // setShowAssetPanel={setShowAssetPanel}
                              // showAssetPanel={showAssetPanel}
                            />
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <div className="w-100 h-100">
                  <div className="text-center mb-3">
                    <div className="py-4">
                      <span
                        className="d-inline-block m-auto mb-3 border rounded-circle text-center d-flex align-items-center justify-content-center text-dark border-dark border-2 o-20"
                        style={{ width: "4rem", height: "4rem" }}
                      >
                        <FontAwesomeIcon icon={faEmptySet} size="2x" />
                      </span>
                      <span className="text-dark fw-500 o-40 fs-sm">0 items selected</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
