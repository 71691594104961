import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import ReactDOM from "react-dom";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "@/components";
import App from "./App";
import "./assets/styles.css";

// monkey patch to remove all consoles in production
if (process.env.NODE_ENV === "production") {
  console.log = () => null;
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("app-root")
);

