import { Navigate, Outlet } from "react-router-dom";

function AuthLayout({ mapRoleToLandingPage, role, accountId }) {

  document.body.className = "";
  if (role !== null && accountId !== undefined) {
    return <Navigate to={mapRoleToLandingPage(role)} />;
  }
  return (
    <Outlet />
  );
}

export default AuthLayout;
