import { createAction } from "redux-actions";

// UploadFile
export const UPLOAD_FILE = "UPLOAD_FILE";
export const UploadFile = createAction(UPLOAD_FILE);
export const UPLOAD_FILE_REQUESTED = "UPLOAD_FILE_REQUESTED";
export const UploadFileRequested = createAction(UPLOAD_FILE_REQUESTED);
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";
export const UploadFileFailure = createAction(UPLOAD_FILE_FAILURE);
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UploadFileSuccess = createAction(UPLOAD_FILE_SUCCESS);

// DeleteFile
export const DELETE_FILE = "DELETE_FILE";
export const DeleteFile = createAction(DELETE_FILE);
export const DELETE_FILE_REQUESTED = "DELETE_FILE_REQUESTED";
export const DeleteFileRequested = createAction(DELETE_FILE_REQUESTED);
export const DELETE_FILE_FAILURE = "DELETE_FILE_FAILURE";
export const DeleteFileFailure = createAction(DELETE_FILE_FAILURE);
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DeleteFileSuccess = createAction(DELETE_FILE_SUCCESS);

export const HIDE_LOADER = "HIDE_LOADER";
export const hideLoader = createAction(HIDE_LOADER);

export const SHOW_LOADER = "SHOW_LOADER";
export const showLoader = createAction(SHOW_LOADER);