import { faArchive, faEllipsisV, faMagnifyingGlass, faPencil, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { ToggleModal, RenameModal, DeleteModal } from "../Modals";
import { ImagePreviewModal } from "@/features/library/components/ImagePreviewModal";
import { Fragment, cloneElement } from "react";
import { capitalise } from "./utils/capitalise";

export const ActionDropdown = ({ modalRenders, updateFunction, cardMeta, title, contextPreview, actions }) => {
  const cardId = cardMeta?.id;

  // default action modals and icons, compares to actions array to display
  const actionMapping = {
    preview: {
      icon: faMagnifyingGlass,
      defaultModal: <ImagePreviewModal />,
      modalSize: "modal-fullscreen",
    },
    rename: { icon: faPencil, defaultModal: <RenameModal /> },
    archive: { icon: faArchive, defaultModal: null },
    delete: { icon: faTrash, defaultModal: <DeleteModal /> },
  };

  const actionRender = {};

  if (actions) {
    actions.forEach((action) => {
      actionRender[action] = actionMapping[action];
    });
  }

  return (
    <Dropdown className="position-absolute top-0 end-0 mt-2 me-2">
      <Dropdown.Toggle variant="light" className="btn btn-light bg-white border-0 controlled-toggle">
        <FontAwesomeIcon icon={faEllipsisV} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-end" align="end">
        <Dropdown.Header>Actions</Dropdown.Header>
        {actionRender && (
          <>
            {Object.keys(actionRender).map((key, i) => (
              <Fragment key={`action-${i}`}>
                {key === "archive" ? (
                  <Dropdown.Item
                    onClick={() => {
                      // Packs and Benchmarks have a status field, while Contexts have an isArchived field
                      const status = cardMeta.status ? (cardMeta.status === "archived" ? "draft" : "archived") : null;
                      const isArchived = "isArchived" in cardMeta ? !cardMeta.isArchived : null;
                      const value = cardMeta.status ? { status: status } : { isArchived: isArchived };

                      updateFunction(value, cardId);
                    }}
                  >
                    <FontAwesomeIcon icon={actionRender[key].icon} className="me-1" fixedWidth />{" "}
                    {cardMeta.isArchived || cardMeta.status === "archived" ? "Unarchive" : capitalise(key)}
                  </Dropdown.Item>
                ) : (
                  <ToggleModal
                    toggle={(show) => (
                      <>
                        {key === "delete" && <Dropdown.Divider />}
                        <Dropdown.Item
                          onClick={(e) => {
                            if (key === "preview" && title === "Context") {
                              let cardTitle = cardMeta?.name ? cardMeta.name : cardMeta.filename;
                              contextPreview(title, cardTitle);
                              show();
                            } else {
                              show();
                            }
                          }}
                          className={`${key === "delete" ? "text-danger" : ""}`}
                        >
                          <FontAwesomeIcon icon={actionRender[key].icon} className="me-1" fixedWidth />{" "}
                          {capitalise(key)}
                        </Dropdown.Item>
                      </>
                    )}
                    content={(hide) =>
                      cloneElement(actionRender[key].defaultModal, {
                        hide,
                        updateFunction,
                        cardMeta,
                        title,
                        modalMeta: cardMeta,
                      })
                    }
                    dialogClassName={actionRender[key]?.modalSize}
                  />
                )}
              </Fragment>
            ))}
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
