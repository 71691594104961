import { useContext } from "react";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AccordionContext, useAccordionButton } from "react-bootstrap";

export const AdvancedToggle = ({
  children,
  eventKey,
  callback,
  icon,
  iconSize,
  toggleText,
  startRotate = 0,
  endRotate = 270,
}) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button
      type="button"
      style={{ outline: "none", background: "none", border: "none", paddingLeft: "0" }}
      onClick={decoratedOnClick}
      className="fs-sm"
    >
      {children ? (
        children
      ) : (
        <>
          {icon !== null ? (
            <>
              <FontAwesomeIcon
                icon={icon ? icon : faCaretDown}
                size={iconSize ? iconSize : "xs"}
                fixedWidth
                style={{
                  transition: "transform 0.3s ease",
                  transform: `rotate(${isCurrentEventKey ? startRotate : endRotate}deg)`,
                }}
              />{" "}
            </>
          ) : null}
          {toggleText ? toggleText : "Advanced"}
        </>
      )}
    </button>
  );
};
