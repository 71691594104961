export const generateQueryParam = (queryParamArray, key, firstParam = false) => {
  // when the API expects multiple query parameters for the same key, we need to generate a string
  // for each value in the array and join them with an ampersand
  const generatedQueryString = queryParamArray
    .map((queryParam, i) => {
      if (i === 0 && firstParam) {
        return `?${key}=${queryParam}`;
      }
      return `&${key}=${queryParam}`;
    })
    .join("");
  return generatedQueryString;
};
